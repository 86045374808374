import { ReactComponent as IconHome } from 'assets/icons/home.svg';
import { ReactComponent as IconProducts } from 'assets/icons/products.svg';
import { ReactComponent as IconEndPoints } from 'assets/icons/end-points.svg';
import { ReactComponent as IconAnalytics } from 'assets/icons/analytics.svg';
import { ReactComponent as IconSettings } from 'assets/icons/settings.svg';
import { ReactComponent as IconCreditCard2 } from 'assets/icons/credit-card-2.svg';
import { ReactComponent as IconConnect } from 'assets/icons/connect.svg';

import { ReactComponent as IconGithub } from 'assets/icons/socialmedia/github.svg';
import { ReactComponent as IconDribbble } from 'assets/icons/socialmedia/dribbble.svg';
import { ReactComponent as IconLinkedin } from 'assets/icons/socialmedia/linkedin-gray.svg';
import { ReactComponent as IconBehance } from 'assets/icons/socialmedia/behance.svg';

import { ReactComponent as IconFacebook } from 'assets/icons/socialmedia/facebook.svg';
import { ReactComponent as IconTwitter } from 'assets/icons/socialmedia/twitter.svg';
import { ReactComponent as IconInstagram } from 'assets/icons/socialmedia/instagram.svg';
import { ReactComponent as IconLinkedin2 } from 'assets/icons/socialmedia/linkedin-2.svg';
import { ROUTE_PATHS } from 'data/paths';

export const TEXT = {};

export const FORM_TEXT = {
  required: 'This is required!',
  company_mail: 'Please enter your company email',
  form_successful_message: 'The form was submitted successfully.',
  form_error_message: 'An Error Occurred Please Try Again Later!',
};

export const ACCEPT_FILE_FORMATS = {
  'application/pdf': [],
  'image/jpeg': [],
  'image/png': [],
  'image/webp': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/vnd.ms-excel': [],
  'application/vnd.oasis.opendocument.presentation': [],
  'application/vnd.oasis.opendocument.spreadsheet': [],
  'application/vnd.oasis.opendocument.text': [],
  'image/gif': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
};
export const ACCEPT_FILE_FORMATS_JOB = { 'image/jpeg': [], 'image/png': [], 'image/gif': [] };
export const MAX_FILE_SIZE = 6291456;
export const MAX_FILE_SIZE_JOB = 3145728;

export const PROFILE_SRC = 'https://hirize-static-assets-dev.s3.us-west-1.amazonaws.com/img/m/';

export const URL_API_DOCUMENTATION = 'https://documenter.getpostman.com/view/25317643/2s8ZDSb5Ha';

export const URL_ZAPIER_EXPORT_ZAP_TEMPLATE = 'https://zapier.com/shared/f6027679801b438fd197c1e6723e1ee810c85151';
export const NAVIGATION = [
  {
    id: 'home',
    href: ROUTE_PATHS.HOME,
    label: 'Home',
    icon: <IconHome />,
  },
  {
    id: 'products',
    href: ROUTE_PATHS.PRODUCTS,
    label: 'Products',
    icon: <IconProducts />,
  },
  {
    id: 'endpoints/list',
    href: ROUTE_PATHS.ENDPOINTS_LIST,
    label: 'End Points',
    icon: <IconEndPoints />,
  },
  {
    id: 'connected-apps',
    href: ROUTE_PATHS.CONNECTED_APPS,
    label: 'Connect',
    icon: <IconConnect />,
  },
  {
    id: 'analytics',
    href: ROUTE_PATHS.ANALYTICS,
    label: 'Analytics',
    icon: <IconAnalytics />,
  },
];

export const NAVIGATION_BOTTOM = [
  {
    id: 'checkout',
    href: ROUTE_PATHS.CHECKOUT,
    label: 'Checkout',
    icon: <IconCreditCard2 />,
  },
  {
    id: 'settings',
    href: ROUTE_PATHS.SETTINGS_COMPANY_ACCOUNT,
    label: 'Settings',
    icon: <IconSettings />,
  },
];

export const DATA_SOCIAL_MEDIA_ICONS = {
  github: <IconGithub />,
  dribbble: <IconDribbble />,
  linkedin: <IconLinkedin />,
  LinkedIn: <IconLinkedin />,
  behance: <IconBehance />,
};

export const DATA_SOCIAL_MEDIA = [
  {
    id: 'linkedin',
    label: 'Linkedin',
    link: 'https://www.linkedin.com/company/80397060/',
    icon: <IconLinkedin2 />,
  },
  {
    id: 'instagram',
    label: 'Instagram',
    link: 'https://www.instagram.com/hirize.hr/',
    icon: <IconInstagram />,
  },
  {
    id: 'twitter',
    label: 'Twitter',
    link: 'https://twitter.com/Hirize_Hr',
    icon: <IconTwitter />,
  },

  {
    id: 'facebook',
    label: 'Facebook',
    link: 'https://www.facebook.com/Hirize.hr/',
    icon: <IconFacebook />,
  },
];

export const DATA_ANALYTICS_PRODUCTS = [
  {
    id: 'Resume Parser',
    label: 'Resume Parser',
    color: '#1029BA',
  },
  {
    id: 'Job Parser',
    label: 'Job Parser',
    color: '#2644F1',
  },
  {
    id: 'AI Matcher',
    label: 'AI Matcher',
    color: '#6980F2',
  },
  {
    id: 'DataAI',
    label: 'DataAI',
    color: '#A1AFF7',
  },
  {
    id: 'Hirize Logic',
    label: 'Hirize Logic',
    color: '#C7CFFA',
  },
  {
    id: 'Hirize IQ',
    label: 'Hirize IQ',
    color: '#DEE1F4',
  },
];
