export const DATA_HIRIZE_FIN_LIST_HEAD = [
  {
    id: 'name',
    label: 'INVOICE NAME',
    width: '70%',
  },

  {
    id: 'date',
    label: 'DATE',
    width: '10%',
  },
  {
    id: 'action',
    label: 'ACTION',
    width: '10%',
    align: 'right',
  },
];
