import { createSlice } from '@reduxjs/toolkit';
import { FORM_TEXT } from 'data';
import { snackActions } from 'libs/snackbar';
import { getAllInvoices, getCompany, getCompanyPlan, updateCompany } from './companyAction';

const initialState = {
  success: null,
  message: null,
  company: null,
  invoices_list: null,
  company_plan_data: null,
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // get company
    builder.addCase(getCompany.pending, state => {
      state.success = null;
    });
    builder.addCase(getCompany.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.company = payload.data[0];
    });
    builder.addCase(getCompany.rejected, (state, { payload }) => {
      state.success = payload.success;
    });

    // update company
    builder.addCase(updateCompany.pending, state => {
      state.success = null;
    });
    builder.addCase(updateCompany.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.message = FORM_TEXT.form_successful_message;
      snackActions.success(FORM_TEXT.form_successful_message);
    });
    builder.addCase(updateCompany.rejected, (state, { payload }) => {
      state.success = payload.success;
      state.message = payload.message;
    });

    // getAllInvoices
    builder.addCase(getAllInvoices.pending, state => {
      state.success = null;
    });
    builder.addCase(getAllInvoices.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.invoices_list = payload.data;
    });
    builder.addCase(getAllInvoices.rejected, (state, { payload }) => {
      state.success = payload.success;
      state.message = payload.message;
    });

    // getCompanyPlan
    builder.addCase(getCompanyPlan.pending, state => {
      state.success = null;
    });
    builder.addCase(getCompanyPlan.fulfilled, (state, { payload }) => {
      const { success, data } = payload;
      state.success = success;
      state.company_plan_data = data;
    });
    builder.addCase(getCompanyPlan.rejected, (state, { payload }) => {
      state.success = payload.success;
    });
  },
});

export default companySlice.reducer;
