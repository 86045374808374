import { AppBar, Button, Toolbar } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { CreditStatusBox } from 'components/CreditStatusBox';
import FixedPlanPayment from 'components/banners/FixedPayment';
import { ReactComponent as IconCard } from 'assets/icons/card-2.svg';
import { ReactComponent as IconWarning } from 'assets/icons/warning.svg';
import { ROUTE_PATHS } from 'data/paths';
import { verifyResendCode } from 'features/userMember/userMemberAction';

export const ProtectedHeader = ({ background }) => {
  const dispatch = useDispatch();

  const { isOpenSidebar } = useSelector(state => state.ui);
  const { account_data } = useSelector(state => state.account);
  const { pathname } = useLocation();

  const classname = `Header background-${background} ${isOpenSidebar ? 'sidebar-opened' : ''}`;
  const is_trial_ended = pathname === '/products' && account_data?.['remaining-credit'] === '0.00';

  const clickResendVerification = () => {
    dispatch(
      verifyResendCode({
        email: account_data?.username,
      }),
    );
  };

  return (
    <AppBar className={classname} position="relative">
      {is_trial_ended ? (
        <FixedPlanPayment
          icon={<IconCard />}
          type={'info'}
          text="Your trial has ended, upgrade to supercharge your recruitment."
        >
          <FixedPlanPayment.Action>
            <Button
              component={Link}
              className="bg-light-blue"
              variant="contained"
              color="primary"
              to={ROUTE_PATHS.CHECKOUT}
            >
              Upgrade Now
            </Button>
          </FixedPlanPayment.Action>
        </FixedPlanPayment>
      ) : null}

      {account_data && !account_data?.is_email_approved ? (
        <FixedPlanPayment
          icon={<IconWarning />}
          type={'warning'}
          text={`Please verify your email address. We've sent a verification link to <b>${account_data?.username}</b> `}
        >
          <FixedPlanPayment.Action>
            <Button variant="outlined" color="white" size="medium" onClick={clickResendVerification}>
              Resend verification email
            </Button>
          </FixedPlanPayment.Action>
        </FixedPlanPayment>
      ) : null}

      <Toolbar className="Header-Content">
        <div className="Header-Start"></div>
        <div className="Header-End">
          <CreditStatusBox />
        </div>
      </Toolbar>
    </AppBar>
  );
};
