export const ROUTE_PATHS = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  PRODUCTS: '/products',

  PRODUCTS_PARSER_RESUME: '/products/parser/resume',
  PRODUCTS_PARSER_RESUME_DETAIL: '/products/parser/resume/detail/',
  PRODUCTS_PARSER_JOB: '/products/parser/job',
  PRODUCTS_PARSER_JOB_DETAIL: '/products/parser/job/detail/',
  PRODUCTS_PARSER_SETTINGS: '/products/parser/settings',

  PRODUCTS_MATCHER_LIST: '/products/matcher-list',
  PRODUCTS_MATCHER_DETAIL: '/products/matcher-detail/',

  PRODUCTS_MEDIMIND_LIST: '/products/medimind-list',
  PRODUCTS_MEDIMIND_DETAIL: '/products/medimind-detail/',

  PRODUCTS_HIRIZE_IQ: '/products/hirize-iq',
  PRODUCTS_EVALUATOR: '/products/evaluator',
  PRODUCTS_CHATCV: '/products/chat-cv',

  PRODUCTS_HIRIZE_FIN_LIST: '/products/hirize-fin/list',
  PRODUCTS_HIRIZE_FIN_DETAIL: '/products/hirize-fin/detail/',

  ENDPOINTS_LIST: '/endpoints/list',
  ENDPOINTS_NEW: '/endpoints/new',
  ENDPOINTS_EDIT: '/endpoints/edit/',

  CONNECTED_APPS: '/connected-apps',
  SOURCE_SETUP: '/source-setup/',
  ANALYTICS: '/analytics',

  SETTINGS_COMPANY_ACCOUNT: '/settings/company-account',
  SETTINGS_ACCESS_MANAGEMENT: '/settings/access-management',
  SETTINGS_BILLING_INVOICES: '/settings/billing-invoices',

  PROFILE: '/profile',
  LOGIN: '/login',
  REGISTER: '/register',

  FORGOT_PASSWORD_EMAIL: '/forgot-password/email',
  FORGOT_PASSWORD_CODE_VERIFICATION: '/forgot-password/code-verification',
  FORGOT_PASSWORD_RESET_PASSWORD: '/forgot-password/reset-password',

  CREATE_PASSWORD_NEW_PASSWORD: '/create-password/new-password',
  CREATE_PASSWORD_RESULT: '/create-password/result',

  CHECKOUT: '/checkout',
  CHECKOUT_PAYMENT_RESULT: '/checkout/payment-result',

  EVALUATOR_FORM: '/evaluator-form/',
};
