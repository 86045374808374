import { Avatar, Box, Button, CircularProgress, IconButton, TextField } from '@mui/material';
import { FormInput } from 'components/elements/FormInput';
import { AuthControl } from 'components/layouts/ProtectedLayout';
import { useForm } from 'react-hook-form';
import { ReactComponent as IconSend } from 'assets/icons/send.svg';
import { ReactComponent as IconDownload } from 'assets/icons/download.svg';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';
import { ReactComponent as IconRefresh } from 'assets/icons/refresh.svg';
import { ReactComponent as IconContact } from 'assets/icons/contact.svg';
import { ReactComponent as IconPencil } from 'assets/icons/pencil.svg';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import { ReactComponent as IconClose } from 'assets/icons/close.svg';
import { ReactComponent as IconBlog } from 'assets/icons/blog.svg';
import { useEffect, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { ProfileAvatar } from 'components/layouts/ProtectedSidebar';
import { getRandomNumber } from 'utils';
import { ACCEPT_FILE_FORMATS, MAX_FILE_SIZE } from 'data';
import { DropzoneStandart } from 'components/DropzoneStandart';
import { DATA_CHAT_LIST } from 'data/chat-cv';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewChat,
  deleteChat,
  renameChat,
  submitChatMessage,
  toggleLoading,
  toggleTyping,
  updateActiveChat,
} from 'features/chat-cv/chatCVSlice';
import TypewriterComponent from 'typewriter-effect';
import { ROUTE_PATHS } from 'data/paths';

export const ChatCV = () => {
  const [chat_list] = useState(DATA_CHAT_LIST);
  const { active_chat } = useSelector(state => state.chatCV);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateActiveChat({ ...chat_list[0], history: [] }));
  }, []);

  return (
    <AuthControl>
      <div className="Page Page-ChatCV">
        <div className="ChatCV">
          <ChatCvSidebar />
          {active_chat ? <ChatArea /> : null}
        </div>
      </div>
    </AuthControl>
  );
};

const ChatCvSidebar = () => {
  const dispatch = useDispatch();
  const { active_chat } = useSelector(state => state.chatCV);
  const { chat_list } = useSelector(state => state.chatCV);
  const handleOnDropChange = acceptedFile => {
    StartNewChat(acceptedFile[0]['name']);
  };

  const HandlerDeleteChat = id => {
    dispatch(deleteChat({ id }));
  };

  const HandlerRenameChat = (id, new_name) => {
    dispatch(renameChat({ id, new_name }));
  };

  const HandlerUpdateActiveChat = id => {
    dispatch(updateActiveChat({ id }));
  };

  const StartNewChat = name => {
    const new_chat = {
      name: name,
      id: getRandomNumber(),
      history: [],
    };
    dispatch(addNewChat(new_chat));
  };

  return (
    <div className="ChatCv-Sidebar">
      <div className="ChatCv-Sidebar-Heading">
        <span className="logo">
          <Link to={ROUTE_PATHS.HOME}>
            <img src="/images/logo-tiny-2.svg" alt="" />
          </Link>
        </span>
        <span className="product-name">ChatCV</span>
      </div>

      <div className="ChatCv-Sidebar-Content">
        <div className="cv-dropzone">
          <DropzoneStandart
            onDropChange={handleOnDropChange}
            accept={ACCEPT_FILE_FORMATS}
            maxSize={MAX_FILE_SIZE}
            showIcon
            text={'PDF, Doc, PNG or JPG (max. 2 MB)'}
          />
        </div>
        {chat_list.length > 0 ? (
          <div className="chat-list">
            <div className="chat-list-title">Recent Chats</div>
            {chat_list.map((item, index) => {
              return (
                <ChatListItem
                  key={index}
                  data={item}
                  isActive={item.id === active_chat?.id}
                  clickChangeActiveChat={HandlerUpdateActiveChat}
                  clickDelete={HandlerDeleteChat}
                  clickRename={HandlerRenameChat}
                />
              );
            })}
          </div>
        ) : (
          <div className="no-chat">
            <span className="icon">
              <IconBlog />
            </span>
            <span className="text">No chat!</span>
          </div>
        )}
      </div>
      <div className="ChatCv-Sidebar-Footer">
        <Button href="https://www.hirize.hr/" target="_blank" variant="outlined" color="white">
          Powered by
        </Button>
      </div>
    </div>
  );
};

const ChatArea = () => {
  const { active_chat, is_loading, is_typing } = useSelector(state => state.chatCV);
  const RefMessageList = useRef(null);
  const dispatch = useDispatch();

  const { handleSubmit, reset, control } = useForm({
    defaultValues: {
      chat_question: '',
    },
  });

  const SendChatMessage = data => {
    if (is_loading || is_typing) return;
    dispatch(toggleLoading(true));
    dispatch(toggleTyping(true));
    const newMessage = {
      from: 'user',
      time: new Date().toISOString(),
      text: data.chat_question,
    };
    dispatch(submitChatMessage({ newMessage }));
    reset({
      chat_question: '',
    });

    setTimeout(() => {
      const newMessage = {
        from: 'ai',
        time: new Date().toISOString(),
        text: `<strong>Lorem Ipsum is simply dummy</strong> text of the printing and typesetting industry.`,
      };
      dispatch(submitChatMessage({ newMessage }));
      dispatch(toggleLoading(false));
    }, 500);
  };

  /*   const clickStop = () => {
    dispatch(toggleStop(true));
  };
 */
  useEffect(() => {
    RefMessageList.current.scroll({ top: RefMessageList.current.scrollHeight, behavior: 'smooth' });
    if (RefMessageList) {
      RefMessageList.current.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  }, []);

  return (
    <div className="ChatArea">
      <div className="ChatArea-Heading">
        <div className="cv-profile">
          <ActiveAvatar />
          <div className="name">{active_chat?.name}</div>
        </div>
        <div className="actions">
          <IconButton onClick={null} color="primary" size="small" title="Export Chat">
            <IconDownload />
          </IconButton>
          <IconButton onClick={null} color="primary" size="small" title="Reset Chat">
            <IconRefresh />
          </IconButton>
          <IconButton
            onClick={() => dispatch(deleteChat({ id: active_chat.id }))}
            color="primary"
            size="small"
            title="Delete Chat"
          >
            <IconTrash />
          </IconButton>
        </div>
      </div>
      <div className="ChatArea-Content">
        <ul className="message-list" ref={RefMessageList}>
          {active_chat?.history?.map((item, index) => {
            return (
              <li key={index}>
                <ChatMessage data={item} />
              </li>
            );
          })}{' '}
          {is_loading ? <CircularProgress /> : null}
        </ul>
      </div>
      <div className="ChatArea-Footer">
        {/* {is_typing ? (
          <div className="message-actions">
            <Button color="secondary" variant="contained" onClick={clickStop}>
              Stop generating
            </Button>
          </div>
        ) : null} */}
        <Box component="form" onSubmit={handleSubmit(data => SendChatMessage(data))} autoComplete="off">
          <FormInput
            control={control}
            name={'chat_question'}
            placeholder={'Ask any question...'}
            isRequired={true}
            autoFocus
          />
          <IconButton disabled={is_loading || is_typing} onClick={null} color="primary" size="small">
            <IconSend />
          </IconButton>
        </Box>
      </div>
    </div>
  );
};

const ActiveAvatar = () => {
  const { active_chat } = useSelector(state => state.chatCV);
  return <Avatar children={active_chat ? String(active_chat.name)?.toUpperCase().split('')[0] : ''} />;
};

const ChatMessage = ({ data }) => {
  const is_user = data.from === 'user';
  const dispatch = useDispatch();

  return (
    <div className={`ChatMessage  ${data.from}`}>
      <div className="message-avatar">{is_user ? <ProfileAvatar /> : <ActiveAvatar />}</div>
      <div className="ChatMessage-Body">
        <div className="message-content">
          <div className="message-text">
            {is_user ? (
              data.text
            ) : (
              <TypewriterComponent
                options={{
                  delay: 25,
                }}
                onInit={typewriter => {
                  typewriter.callFunction(e => {});
                  typewriter
                    .typeString(data.text)
                    .callFunction((e, thisArg) => {})
                    .pause()
                    .stop()
                    .callFunction(e => {
                      dispatch(toggleTyping(false));
                    })
                    .start();
                }}
              />
            )}
          </div>
          <div className="message-date">{format(parseISO(data.time), 'p')}</div>
        </div>
      </div>
    </div>
  );
};

const ChatListItem = ({ data, isActive, clickChangeActiveChat, clickRename, clickDelete }) => {
  const { id, name } = data;
  const [showEditMode, setEditMode] = useState(false);
  const [valueNewName, setValueNewName] = useState('');

  const ClickRenameConfirm = () => {
    clickRename(id, valueNewName);
    setEditMode(false);
  };
  return (
    <div className={`ChatListItem ${isActive ? 'active' : ''}`} title={name} onClick={() => clickChangeActiveChat(id)}>
      <span className="icon">
        <IconContact />
      </span>
      {showEditMode ? (
        <div className="rename-form">
          <TextField
            autoFocus
            value={valueNewName}
            placeholder={'New Name'}
            variant="outlined"
            onChange={event => setValueNewName(event.target.value)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                ClickRenameConfirm();
              }
            }}
          />
        </div>
      ) : (
        <span className="name">{name}</span>
      )}
      <div className="actions">
        {showEditMode ? (
          <>
            {' '}
            <IconButton color="white" variant="contained" size="small" title="Rename Chat" onClick={ClickRenameConfirm}>
              <IconCheck />
            </IconButton>
            <IconButton
              color="white"
              variant="contained"
              size="small"
              title="Cancel"
              onClick={event => setEditMode(false)}
            >
              <IconClose />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              onClick={event => {
                event.stopPropagation();
                setEditMode(true);
              }}
              color="white"
              variant="contained"
              size="small"
              title="Rename Chat"
            >
              <IconPencil />
            </IconButton>
            <IconButton
              onClick={event => {
                event.stopPropagation();
                clickDelete(id);
              }}
              color="white"
              variant="contained"
              size="small"
              title="Delete Chat"
            >
              <IconTrash />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
};
