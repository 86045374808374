import { Button, Dialog } from '@mui/material';
import React from 'react';
import { ReactComponent as IconSuccess } from 'assets/icons/success.svg';
import { ReactComponent as IconWarning } from 'assets/icons/warning.svg';
import { ReactComponent as IconAlert } from 'assets/icons/alert.svg';
export const DialogConfirm = props => {
  const {
    open,
    onClose,
    title,
    desc,
    TextConfirmButton,
    ClickConfirmButton,
    type = 'success',
    showCancelButton = true,
  } = props;
  return (
    <Dialog className="Modal-Container Modal-DialogConfirm" maxWidth={'sm'} open={open}>
      <div className="Modal-Box">
        <div className="Modal-Header">
          <div className="icon">
            {type === 'warning' ? <IconWarning /> : ''}
            {type === 'success' ? <IconSuccess /> : ''}
            {type === 'alert' ? <IconAlert /> : ''}
          </div>
          <div className="title">{title}</div>
          <div className="desc" dangerouslySetInnerHTML={{ __html: desc }} />
        </div>
        <div className="Modal-Content"></div>
        <div className="Modal-Footer">
          {showCancelButton ? (
            <Button onClick={onClose} variant="outlined" color="white" fullWidth size="large">
              Cancel
            </Button>
          ) : null}
          <Button
            variant="contained"
            size="large"
            fullWidth
            className={`buton-dialog-type-${type}`}
            onClick={ClickConfirmButton}
          >
            {TextConfirmButton}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
