import { createSlice } from '@reduxjs/toolkit';
import { getDataUsage, getDataUsageDetail } from './analyticsAction';

const initialState = {
  success: null,
  message: null,
  data_usage: null,
  data_usage_detail: null,
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // get all product
    builder.addCase(getDataUsage.pending, state => {
      state.success = null;
    });
    builder.addCase(getDataUsage.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      const arrayData = Object.entries(payload.data).map(item => {
        return {
          date: item[0].split(' ')[0],
          hour: item[0].split(' ')?.[1],
          ...item[1],
        };
      });

      state.data_usage = arrayData;
    });
    builder.addCase(getDataUsage.rejected, (state, { payload }) => {
      state.success = payload.success;
    });

    // getDataUsageDetail
    builder.addCase(getDataUsageDetail.pending, state => {
      state.success = null;
    });
    builder.addCase(getDataUsageDetail.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.data_usage_detail = payload.data;
    });
    builder.addCase(getDataUsageDetail.rejected, (state, { payload }) => {
      state.success = payload.success;
      state.message = payload.message;
    });
  },
});

export default analyticsSlice.reducer;
