import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const getConnectedApps = createAsyncThunk(
  'connected_apps/getConnectedApps',
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('/api/connect/apps');
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const createConnectedApp = createAsyncThunk(
  'connected_apps/createConnectedApp',
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('/api/connect/', postData);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const deleteConnectedApp = createAsyncThunk(
  'connected_apps/deleteConnectedApp',
  async (postData, { rejectWithValue }) => {
    const { appId, keyId } = postData;

    try {
      const { data } = await axios.delete(`/api/connect/${appId}/delete/${keyId}`);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const testZapierExport = createAsyncThunk(
  'connected_apps/testZapierExport',
  async (data, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/api/zapier/test`);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
