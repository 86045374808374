import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const inviteCompanyMember = createAsyncThunk(
  'company/inviteCompanyMember',
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('api/company/member/invite', {
        ...postData,
        authority: String(postData.authority),
        roles: JSON.stringify(postData.roles),
      });

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const updateCompanyMember = createAsyncThunk(
  'company/updateCompanyMember',
  async (data, { dispatch, rejectWithValue }) => {
    const { postData, value = null } = data;
    const sendPostData = {
      ...postData,
      authority: value || postData.authority,
    };

    try {
      const { data } = await axios.post(`api/company/member/${postData.id}`, sendPostData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    } finally {
      dispatch(getAllCompanyMember());
      dispatch(getPendingInviteList());
    }
  },
);

export const deleteCompanyMember = createAsyncThunk(
  'company/deleteCompanyMember',
  async (memberID, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`api/company/member/${memberID}/delete`);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    } finally {
      dispatch(getAllCompanyMember());
      dispatch(getPendingInviteList());
    }
  },
);

export const getAllCompanyMember = createAsyncThunk(
  'company/getAllCompanyMember',
  async (data, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`api/company/member/all`);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const getPendingInviteList = createAsyncThunk(
  'company/getPendingInviteList',
  async (data, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`api/company/member/pending-invite-list`);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const getAuthorityList = createAsyncThunk('company/getAuthorityList', async (data, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`api/company/member/authority-list`);

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getPermissionTemplate = createAsyncThunk(
  'company/getPermissionTemplate',
  async (data, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`api/company/member/permission-template`);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
