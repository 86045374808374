/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Button, Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ProtectedLayout } from 'components/layouts/ProtectedLayout';
import { SocialMedia404 } from 'pages/404';
import { ROUTE_PATHS } from 'data/paths';

export default function UnAuthorized() {
  const navigate = useNavigate();
  const { access_token } = useSelector(state => state.userLogin);
  const { isLoading } = useSelector(state => state.ui);

  return (
    <ProtectedLayout>
      <div className="Page-UnAuthorized">
        <section className="UnAuthorized">
          {isLoading ? null : (
            <Container>
              <Grid>
                <Grid item>
                  <div className="UnAuthorized-Content">
                    <div className="heading">
                      <h1>Unauthorized!</h1>
                    </div>
                    <div className="desc">
                      <p>
                        Oops... <br />
                        Unauthorized
                      </p>
                    </div>
                    <div className="actions">
                      {access_token ? (
                        <Button onClick={() => navigate(ROUTE_PATHS.DASHBOARD)} size="large" variant="contained">
                          Go to Dashboard
                        </Button>
                      ) : (
                        <Button onClick={() => navigate(ROUTE_PATHS.LOGIN)} size="large" variant="contained">
                          Go to Login
                        </Button>
                      )}
                    </div>

                    <SocialMedia404 />
                  </div>
                </Grid>
              </Grid>
            </Container>
          )}
        </section>
      </div>
    </ProtectedLayout>
  );
}
