import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const addCompanyEndpoint = createAsyncThunk('parser/addCompanyEndpoint', async (data, { rejectWithValue }) => {
  const { name, webhook_url, product_id } = data;
  const postData = {
    name,
    webhook_url,
    product_id,
  };
  try {
    const { data } = await axios.post('api/endpoint/', postData);

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getCompanyEndpoints = createAsyncThunk('parser/getCompanyEndpoints', async (data, { rejectWithValue }) => {
  try {
    const { data } = await axios.get('api/endpoint/all');
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getCompanyEndpointDetail = createAsyncThunk(
  'parser/getCompanyEndpointDetail',
  async (data, { rejectWithValue }) => {
    const { id } = data;
    try {
      const { data } = await axios.get(`api/endpoint/${id}`);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const updateCompanyEndpointDetail = createAsyncThunk(
  'parser/updateCompanyEndpointDetail',
  async (data, { rejectWithValue }) => {
    const { name, webhook_url, product_id, id } = data;
    const postData = {
      name,
      webhook_url,
      product_id,
    };
    try {
      const { data } = await axios.put(`api/endpoint/${id}`, postData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const createCompanyEndpointKey = createAsyncThunk(
  'parser/createCompanyEndpointKey',
  async (data, { dispatch, rejectWithValue }) => {
    const { id } = data;
    try {
      const { data } = await axios.get(`api/endpoint/${id}/key/create`);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    } finally {
      dispatch(getCompanyEndpointDetail({ id }));
    }
  },
);

export const deleteCompanyEndpointKey = createAsyncThunk(
  'parser/deleteCompanyEndpointKey',
  async (data, { dispatch, rejectWithValue }) => {
    const { id, endpoint_id } = data;
    try {
      const { data } = await axios.delete(`api/endpoint/key/${id}`);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    } finally {
      dispatch(getCompanyEndpointDetail({ id: endpoint_id }));
    }
  },
);

export const activeCompanyEndpointKey = createAsyncThunk(
  'parser/activeCompanyEndpointKey',
  async (data, { dispatch, rejectWithValue }) => {
    const { id, endpoint_id } = data;
    try {
      const { data } = await axios.get(`api/endpoint/${id}/key/active`);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    } finally {
      dispatch(getCompanyEndpointDetail({ id: endpoint_id }));
    }
  },
);

export const deactiveCompanyEndpointKey = createAsyncThunk(
  'parser/deactiveCompanyEndpointKey',
  async (data, { dispatch, rejectWithValue }) => {
    const { id, endpoint_id } = data;
    try {
      const { data } = await axios.get(`api/endpoint/${id}/key/deactive`);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    } finally {
      dispatch(getCompanyEndpointDetail({ id: endpoint_id }));
    }
  },
);
