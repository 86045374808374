import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const createEvaluator = createAsyncThunk('evaluator/createEvaluator', async (postData, { rejectWithValue }) => {
  try {
    const { data } = await axios.post('api/evaluator/', postData);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getEvaluatorList = createAsyncThunk(
  'evaluator/getEvaluatorList',
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/evaluator/list`);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const postEvaluatorForm = createAsyncThunk(
  'evaluator/postEvaluatorForm',
  async (postData, { rejectWithValue }) => {
    const { code } = postData;
    try {
      const { data } = await axios.post(`api/evaluator/form/${code}`, postData);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const getEvaluatorResult = createAsyncThunk(
  'evaluator/getEvaluatorResult',
  async (postData, { rejectWithValue }) => {
    const { code } = postData;
    try {
      const { data } = await axios.post(`api/evaluator/result/${code}`, postData);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
