import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const getAccount = createAsyncThunk('user/getAccount', async (data, { rejectWithValue }) => {
  try {
    const { data } = await axios.get('api/account');

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
