import React, { useEffect, useState } from 'react';

import { ParserBlock } from 'components/modules/ParserBlock';
import { useDispatch, useSelector } from 'react-redux';

import { updatePageNumber } from 'features/parser/parserSlice';
import { useDebouncedCallback } from 'use-debounce';
import { getAllParserJobList, jobParserMakeRequest } from 'features/parser/jobParserAction';
import { addTagResume, getAllCompanyParserTags, removeResume, removeTagResume } from 'features/parser/parserAction';
import { updateClickedJob } from 'features/parser/jobParserSlice';

export const ParserJob = () => {
  const dispatch = useDispatch();
  const { job_list, page_number, clicked_job } = useSelector(state => state.jobParser);

  const { company_tags } = useSelector(state => state.parser);
  const [valueTableSearch, setValueTableSearch] = useState('');
  const [valueSelectedTags, setValueSelectedTags] = useState('');
  const [openModalAddTagsFiles, setOpenModalAddTagsFiles] = useState(false);
  const [selectedFilterTagSearch, updateSelectedFilterTagSearch] = useState([]);
  const [selectedTagsNewParser, updateselectedTagsNewParser] = useState([]);

  const onChangeValueTableSearch = event => {
    const { value } = event.target;
    setValueTableSearch(value);
  };

  const handleChangePageTable = (event, newPage) => {
    dispatch(updatePageNumber({ page_number: newPage }));
    handleUpdateParserList(null, newPage, null);
  };

  const handleUpdateParserList = (tag_ids = null, current_page = null, keyword = null) => {
    dispatch(
      getAllParserJobList({
        tag_ids,
        current_page,
        keyword,
      }),
    );
  };

  const debounced_update_parser_list = useDebouncedCallback(() => {
    if (valueTableSearch.length > 2 || valueTableSearch.length === 0) {
      handleUpdateParserList(selectedFilterTagSearch, page_number, valueTableSearch);
    }
  }, 200);

  useEffect(() => {
    debounced_update_parser_list();
  }, [valueTableSearch, selectedFilterTagSearch, page_number]);

  useEffect(() => {
    dispatch(getAllCompanyParserTags());
  }, []);

  const handleRemoveParser = ids => {
    dispatch(removeResume({ ids, page_number, parserType: 'job' }));
  };

  const handleRemoveTagParser = (tag, row_id) => {
    const _tag = [tag].map(i => i.id);
    dispatch(removeTagResume({ tag_id: _tag, row_id, page_number, parserType: 'job' }));
  };

  const handleAddTagParser = (tag, row_id) => {
    const _tag = [tag].map(i => i.id);

    dispatch(addTagResume({ tag_id: _tag, row_id, page_number, parserType: 'job' }));
  };

  const handleUpdateClickedJob = resume => {
    dispatch(updateClickedJob(resume));
  };

  const handleUpdateSelectedFilterTagSearch = tag => {
    if (selectedFilterTagSearch.find(stateTag => stateTag.id === tag.id)) {
      updateSelectedFilterTagSearch(prev => prev.filter(i => i.id !== tag.id));
    } else {
      updateSelectedFilterTagSearch(prev => [...prev, tag]);
    }
  };

  const handleCreateParserJob = (job_description, sendType) => {
    const tags = selectedTagsNewParser.map(i => {
      return i.id;
    });
    dispatch(jobParserMakeRequest({ job_description, tag_ids: sendType === 'skip' ? [] : tags })).then(result => {
      handleUpdateParserList();
    });
  };
  return (
    <>
      <ParserBlock
        table_data={job_list}
        company_tags={company_tags}
        removeParser={handleRemoveParser}
        removeTagParser={handleRemoveTagParser}
        addTagParser={handleAddTagParser}
        pageNumber={page_number}
        uploadList={[]}
        valueTableSearch={valueTableSearch}
        setValueTableSearch={setValueTableSearch}
        valueSelectedTags={valueSelectedTags}
        setValueSelectedTags={setValueSelectedTags}
        openModalAddTagsFiles={openModalAddTagsFiles}
        setOpenModalAddTagsFiles={setOpenModalAddTagsFiles}
        onChangeValueTableSearch={onChangeValueTableSearch}
        parserType="job"
        uploadFile={null}
        selectedTagsNewParser={selectedTagsNewParser}
        updateselectedTagsNewParser={updateselectedTagsNewParser}
        changePageTable={handleChangePageTable}
        clicked_row={clicked_job}
        updateClickedRow={handleUpdateClickedJob}
        selectedFilterTagSearch={selectedFilterTagSearch}
        updateSelectedFilterTagSearch={handleUpdateSelectedFilterTagSearch}
        createParserJob={handleCreateParserJob}
      />
    </>
  );
};
