import {
  AlignmentType,
  Document,
  ExternalHyperlink,
  Footer,
  Header,
  ImageRun,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from 'docx';
import { PARAGRAPH_TEXT } from './doc_common';

const DOC_LIST_RESUME = {
  name: {
    label: 'Name Surname',
    value: null,
  },
  current_position: {
    label: 'Current Position',
    value: null,
  },
  location: {
    label: 'Location',
    value: null,
  },
  address: {
    label: 'Street Address',
    value: null,
  },
  zip_code: {
    label: 'ZIP Code',
    value: null,
  },
  email: {
    label: 'Email',
    value: null,
  },
  phone_number: {
    label: 'Phone Number',
    value: null,
  },
};

const DOC_HEADIGN = text => {
  return new Paragraph({
    alignment: AlignmentType.LEFT,
    spacing: {
      before: 40,
      after: 40,
    },
    indent: {
      left: 0,
    },
    children: [
      new TextRun({
        size: 22,
        text: text,
        bold: true,
        color: '2e48cc',
      }),
    ],
  });
};

const DOC_TABLE_PERSONAL_CONTACT = (basic_info, contact_info) => {
  const { name, current_position } = basic_info;
  const { city, country, street_address, zip_code, phone_number, email } = contact_info;

  const DATA_OBJECT = JSON.parse(JSON.stringify(DOC_LIST_RESUME));

  DATA_OBJECT.name.value = name;
  DATA_OBJECT.current_position.value = current_position;
  DATA_OBJECT.location.value = `${city || ''} / ${country || ''}`;
  DATA_OBJECT.address.value = street_address;
  DATA_OBJECT.zip_code.value = zip_code;
  DATA_OBJECT.phone_number.value = phone_number;
  DATA_OBJECT.email.value = email;

  return new Table({
    columnWidths: [2500, 7500],
    rows: Object.entries(DATA_OBJECT).map(([key, value]) => {
      return new TableRow({
        children: [
          new TableCell({
            width: {
              size: 2500,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: value.label,
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            width: {
              size: '75%',
              type: WidthType.PERCENTAGE,
            },
            children: [PARAGRAPH_TEXT(value.value ? value.value : '')],
          }),
        ],
      });
    }),
  });
};

const DOC_TABLE_WORKS = data => {
  return new Table({
    columnWidths: [3000, 2500, 1500, 3000],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [PARAGRAPH_TEXT('Company', true, false)],
          }),
          new TableCell({
            width: {
              size: 2500,
              type: WidthType.DXA,
            },
            children: [PARAGRAPH_TEXT('Job Title', true, false)],
          }),
          new TableCell({
            width: {
              size: 1500,
              type: WidthType.DXA,
            },
            children: [PARAGRAPH_TEXT('Seniority', true, false)],
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [PARAGRAPH_TEXT('Date', true, false)],
          }),
        ],
      }),

      ...data?.map(item => {
        return new TableRow({
          children: [
            new TableCell({
              width: {
                size: 3000,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT(item.company)],
            }),
            new TableCell({
              width: {
                size: 2500,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT(item.job_title)],
            }),
            new TableCell({
              width: {
                size: 1500,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT(item.seniority)],
            }),
            new TableCell({
              width: {
                size: 3000,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT(`${item.start_date} / ${item.end_date}`)],
            }),
          ],
        });
      }),
    ],
  });
};

const DOC_TABLE_EDUCATION = data => {
  return new Table({
    columnWidths: [3000, 3000, 2000, 2000],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [PARAGRAPH_TEXT('School', true, false)],
          }),
          new TableCell({
            width: {
              size: 3000,
              type: WidthType.DXA,
            },
            children: [PARAGRAPH_TEXT('Major', true, false)],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [PARAGRAPH_TEXT('Education Level', true, false)],
          }),
          new TableCell({
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            children: [PARAGRAPH_TEXT('Graduation Date', true, false)],
          }),
        ],
      }),

      ...data?.map(item => {
        return new TableRow({
          children: [
            new TableCell({
              width: {
                size: 3000,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT(item.school_name)],
            }),
            new TableCell({
              width: {
                size: 3000,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT(item.major)],
            }),
            new TableCell({
              width: {
                size: 2000,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT(item.education_level)],
            }),
            new TableCell({
              width: {
                size: 2000,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT(item.graduation_year)],
            }),
          ],
        });
      }),
    ],
  });
};

const DOC_SKILLS = data => {
  return new Table({
    columnWidths: [10000],
    rows: data.map(item => {
      return new TableRow({
        children: [
          new TableCell({
            width: {
              size: 10000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item,
                  }),
                ],
                bullet: {
                  level: 0,
                },
              }),
            ],
          }),
        ],
      });
    }),
  });
};
export const DOC_HIRIZE = async data => {
  const image = await fetch('/images/logo-large.png').then(r => r.blob());

  return new Document({
    background: {
      color: 'f5f5f6',
    },
    styles: {
      default: {
        document: {
          run: {
            font: 'Arial',
          },
          paragraph: {
            spacing: {
              before: 60,
              after: 60,
            },
            indent: {
              left: 120,
            },
          },
        },
      },
    },

    sections: [
      {
        headers: {
          default: new Header({
            alignment: AlignmentType.CENTER,
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new ImageRun({
                    alignment: AlignmentType.CENTER,
                    data: image,
                    transformation: {
                      width: 240,
                      height: 84,
                    },
                  }),
                ],
              }),
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                children: [
                  new TextRun({ text: 'Hirize', bold: true, size: 28 }),
                  new TextRun({ text: ' | Miami, US', bold: false, size: 28 }),
                ],
                alignment: AlignmentType.CENTER,
              }),

              new Paragraph({
                children: [
                  new ExternalHyperlink({
                    children: [
                      new TextRun({
                        bold: true,
                        size: 24,
                        text: 'hirize.hr',
                        style: 'Hyperlink',
                      }),
                    ],
                    link: 'https://hirize.hr/',
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
          }),
        },
        children: [
          PARAGRAPH_TEXT(),
          PARAGRAPH_TEXT(),

          DOC_HEADIGN('PERSONAL & CONTACT DETAILS'),
          PARAGRAPH_TEXT(),
          DOC_TABLE_PERSONAL_CONTACT(data.basic_info, data.contact_info),
          PARAGRAPH_TEXT(),

          DOC_HEADIGN('WORK EXPERIENCE'),
          PARAGRAPH_TEXT(),
          data?.work_experience?.works && DOC_TABLE_WORKS(data?.work_experience?.works),
          PARAGRAPH_TEXT(),

          DOC_HEADIGN('EDUCATION'),
          PARAGRAPH_TEXT(),
          data?.education && DOC_TABLE_EDUCATION(data?.education),
          PARAGRAPH_TEXT(),

          DOC_HEADIGN('SKILLS'),
          PARAGRAPH_TEXT(),
          DOC_SKILLS(data?.skills),
          PARAGRAPH_TEXT(),
        ],
      },
    ],
  });
};
