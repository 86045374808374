import { Button, Dialog, MenuItem, Tab, Tabs, TextField } from '@mui/material';
import React, { useState } from 'react';
import TabPanel from 'components/elements/TabPanel';
import { FormInput } from 'components/elements/FormInput';
import { ReactComponent as IconRight } from 'assets/icons/arrow-right.svg';

import { FormSelect } from 'components/elements/FormSelect';
import { useDispatch, useSelector } from 'react-redux';

import IconMatcher from 'assets/icons/products/ai-matching.png';
import IconMediMind from 'assets/icons/products/medimind.svg';
import { InputTag } from 'components/elements/InputTag';
import { createMatcher } from 'features/matcher/matcherAction';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

export const CreateMatcher = ({ onClose, open, updateMatcherList }) => {
  const dispatch = useDispatch();
  const { list_seniority } = useSelector(state => state.matcher);
  const [tabValue, setTabValue] = useState('filter');
  const [valueSkillList, setValueSkillList] = useState([]);
  const [valueJobDescription, setvalueJobDescription] = useState('');
  const location = useLocation();

  const IS_MEDIMIND = location.pathname.includes('medimind-list');

  const handleChangeJobDescription = event => {
    const { value } = event.target;
    setvalueJobDescription(value.trim());
  };
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({});

  const successStatusAddMatcher = () => {
    reset({
      matcher_name: '',
      matcher_title: '',
      seniority: 1,
    });
    setValueSkillList([]);
    setvalueJobDescription('');
    onClose();
    updateMatcherList();
  };

  const clickCreateMatcher = data => {
    const is_tab_filter = tabValue === 'filter';
    dispatch(
      createMatcher({
        name: data.matcher_name,
        title: is_tab_filter ? data.matcher_title : null,
        seniority_id: is_tab_filter ? data.seniority : null,
        skills: is_tab_filter ? JSON.stringify(valueSkillList) : null,
        job_description: is_tab_filter ? null : valueJobDescription,
        matcher_type: IS_MEDIMIND ? 'MEDI_MIND' : null,
      }),
    ).then(result => {
      if (result.payload.success) {
        successStatusAddMatcher();
      }
    });
  };
  return (
    <Dialog className="Modal-Container Modal-CreateMatcher" onClose={onClose} maxWidth={'sm'} open={open}>
      <div className="Modal-Box">
        <div className="Modal-Header">
          <div className="icon icon-img">
            <img src={IS_MEDIMIND ? IconMediMind : IconMatcher} alt="" />
          </div>
          <div className="title">{IS_MEDIMIND ? 'Create MediMind' : 'Create Matcher'}</div>
          <div className="desc">
            Hirize is different from other matcher, we use magic to <br /> find you the best candidate. Let's give it a
            try!
          </div>
        </div>
        <div className="Modal-Content">
          <div className="Matcher-Name">
            <FormInput
              label={IS_MEDIMIND ? 'MediMind Name' : 'Matcher Name'}
              errors={errors}
              name={'matcher_name'}
              control={control}
              placeholder={'Name your matcher for future searches'}
              isRequired={true}
            />
          </div>
          <Tabs className={'Tabs-Standart Tabs-CreateMatcher'} value={tabValue} onChange={handleChangeTab}>
            <Tab label={'Match by  Filter'} value={'filter'} />
            <Tab label={'Match by Job Description'} value={'desc'} />
          </Tabs>

          <TabPanel value={tabValue} index={'filter'}>
            <div className="Match-By-Filter">
              <div className="filter-list">
                <div className="filter-item">
                  <div className="filter-title">Looking</div>
                  <FormInput
                    errors={errors}
                    name={'matcher_title'}
                    control={control}
                    placeholder={'ie. Software Engineer'}
                    isRequired={true}
                  />
                </div>
                <div className="filter-item">
                  <div className="filter-title">Seniority</div>
                  <FormSelect
                    name="seniority"
                    control={control}
                    errors={errors}
                    placeholder={'Choose Seniority*'}
                    defaultValue={list_seniority?.[0].id || ''}
                  >
                    {list_seniority?.map(item => {
                      return (
                        <MenuItem value={item.id} key={item.id}>
                          {item.seniority}
                        </MenuItem>
                      );
                    })}
                  </FormSelect>
                </div>
                <div className="filter-item">
                  <div className="filter-title">Skills</div>
                  <InputTag
                    viewType={'large'}
                    value={valueSkillList}
                    setValue={setValueSkillList}
                    placeholder={'ie. PHP, Social Skills (enter)'}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={'desc'}>
            <TextField
              placeholder="Paste job description here"
              multiline
              rows={6}
              onChange={handleChangeJobDescription}
              variant="outlined"
              fullWidth
              value={valueJobDescription}
            />
          </TabPanel>
        </div>
        <div className="Modal-Footer">
          <Button onClick={onClose} variant="outlined" color="white" fullWidth className="button-cancel">
            Cancel
          </Button>
          <Button
            variant="contained"
            fullWidth
            className="icon-right icon-color-white"
            endIcon={<IconRight />}
            onClick={handleSubmit(data => clickCreateMatcher(data))}
          >
            Match now
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
