import React, { useCallback, useState } from 'react';
import {
  Badge,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { DATA_JOB_PARSER_LIST_HEAD, DATA_PARSER_LIST_HEAD, STATUS_LIST } from 'data/parser';

import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import { ReactComponent as IconFilter } from 'assets/icons/filter.svg';
import { ReactComponent as IconUpload } from 'assets/icons/upload.svg';
import { ReactComponent as IconPlus } from 'assets/icons/plus-2.svg';
import { ReactComponent as IconSearch } from 'assets/icons/search.svg';
import { ReactComponent as IconClose } from 'assets/icons/close-2.svg';

import { ReactComponent as IconTag } from 'assets/icons/tag.svg';
import { ReactComponent as IconPencil } from 'assets/icons/pencil.svg';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';

import useTableCheckbox from 'hooks/useTableCheckbox';
import { ACCEPT_FILE_FORMATS, MAX_FILE_SIZE } from 'data';
import { TableEmpty } from 'components/elements/TableEmpty';
import { AddTagsFiles } from 'components/modals/AddTagsFiles';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { DialogConfirm } from 'components/modals/DialogConfirm';
import { AutoCompletedTagList } from 'components/elements/AutoCompletedTagList';
import { getUniqueListBy } from 'utils';
import { AddJobParser } from 'components/modals/AddJobParser';
import Date from 'components/elements/Date';
import { useDispatch } from 'react-redux';
import { changeFileName } from 'features/parser/parserAction';
import { Chip } from 'components/elements/Chip';

export const ParserBlock = ({
  table_data,
  uploadList,
  removeParser,
  valueTableSearch,
  pageNumber,
  openModalAddTagsFiles,
  onChangeValueTableSearch,
  setOpenModalAddTagsFiles,
  parserType,
  uploadFile,
  updateSelectedTagList,
  selectedTagList,
  changePageTable,
  company_tags,
  removeTagParser,
  addTagParser,
  selectedTagsNewParser,
  updateselectedTagsNewParser,
  updateClickedRow,
  clicked_row,
  selectedFilterTagSearch,
  updateSelectedFilterTagSearch,
  createParserJob,
}) => {
  const [openModalAddJobParser, setOpenModalAddJobParser] = useState(false);
  const [valueJobDescription, setvalueJobDescription] = useState('');
  const [openDeleteRowDialogConfirm, setOpenDeleteRowDialogConfirm] = useState(false);
  const [anchorElRowTagList, setAnchorElRowTagList] = useState(null);
  const [clickedMultiRemoveRow, updateClickedMultiRemoveRow] = useState(false);
  const [anchorElFilterTagSearch, setAnchorElFilterTagSearch] = useState(null);

  const table_head = parserType === 'job' ? DATA_JOB_PARSER_LIST_HEAD : DATA_PARSER_LIST_HEAD;
  const [handleClickTableCheckBox, handleSelectAllClick, isSelectedCheckbox, selectedCheckbox, setSelectedCheckbox] =
    useTableCheckbox(table_data?.list);

  const onDrop = useCallback(acceptedFiles => {
    setOpenModalAddTagsFiles(true);
  }, []);

  const {
    getRootProps,
    getInputProps,
    open: openDialog,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    noClick: true,
    accept: ACCEPT_FILE_FORMATS,
    maxSize: MAX_FILE_SIZE,
  });

  const handleCreateParserJob = value => {
    setOpenModalAddTagsFiles(true);
  };

  const handleClickAddTagsFilesModal = sendType => {
    if (parserType === 'resume') {
      uploadFile(acceptedFiles, sendType);
    }
    if (parserType === 'job') {
      createParserJob(valueJobDescription, sendType);
    }
  };

  const handleClickRowFilterTags = (event, row) => {
    setAnchorElRowTagList(event.currentTarget);
    updateClickedRow(row);
  };

  const handleClickRemoveRows = event => {
    updateClickedMultiRemoveRow(true);
    setOpenDeleteRowDialogConfirm(true);
  };

  const handleClickConfirmButton = () => {
    if (clickedMultiRemoveRow) {
      const ids = JSON.stringify(selectedCheckbox);
      removeParser(ids);
      setSelectedCheckbox([]);
    } else {
      const ids = JSON.stringify([clicked_row.id]);
      removeParser(ids);
    }
    setOpenDeleteRowDialogConfirm(false);
  };

  return (
    <>
      <AddTagsFiles
        open={openModalAddTagsFiles}
        onClose={() => setOpenModalAddTagsFiles(false)}
        tagList={company_tags}
        updateSelectedTagList={updateSelectedTagList}
        selectedTagList={selectedTagList}
        onClickConfirm={handleClickAddTagsFilesModal}
        selectedTagsNewParser={selectedTagsNewParser}
        updateselectedTagsNewParser={updateselectedTagsNewParser}
      />
      <AddJobParser
        open={openModalAddJobParser}
        onClose={() => setOpenModalAddJobParser(false)}
        onClickConfirm={handleCreateParserJob}
        valueJobDescription={valueJobDescription}
        setvalueJobDescription={setvalueJobDescription}
      />
      <DialogConfirm
        open={openDeleteRowDialogConfirm}
        onClose={() => {
          updateClickedMultiRemoveRow(false);
          updateClickedRow(null);
          setOpenDeleteRowDialogConfirm(false);
        }}
        title="Are you sure?"
        desc={`You are about to delete "${
          clickedMultiRemoveRow ? `${selectedCheckbox.length} files` : clicked_row?.file_name
        }". </br> </br> This action cannot be undone. `}
        TextConfirmButton="Delete"
        ClickConfirmButton={handleClickConfirmButton}
        type="warning"
      />
      <Popover
        onClose={() => setAnchorElRowTagList(null)}
        open={Boolean(anchorElRowTagList)}
        anchorEl={anchorElRowTagList}
        className={'Popover-CompanyTagList'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="CompanyTagList-AutoCompleted">
          <div className="Autocomplete-OpenedStandart-Container">
            <AutoCompletedTagList
              tagList={company_tags}
              removeTagParser={removeTagParser}
              addTagParser={addTagParser}
              clicked_row={clicked_row}
              type={'update'}
            />
          </div>
        </div>
      </Popover>
      <div className="Parser-Block">
        <div className="Parser-Header">
          <div className="Parser-Header-Left">
            <div className="parsed-info">
              <div className="text">Parsed {parserType === 'resume' ? "Resume's" : 'Jobs'}</div>
              <div className="value">({table_data?.total_count})</div>
            </div>
          </div>
          <div className="Parser-Header-Right">
            <div className="search">
              <TextField
                placeholder={'Search'}
                variant="outlined"
                value={valueTableSearch}
                onChange={onChangeValueTableSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconSearch />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="filters">
              <IconButton
                onClick={event => setAnchorElFilterTagSearch(event.currentTarget)}
                variant="outline"
                color="white"
              >
                <Badge badgeContent={selectedFilterTagSearch?.length} color="primary">
                  <IconFilter />
                </Badge>
              </IconButton>
              <Popover
                onClose={() => setAnchorElFilterTagSearch(null)}
                open={Boolean(anchorElFilterTagSearch)}
                anchorEl={anchorElFilterTagSearch}
                className={'Popover-FilterTagSearch'}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className="PopoverBox">
                  <div className="tag-list">
                    {company_tags.length === 0
                      ? 'No tags'
                      : company_tags.map((tag, index) => {
                          const isSelected = selectedFilterTagSearch.find(selectedTag => selectedTag.id === tag.id);
                          return (
                            <div key={index} className="tag-item">
                              <Chip
                                color="primary"
                                type={`${isSelected ? 'tint' : 'stroke'}`}
                                label={tag.tag}
                                size="xsmall"
                                onClick={() => updateSelectedFilterTagSearch(tag)}
                              />
                            </div>
                          );
                        })}
                  </div>
                </div>
              </Popover>
            </div>
            <div className="add-parser">
              {parserType === 'resume' ? (
                <Button startIcon={<IconUpload />} variant="contained" onClick={openDialog}>
                  Upload
                </Button>
              ) : (
                <Button
                  startIcon={<IconPlus />}
                  variant="contained"
                  onClick={() => {
                    setOpenModalAddJobParser(true);
                  }}
                >
                  Add Job
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="Parser-Table">
          <TableContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  <TableCell className="table-head-cell-checkbox" padding="none">
                    <Checkbox
                      color="primary"
                      indeterminate={selectedCheckbox?.length > 0 && selectedCheckbox?.length < table_data?.list.length}
                      checked={table_data?.list?.length > 0 && selectedCheckbox?.length === table_data?.list?.length}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all',
                      }}
                    />
                  </TableCell>

                  {selectedCheckbox?.length > 0 ? (
                    <>
                      <TableCell>
                        <div className="table-head-selected">
                          <div className="selected-info">
                            {selectedCheckbox?.length} document{selectedCheckbox?.length > 1 ? 's' : ''} selected
                          </div>
                          <div className="selected-actions">
                            <TableActions
                              onClickTag={null}
                              onClickDownload={null}
                              onClickDelete={handleClickRemoveRows}
                            />
                          </div>
                        </div>
                      </TableCell>

                      <TableCell sx={{ width: '20%' }}> </TableCell>
                      <TableCell sx={{ width: '20%' }}> </TableCell>
                      <TableCell sx={{ width: '20%' }}> </TableCell>
                    </>
                  ) : (
                    <>
                      {table_head.map(headCell => (
                        <TableCell
                          sx={{ width: headCell.width }}
                          key={headCell.id}
                          align={headCell.align}
                          padding={'none'}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(table_data?.list?.length > 0 || uploadList.length > 0) &&
                  [...uploadList, ...table_data?.list]?.map((row, index) => {
                    const isItemSelected = isSelectedCheckbox(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={event => handleClickTableCheckBox(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        <TableCell className="table-row-cell-checkbox" padding="none">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell className="table-cell-name" component="th" id={labelId} scope="row" padding="none">
                          <TableFileName row={row} current_page={pageNumber} parserType={parserType} />
                        </TableCell>
                        <TableCell padding="none">
                          <TableStatus row={row} />
                        </TableCell>
                        <TableCell padding="none">{row?.integration_platform}</TableCell>
                        <TableCell padding="none">
                          {row.status ? (
                            ''
                          ) : (
                            <TableTags
                              tags={row.tags}
                              rowId={row.id}
                              removeTagParser={removeTagParser}
                              isSelected={isItemSelected}
                            ></TableTags>
                          )}
                        </TableCell>
                        <TableCell width={'40%'} padding="none">
                          <TableActions
                            onClickTag={event => {
                              event.stopPropagation();
                              handleClickRowFilterTags(event, row);
                            }}
                            onClickDownload={event => event.stopPropagation()}
                            onClickDelete={event => {
                              event.stopPropagation();
                              updateClickedRow(row);
                              setOpenDeleteRowDialogConfirm(true);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              {table_data?.list?.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6}>
                      {valueTableSearch === '' && selectedFilterTagSearch.length === 0 ? (
                        <TableEmpty
                          icon={'table-empty-parser'}
                          text={'You have no files uploaded yet'}
                          desc={'PDF, DOCX, PNG, JPEG, JPG, WEBP (max 2 MB)'}
                        />
                      ) : (
                        <TableEmpty icon={'table-empty-parser'} text={'No result found!'} desc={''} />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            {table_data?.list?.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[]}
                labelRowsPerPage=""
                rowsPerPage={table_data?.per_page}
                component="div"
                count={table_data?.total_count}
                page={pageNumber}
                onPageChange={changePageTable}
                SelectProps={{ className: 'table-select-rows-per-page' }}
              />
            )}
          </TableContainer>
        </div>
      </div>
    </>
  );
};

const TableTags = ({ tags, removeTagParser, rowId, isSelected }) => {
  const _tags = tags ? getUniqueListBy(tags, 'id') : [];
  const [showTags, changeShowTags] = useState(false);
  const tagsLength = _tags?.length;
  const isLimit = tagsLength >= 3;
  const limit = isLimit && showTags ? tagsLength : 2;
  const filteredTags = _tags?.slice(0, limit);
  const handlerOnClick = event => {
    event.stopPropagation();
    changeShowTags(true);
  };
  return (
    <div className="TableTags">
      {filteredTags?.map((item, index) => {
        return (
          item.tag && (
            <Chip color="primary" size="xsmall" type={isSelected ? 'tint' : 'stroke'} key={index} label={item.tag} />
          )
        );
      })}
      {isLimit && !showTags && (
        <Chip
          onClick={event => handlerOnClick(event)}
          color="primary"
          type={isSelected ? 'tint' : 'stroke'}
          size="xsmall"
          label={`+${tags.length - 2} more`}
        />
      )}
    </div>
  );
};

const TableActions = ({ onClickTag, onClickDownload, onClickDelete }) => {
  return (
    <div className="TableActions">
      {onClickTag && (
        <IconButton size="small" color="white" onClick={onClickTag}>
          <IconTag />
        </IconButton>
      )}
      {/*  <IconButton size="small" color="white" onClick={onClickDownload}>
        <IconDownload />
      </IconButton> */}
      {onClickDelete && (
        <IconButton size="small" color="red-outlined" onClick={onClickDelete}>
          <IconTrash />
        </IconButton>
      )}
    </div>
  );
};

export const TableStatus = ({ row }) => {
  return (
    <div className="TableUploadStatus">
      {row.created_at ? (
        <div className="date">
          <Date date={row.created_at} />
        </div>
      ) : (
        <div className="status">
          <span className={`icon ${row.status === 'loading' ? 'spinner' : ''}`}>{STATUS_LIST[row.status].icon}</span>
          <span className="text">{STATUS_LIST[row.status].label}</span>
        </div>
      )}
    </div>
  );
};

const TableFileName = ({ row, parserType, current_page }) => {
  const dispatch = useDispatch();
  const [valueFilename, updateValueFilename] = useState(row.file_name);
  const [viewMode, updateViewMode] = useState('text');

  const clickEditFileName = () => {
    dispatch(
      changeFileName({
        id: row.id,
        name: valueFilename,
        parserType,
        current_page,
      }),
    ).then(result => {
      if (result.payload.success) {
        updateViewMode('text');
      }
    });
  };

  const changeMode = event => {
    updateValueFilename(event.target.value);
  };
  return (
    <div className="table-cell-filename" onClick={event => event.stopPropagation()}>
      {viewMode === 'text' ? (
        <div className="filename-text-mode">
          <Link to={`/products/parser/${parserType}/detail/${row.id}`}>{row.file_name}</Link>
          <IconButton color="white" size="small" onClick={event => updateViewMode(event, 'edit')}>
            <IconPencil />
          </IconButton>
        </div>
      ) : (
        <div className="filename-edit-mode">
          <TextField value={valueFilename} placeholder={'New Filename'} variant="outlined" onChange={changeMode} />
          <IconButton color="white" size="small" onClick={event => clickEditFileName(row.id)}>
            <IconCheck />
          </IconButton>
          <IconButton color="white" size="small" onClick={event => updateViewMode('text')}>
            <IconClose />
          </IconButton>
        </div>
      )}
    </div>
  );
};
