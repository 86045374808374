import React from 'react';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

export const Loading = ({ loading }) => {
  const { isLoading } = useSelector(state => state.ui);

  if (isLoading || loading) {
    return (
      <div className="PageLoading">
        <CircularProgress />
      </div>
    );
  } else {
    return <></>;
  }
};
