import { Button as MuiButton } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as IconArrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as IconExternalLink } from 'assets/icons/external-link.svg';
import { ReactComponent as IconArrowRight2 } from 'assets/icons/arrow-right-2.svg';
import { ReactComponent as IconChevronLeft } from 'assets/icons-v2/chevron-left.svg';

const Buttons = ({ children }) => <>{children}</>;

const HistoryBack = ({ text, to, state }) => {
  const navigate = useNavigate();

  return (
    <MuiButton
      variant="contained"
      color="primary"
      startIcon={<IconChevronLeft />}
      onClick={() => navigate(to, { state })}
      size="small"
    >
      {text}
    </MuiButton>
  );
};

const AuthBack = ({ text, onClick }) => {
  return (
    <Link className="button-auth-back" onClick={onClick}>
      <span className="icon">
        <IconArrowLeft />
      </span>
      <span className="text">{text}</span>
    </Link>
  );
};

const ExternalLink = ({ text, url, icon = null }) => {
  return (
    <a className="button-external-link" href={url} rel="noreferrer" target="_blank">
      <span className="text">{text}</span>
      <span className="icon">{icon ? icon : <IconExternalLink />}</span>
    </a>
  );
};

const BadgeLink = ({ text, url, className, icon = null }) => {
  return (
    <Link className={`${className ? className : ''} button-badge-link`} to={url}>
      <span className="text">{text}</span>
      <span className="icon">{icon ? icon : <IconArrowRight2 />}</span>
    </Link>
  );
};

const LinkContactUs = ({ text, href, target, icon = null }) => {
  return (
    <a rel="noreferrer" target={target} href={href} className="link-contact-us">
      <span className="icon">{icon}</span>
      <span className="text">{text}</span>
    </a>
  );
};

Buttons.HistoryBack = HistoryBack;
Buttons.ExternalLink = ExternalLink;
Buttons.BadgeLink = BadgeLink;
Buttons.AuthBack = AuthBack;
Buttons.LinkContactUs = LinkContactUs;

export default Buttons;
