import { createSlice } from '@reduxjs/toolkit';
import { snackActions } from 'libs/snackbar';
import { userLogin } from './userLoginAction';

const access_token = localStorage.getItem('access_token');
const expires_in = localStorage.getItem('expires_in');
const refresh_token = localStorage.getItem('refresh_token');

const initialState = {
  success: null,
  message: null,
  expires_in: expires_in,
  access_token: access_token,
  refresh_token: refresh_token,
  error: false,
  loading: false,
};

export const userLoginSlice = createSlice({
  name: 'userLogin',
  initialState,
  reducers: {
    logout: (state, action) => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('expires_in');
      localStorage.removeItem('refresh_token');
      state.expires_in = null;
      state.access_token = null;
      state.refresh_token = null;
    },
  },
  extraReducers: builder => {
    // login user
    builder.addCase(userLogin.pending, state => {
      state.message = null;
      state.error = null;
      state.loading = true;
    });
    builder.addCase(userLogin.fulfilled, (state, { payload }) => {
      state.message = payload.message;
      state.expires_in = payload.expires_in;
      state.access_token = payload.access_token;
      state.refresh_token = payload.refresh_token;
      state.success = true;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(userLogin.rejected, (state, { payload }) => {
      state.message = payload;
      snackActions.error(payload.error_description);
      state.success = false;
      state.error = payload.error;
      state.loading = false;
    });
  },
});

export const { logout } = userLoginSlice.actions;

export default userLoginSlice.reducer;
