import { ProtectedLayout } from 'components/layouts/ProtectedLayout';

import React, { useEffect } from 'react';
import { Product as CardProduct } from 'components/cards/Product';
import { Grid } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { getAllProduct, getAllProductFunctions } from 'features/product/productAction';

export const Products = () => {
  const dispatch = useDispatch();
  const { allProduct, allProductFunctions } = useSelector(state => state.product);

  useEffect(() => {
    dispatch(getAllProduct());
    dispatch(getAllProductFunctions());
  }, []);

  return (
    <ProtectedLayout>
      <div className="Page Page-Products has-upgrade-now">
        <div className="Products">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="title">Products</div>
              <div className="desc">
                Discover Hirize's powerful core APIs, designed to provide the ultimate user experience.
              </div>
            </div>
            <div className="Heading-End">
              {/* <div className="credit-info">
                <CreditStatusBox credit={creditInfo} />
              </div> */}
            </div>
          </div>
          <div className="Products-List">
            <Grid className="grid-product-list" container columnSpacing={'24px'} rowSpacing={'24px'}>
              {allProduct?.map((item, index) => {
                return (
                  <Grid key={item.id} item sm={3}>
                    <CardProduct type="normal" data={item} />
                  </Grid>
                );
              })}
            </Grid>
          </div>

          <div className="Products-Features-List">
            <div className="heading">
              <div className="title">Useful Features</div>
              <div className="desc">Take your usage to the next level by unlocking these useful features.</div>
            </div>
            <Grid className="grid-product-features-list" container columnSpacing={'24px'} rowSpacing={'24px'}>
              {allProductFunctions?.map((item, index) => {
                return (
                  <Grid key={item.id} item sm={3}>
                    <CardProduct type="feature" data={item} />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};
