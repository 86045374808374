import { ReactComponent as IconVisa } from 'assets/icons/credit-cards/visa.svg';
import { ReactComponent as IconMasterCard } from 'assets/icons/credit-cards/master-card.svg';
import { ReactComponent as IconUnionPay } from 'assets/icons/credit-cards/union-pay.svg';
import { ReactComponent as IconDinersClub } from 'assets/icons/credit-cards/diners-club.svg';
import { ReactComponent as IconJCB } from 'assets/icons/credit-cards/jcb.svg';
import { ReactComponent as IconAmericanExpress } from 'assets/icons/credit-cards/american-express.svg';
import { ReactComponent as IconDiscover } from 'assets/icons/credit-cards/discover.svg';

import { ReactComponent as IconArrowDown2 } from 'assets/icons/arrow-down-2.svg';
import { ReactComponent as IconSelected } from 'assets/icons/selected.svg';
import { ReactComponent as IconUnSelected } from 'assets/icons/unselected.svg';

export const CURRENCY_SYMBOL_LİST = {
  usd: '$',
  euro: '€',
  tl: '₺',
};

export const PAYMENT_STATUS = {
  paid: 'Paid',
  failed: 'Failed',
  pending: 'Pending',
};

export const ROLE_TYPES = [
  {
    id: 'admin',
    label: 'Admin',
  },
  {
    id: 'developer',
    label: 'Developer',
  },
  {
    id: 'member',
    label: 'Member',
  },
  {
    id: 'billing',
    label: 'Billing',
  },
];

export const CREDIT_CARD_BRANDS = {
  visa: <IconVisa />,
  mastercard: <IconMasterCard />,
  unionpay: <IconUnionPay />,
  dinersclub: <IconDinersClub />,
  jcb: <IconJCB />,
  americanexpress: <IconAmericanExpress />,
  discover: <IconDiscover />,
};

export const CHECKBOXTREE_ICONS = {
  check: <IconSelected />,
  uncheck: <IconUnSelected />,
  halfCheck: <IconUnSelected />,
  expandClose: <IconArrowDown2 />,
  expandOpen: <IconArrowDown2 />,
  expandAll: null,
  collapseAll: null,
  parentClose: null,
  parentOpen: null,
  leaf: null,
};

export const PASSWORD_LEVEL = {
  1: 'Weak',
  2: 'Medium',
  3: 'Medium',
  4: 'Strong',
};

export const URL_DASHBOARD_API_TEST = 'https://dashboard-fe.dev.hirize.hr:8443/';
export const URL_DASHBOARD_API_LIVE = 'https://app.hirize.hr/';
export const URL_DASHBOARD_API_LOCAL = 'http://localhost:3000/';
export const URL_CHAT_CV = 'https://chatcv.ai/';
