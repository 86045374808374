import { DialogConfirm } from 'components/modals/DialogConfirm';
import { ROUTE_PATHS } from 'data/paths';
import { verifyEmailAddress, verifyResendCode } from 'features/userMember/userMemberAction';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

export const Verification = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openVerificationDialogConfirm, setOpenVerificationDialogConfirm] = useState(true);

  const verify_detail = React.useMemo(() => new URLSearchParams(search), [search]);

  const { is_verify_expired } = useSelector(state => state.userMember);
  const SendCodeVerificationForm = () => {
    dispatch(
      verifyEmailAddress({
        token: verify_detail.get('token'),
        email: verify_detail.get('email'),
      }),
    );
  };

  useEffect(() => {
    SendCodeVerificationForm();
  }, [verify_detail]);

  const clickResendVerification = () => {
    dispatch(
      verifyResendCode({
        email: verify_detail.get('email'),
      }),
    );
  };

  return (
    <div className="Page-Verification">
      <div className="Page-Content">
        {!is_verify_expired ? (
          <DialogConfirm
            open={openVerificationDialogConfirm}
            onClose={() => {
              setOpenVerificationDialogConfirm(false);
            }}
            title={'Verified!'}
            desc={'Your email was successfuly verified.'}
            TextConfirmButton={'Go to Dashboard'}
            ClickConfirmButton={() => navigate(ROUTE_PATHS.DASHBOARD)}
            type="success"
            showCancelButton={false}
          />
        ) : null}

        {is_verify_expired ? (
          <DialogConfirm
            open={openVerificationDialogConfirm}
            onClose={() => {
              setOpenVerificationDialogConfirm(false);
            }}
            title={'Your email could not be verified!'}
            desc={`This link has expired. <br/> Please send a verification request.`}
            TextConfirmButton={'Resend verification link'}
            ClickConfirmButton={clickResendVerification}
            type="warning"
            showCancelButton={false}
          />
        ) : null}
      </div>
    </div>
  );
};
