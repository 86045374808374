import { ReactComponent as IconFlash } from 'assets/icons-v2/flash.svg';
import { Chip } from 'components/elements/Chip';
import { useSelector } from 'react-redux';

export const ChipDemo = () => {
  return <Chip size="small" color="primary" type="tint" label="Demo" />;
};

export const ChipPro = () => {
  return <Chip size="small" color="warning" type="stroke" label="PRO" icon={<IconFlash />} />;
};

export const ChipDemoPro = ({ type }) => {
  const { account_data } = useSelector(state => state.account);

  if (account_data?.customer_state === 'active' || account_data?.customer_state === 'passive') {
    return null;
  }

  if (type === 'pro') {
    return <ChipPro />;
  }

  if (type === 'demo') {
    return <ChipDemo />;
  }
};
