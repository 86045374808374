import { createSlice } from '@reduxjs/toolkit';
import { snackActions } from 'libs/snackbar';
import { createConnectedApp, deleteConnectedApp, getConnectedApps, testZapierExport } from './connectedAppsAction';

const initialState = {
  success: null,
  success_connect_apps: null,
  connected_apps: null,
};

export const connectedAppsSlice = createSlice({
  name: 'connected_apps',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // getConnectedApps
    builder.addCase(getConnectedApps.pending, state => {
      state.success = null;
    });
    builder.addCase(getConnectedApps.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.connected_apps = payload.data;
    });
    builder.addCase(getConnectedApps.rejected, (state, { payload }) => {
      state.success = payload;
    });

    // createConnectedApp

    builder.addCase(createConnectedApp.pending, state => {
      state.success_connect_apps = null;
    });
    builder.addCase(createConnectedApp.fulfilled, (state, { payload }) => {
      state.success_connect_apps = payload.success;
      snackActions.success('API Key added successfully!');
    });
    builder.addCase(createConnectedApp.rejected, (state, { payload }) => {
      state.success_connect_apps = payload;
    });

    // deleteConnectedApp

    builder.addCase(deleteConnectedApp.pending, state => {
      state.success_connect_apps = null;
    });
    builder.addCase(deleteConnectedApp.fulfilled, (state, { payload }) => {
      state.success_connect_apps = payload.success;
      snackActions.success('API Key deleted successfully!');
    });
    builder.addCase(deleteConnectedApp.rejected, (state, { payload }) => {
      state.success_connect_apps = payload;
    });

    // testZapierExport

    builder.addCase(testZapierExport.pending, state => {
      state.success_connect_apps = null;
    });
    builder.addCase(testZapierExport.fulfilled, (state, { payload }) => {
      state.success_connect_apps = payload.success;
      snackActions.success('Test successfully!');
    });
    builder.addCase(testZapierExport.rejected, (state, { payload }) => {
      state.success_connect_apps = payload;
    });
  },
});

export default connectedAppsSlice.reducer;
