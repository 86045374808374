import { ProtectedLayout } from 'components/layouts/ProtectedLayout';

import React, { useEffect } from 'react';
import { Box, Button, Grid, IconButton, InputLabel, MenuItem } from '@mui/material';
import Buttons from 'components/elements/buttons';
import { useForm } from 'react-hook-form';
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';
import { ReactComponent as IconCopy } from 'assets/icons/copy-2.svg';

import { FormInput } from 'components/elements/FormInput';
import { FormSelect } from 'components/elements/FormSelect';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeCompanyEndpointKey,
  addCompanyEndpoint,
  createCompanyEndpointKey,
  deactiveCompanyEndpointKey,
  deleteCompanyEndpointKey,
  getCompanyEndpointDetail,
  updateCompanyEndpointDetail,
} from 'features/endpoint/endpointAction';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllProduct, getAllProductFunctions } from 'features/product/productAction';
import { URL_API_DOCUMENTATION } from 'data';
import { copyResult } from 'utils';
import { ROUTE_PATHS } from 'data/paths';

export const EndPointForm = ({ history, match }) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allProduct, allProductFunctions } = useSelector(state => state.product);
  const { company_endpoint_detail } = useSelector(state => state.endpoint);

  const IS_NEW_MODE = !id;
  const IS_UPDATE_MODE = !!id;

  const handlerClickChangeStatus = key => {
    if (key.is_active) {
      dispatch(deactiveCompanyEndpointKey({ id: key.id, endpoint_id: id }));
    } else {
      dispatch(activeCompanyEndpointKey({ id: key.id, endpoint_id: id }));
    }
  };

  const handlerClickCopyKey = key => {
    copyResult(key.key, 'Key copied!');
  };

  const handlerClickDeleteKey = key => {
    dispatch(deleteCompanyEndpointKey({ id: key.id, endpoint_id: id }));
  };

  const clickGenerateKey = () => {
    dispatch(createCompanyEndpointKey({ id }));
  };

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({});

  const SendEndPointForm = data => {
    const { endpoint_name, webhook_url, product } = data;
    if (IS_NEW_MODE) {
      dispatch(
        addCompanyEndpoint({
          name: endpoint_name,
          webhook_url: webhook_url,
          product_id: product,
        }),
      ).then(result => {
        if (result.payload.success) {
          navigate(`/endpoints/edit/${result.payload.data}`);

          dispatch(getCompanyEndpointDetail({ id: result.payload.data }));
        }
      });
    }

    if (IS_UPDATE_MODE) {
      dispatch(
        updateCompanyEndpointDetail({
          name: endpoint_name,
          webhook_url: webhook_url,
          product_id: product,
          id: id,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(getAllProduct());
    dispatch(getAllProductFunctions());
    if (IS_UPDATE_MODE) {
      dispatch(getCompanyEndpointDetail({ id }));
    }
  }, []);

  useEffect(() => {
    if (company_endpoint_detail && IS_UPDATE_MODE) {
      const { name, product, webhook_url } = company_endpoint_detail;
      reset({
        endpoint_name: name,
        webhook_url: webhook_url,
        product: product.id,
      });
    }
  }, [company_endpoint_detail]);

  return (
    <ProtectedLayout>
      <div className="Page Page-EndPoint">
        <div className="EndPoint">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="top-action">
                <Buttons.HistoryBack text={'Back to End-points'} to={ROUTE_PATHS.ENDPOINTS_LIST} />
              </div>
              <div className="title">{IS_NEW_MODE ? 'New End-Point' : 'Update End-Point'}</div>
              <div className="desc">
                Use your API Key to authenticate, refer to the{' '}
                <a
                  href={IS_UPDATE_MODE ? company_endpoint_detail?.api_doc_url : URL_API_DOCUMENTATION}
                  target={'_blank'}
                  rel="noreferrer"
                >
                  documentation
                </a>{' '}
                for usage details.
              </div>
            </div>
          </div>
          <div className="EndPoint-Form">
            <Box component="form" onSubmit={handleSubmit(data => SendEndPointForm(data))} autoComplete="off">
              <Grid container rowSpacing={{ xs: '32px' }} columnSpacing={{ xs: '24px' }}>
                <Grid item sm={6}>
                  <FormInput
                    label={'End-Point Name'}
                    errors={errors}
                    name={'endpoint_name'}
                    control={control}
                    placeholder={'End-Point Name*'}
                    isRequired={true}
                    helperText={'Identifies this source within your workspace'}
                  />
                </Grid>

                <Grid item sm={6}>
                  <FormInput
                    label={'Webhook URL'}
                    errors={errors}
                    name={'webhook_url'}
                    control={control}
                    placeholder={'Webhook URL*'}
                    isRequired={true}
                    helperText={
                      'A POST request will be made to the URL here after the API has finished processing the request.'
                    }
                  />
                </Grid>

                <Grid item xs={6} sm={6}>
                  <FormSelect
                    name="product"
                    control={control}
                    errors={errors}
                    label={'Choose Product*'}
                    defaultValue={1 || ''}
                  >
                    {allProduct &&
                      allProductFunctions &&
                      [...allProduct, ...allProductFunctions].map(product => {
                        return (
                          <MenuItem key={product.id} value={product.id}>
                            {product.name}
                          </MenuItem>
                        );
                      })}
                  </FormSelect>
                </Grid>
                {IS_UPDATE_MODE ? (
                  <Grid item xs={12} sm={12}>
                    <InputLabel className="Label-Autocomplete">
                      Keys<i className="required">*</i>
                    </InputLabel>
                    <div className="key-list">
                      {company_endpoint_detail?.keys?.map((key, index) => {
                        return (
                          <KeyField
                            key={key.id}
                            data={key}
                            onClickChangeStatus={() => handlerClickChangeStatus(key)}
                            onClickDeleteKey={() => handlerClickDeleteKey(key)}
                            onClickCopyKey={() => handlerClickCopyKey(key)}
                          />
                        );
                      })}
                    </div>
                    <div className="action">
                      <Button
                        onClick={clickGenerateKey}
                        variant="outlined"
                        color="white"
                        disabled={company_endpoint_detail?.keys?.length >= 2}
                        endIcon={<IconPlus />}
                      >
                        Generate Key
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  ''
                )}

                <Grid className="form-footer" item xs={12} sm={12}>
                  <Button
                    onClick={handleSubmit(data => SendEndPointForm(data))}
                    sx={{ minWidth: '170px' }}
                    variant="contained"
                  >
                    {IS_NEW_MODE ? 'Create End-Point' : 'Update End-Point'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};

const KeyField = ({ data, onClickChangeStatus, onClickDeleteKey, onClickCopyKey }) => {
  const { key, is_active } = data;
  return (
    <div className="KeyField">
      <div className={`key key-${is_active ? 'active' : 'deactive'}`}>{key}</div>
      <div className="actions ">
        <Button variant="outlined" color="white" size="small" onClick={onClickChangeStatus}>
          {is_active ? 'Deactivate' : 'Active'}
        </Button>
        <Button variant="outlined" color="white" size="small" onClick={onClickDeleteKey}>
          Delete
        </Button>
        <div className="action-copy">
          <IconButton color="white" size="small" onClick={onClickCopyKey}>
            <IconCopy />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
