import React from 'react';

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`hirize-tabpanel-${index}`}
      aria-labelledby={`hirize-tab-${index}`}
      {...other}
    >
      {value === index && <div className="tabpabel-container">{children} </div>}
    </div>
  );
}
