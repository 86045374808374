import { ProtectedLayout } from 'components/layouts/ProtectedLayout';

import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { NAV_SETTIGNS_TAB } from 'data/settings';
import { useDispatch, useSelector } from 'react-redux';
import { getAllInvoices } from 'features/company/companyAction';

export const Settings = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { company_plan_data } = useSelector(state => state.company);

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const newValue = NAV_SETTIGNS_TAB.findIndex(i => i.path === pathname);
    setTabValue(company_plan_data ? newValue : 0);
  }, [pathname, company_plan_data]);

  useLayoutEffect(() => {
    dispatch(getAllInvoices());
  }, []);

  const handleChangeTab = (event, newValue) => {
    navigate(NAV_SETTIGNS_TAB[newValue]['path']);
    setTabValue(newValue);
  };

  return (
    <ProtectedLayout>
      <div className="Page Page-Settings">
        <div className="Settings">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="title">Settings</div>
            </div>
          </div>
          <div className="Page-Nav">
            <div className="nav-settings">
              <Tabs className={'Tabs-Standart Tabs-SettingsNav'} value={tabValue} onChange={handleChangeTab}>
                {NAV_SETTIGNS_TAB.map((item, index) => {
                  if (item.id === 'billing-invoices' && false) {
                    return null;
                  }
                  return <Tab key={index} label={item.label} value={index} />;
                })}
              </Tabs>
            </div>
          </div>
          <div className="Settings-Page">
            <Outlet />
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};
