import { createSlice } from '@reduxjs/toolkit';
import { DATA_CHAT_LIST } from 'data/chat-cv';

const initialState = {
  chat_list: DATA_CHAT_LIST,
  active_chat: null,
  is_loading: null,
  is_typing: null,
  is_stop: null
};

export const uiSlice = createSlice({
  name: 'chatCV',
  initialState,
  reducers: {
    addNewChat: (state, { payload }) => {
      state.chat_list.push(payload);
      state.active_chat = {
        name: payload.name,
        id: payload.id,
        history: [],
      };
    },
    deleteChat: (state, { payload }) => {
      state.chat_list = state.chat_list.filter(item => {
        return item.id !== payload.id;
      });
    },
    renameChat: (state, { payload }) => {
      state.chat_list = state.chat_list.map(item => {
        if (item.id === payload.id) {
          return {
            ...item,
            name: payload.new_name,
          };
        }
        return item;
      });
    },
    updateActiveChat: (state, { payload }) => {
      state.active_chat = state.chat_list.filter(item => item.id === payload.id)[0];
    },
    submitChatMessage: (state, { payload }) => {
      state.active_chat.history.push(payload.newMessage);
    },
    toggleLoading: (state, {payload}) => {
      state.is_loading = payload
    },
    toggleTyping: (state, {payload}) => {
      state.is_typing = payload
    },
    toggleStop: (state, {payload}) => {
      state.is_stop = payload
    }
  },
});

export const { addNewChat, deleteChat, renameChat, submitChatMessage, updateActiveChat, toggleLoading, toggleTyping, toggleStop } = uiSlice.actions;

export default uiSlice.reducer;
