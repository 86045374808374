import { createSlice } from '@reduxjs/toolkit';
import { FORM_TEXT } from 'data';
import { snackActions } from 'libs/snackbar';
import { deleteProfilePicture, getProfile, updateProfile, updateProfilePicture } from './profileAction';

const initialState = {
  success: null,
  successUpdateProfile: null,
  message: null,
  profile: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetProfileState: () => initialState,
  },
  extraReducers: builder => {
    // get profile
    builder.addCase(getProfile.pending, state => {
      state.success = null;
    });
    builder.addCase(getProfile.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.profile = payload.data;
    });
    builder.addCase(getProfile.rejected, (state, { payload }) => {
      state.success = payload.success;
    });

    // update profile
    builder.addCase(updateProfile.pending, state => {
      state.successUpdateProfile = null;
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.successUpdateProfile = payload.success;
      state.message = FORM_TEXT.form_successful_message;
      snackActions.success(FORM_TEXT.form_successful_message);
    });
    builder.addCase(updateProfile.rejected, (state, { payload }) => {
      state.successUpdateProfile = payload.success;
      state.message = payload.message;
    });

    // update profile picture
    builder.addCase(updateProfilePicture.pending, state => {
      state.success = null;
    });
    builder.addCase(updateProfilePicture.fulfilled, (state, { payload }) => {
      state.success = payload.success;
    });
    builder.addCase(updateProfilePicture.rejected, (state, { payload }) => {
      state.success = payload.success;
    });

    // update profile picture
    builder.addCase(deleteProfilePicture.pending, state => {
      state.success = null;
    });
    builder.addCase(deleteProfilePicture.fulfilled, (state, { payload }) => {
      state.success = payload.success;
    });
    builder.addCase(deleteProfilePicture.rejected, (state, { payload }) => {
      state.success = payload.success;
    });
  },
});
export const { resetProfileState } = profileSlice.actions;

export default profileSlice.reducer;
