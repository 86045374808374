import { createTheme } from '@mui/material/styles';
import { ReactComponent as IconChecked } from 'assets/icons/checkbox-checked.svg';
import { ReactComponent as IconUnChecked } from 'assets/icons/checkbox-unchecked.svg';
import { ReactComponent as IconIndeterminate } from 'assets/icons/checkbox-indeterminate.svg';

/* const fontFamily = ['Inter', 'sans-serif'].join(','); */

const themeSettings = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1025,
      lg: 1272,
      xl: 1272,
    },
  },
  typography: {
    fontFamily: "'Eudoxus Sans', 'system-ui', 'sans-serif'",
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    black: {
      main: '#000',
      darker: '#fff',
    },
    white: {
      main: '#fff',
      contrastText: '#000',
    },
    transparent: {
      main: '#fff',
      contrastText: '#000',
    },
    navy: {
      main: '#000',
    },
    gray: {
      main: '#000',
    },
    red: {
      main: '#000',
    },
  },
  components: {
    // Name of the component
    MuiCheckbox: {
      defaultProps: {
        icon: <IconUnChecked />,
        checkedIcon: <IconChecked />,
        indeterminateIcon: <IconIndeterminate />,
      },
    },

    Tooltip: {
      defaultProps: {
        disableFocusListener: true,
        disableHoverListener: true,
        disableTouchListener: true,
        arrow: true,
        placement: 'bottom-end',
      },
    },
  },
});

export default themeSettings;
