export const DATA_END_POINTS_HEAD = [
  {
    id: 'name',
    label: 'NAME / TYPE',
  },
  {
    id: 'source_key',
    label: 'API KEY',
  },
  {
    id: 'product',
    label: 'PRODUCT',
  },
  {
    id: 'creation_date',
    label: 'CREATION DATE',
  },
  {
    id: 'status',
    label: 'STATUS',
  },
  {
    id: 'action',
    label: 'ACTION',
  },
];
