import { createSlice } from '@reduxjs/toolkit';
import { snackActions } from 'libs/snackbar';
import {
  getAllSeniorityList,
  createMatcher,
  addResumeMatcher,
  getAllMatcherList,
  getMatcherDetail,
  searchMatcherRequestList,
  getResumeDetail,
  getMatcherMedicalScope,
  deleteMatcher,
  deleteMatcherResume,
} from './matcherAction';

const initialState = {
  loading: false,
  success: null,
  success_matcher_delete: null,
  message: null,
  success_matcher_detail: null,
  list_seniority: null,
  list_matcher: null,
  list_matcher_medical_scope: null,
  detail_matcher: null,
  detail_matcher_search: null,
  resume_detail: {},
  matcher_resume_upload_progress: [],
};

export const parserSlice = createSlice({
  name: 'matcher',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetMatcherDetail: (state, { payload }) => {
      state.detail_matcher = null;
      state.detail_matcher_search = null;
    },
    updateUploadFileProgress: (state, { payload }) => {
      const { files } = payload;
      state.matcher_resume_upload_progress = files.map(item => {
        return {
          ...item,
          status: 'loading',
          name: item.name,
        };
      });
    },
    changeUploadFileProgressStatus: (state, { payload }) => {
      const { index, status } = payload;
      state.matcher_resume_upload_progress[index]['status'] = status;
    },
    resetUploadFileProgress: (state, { payload }) => {
      state.matcher_resume_upload_progress = [];
      state.success_resume_list = null;
    },
  },
  extraReducers: builder => {
    // getAllSeniorityList
    builder.addCase(getAllSeniorityList.pending, state => {
      state.success = null;
    });
    builder.addCase(getAllSeniorityList.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.list_seniority = payload.data;
    });
    builder.addCase(getAllSeniorityList.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // createMatcher
    builder.addCase(createMatcher.pending, state => {
      state.success = null;
    });
    builder.addCase(createMatcher.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      snackActions.success('Matcher created successfully!');
    });
    builder.addCase(createMatcher.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // addResumeMatcher
    builder.addCase(addResumeMatcher.pending, state => {
      state.success = null;
      state.loading = true;
    });
    builder.addCase(addResumeMatcher.fulfilled, (state, { payload }) => {
      const is_all_success = payload.every(item => item.success);
      const is_some_success = payload.some(item => item.success);
      if (is_all_success) {
        snackActions.success('All Resumes uploaded successfully!');
      } else if (is_some_success) {
        snackActions.warning('An error occurred while uploading some resumes!');
      }
      state.loading = false;

      /* state.success = payload.success; */
    });
    builder.addCase(addResumeMatcher.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
      state.loading = false;
    });

    // getAllMatcherList
    builder.addCase(getAllMatcherList.pending, state => {
      state.success = null;
    });
    builder.addCase(getAllMatcherList.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.list_matcher = payload.data;
    });
    builder.addCase(getAllMatcherList.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
      state.list_matcher = null;
    });

    // getMatcherDetail
    builder.addCase(getMatcherDetail.pending, state => {
      state.success_matcher_detail = null;
    });
    builder.addCase(getMatcherDetail.fulfilled, (state, { payload }) => {
      state.success_matcher_detail = payload.success;

      state.detail_matcher = payload.data;
    });
    builder.addCase(getMatcherDetail.rejected, (state, { payload }) => {
      state.success_matcher_detail = false;
      state.message = payload;
    });

    // searchMatcherRequestList
    builder.addCase(searchMatcherRequestList.pending, state => {
      state.success = null;
    });
    builder.addCase(searchMatcherRequestList.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.detail_matcher_search = payload.data;
    });
    builder.addCase(searchMatcherRequestList.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // getResumeDetail
    builder.addCase(getResumeDetail.pending, state => {
      state.success = null;
    });
    builder.addCase(getResumeDetail.fulfilled, (state, { payload }) => {
      state.success = payload.result.success;
      state.resume_detail[payload.id] = payload.result.data;
    });
    builder.addCase(getResumeDetail.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // getMatcherMedicalScope
    builder.addCase(getMatcherMedicalScope.pending, state => {
      state.success = null;
    });
    builder.addCase(getMatcherMedicalScope.fulfilled, (state, { payload }) => {
      state.success = payload.result.success;
      state.list_matcher_medical_scope = Object.entries(payload.result.data).map(entry => {
        return {
          id: entry[0],
          label: entry[1],
        };
      });
    });
    builder.addCase(getMatcherMedicalScope.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // deleteMatcher
    builder.addCase(deleteMatcher.pending, state => {
      state.success_matcher_delete = null;
    });
    builder.addCase(deleteMatcher.fulfilled, (state, { payload }) => {
      state.success_matcher_delete = payload.result.success;
    });
    builder.addCase(deleteMatcher.rejected, (state, { payload }) => {
      state.success_matcher_delete = false;
      state.message = payload;
    });

    // deleteMatcherResume
    builder.addCase(deleteMatcherResume.pending, state => {
      state.success_matcher_delete = null;
    });
    builder.addCase(deleteMatcherResume.fulfilled, (state, { payload }) => {
      state.success_matcher_delete = payload.result.success;
    });
    builder.addCase(deleteMatcherResume.rejected, (state, { payload }) => {
      state.success_matcher_delete = false;
      state.message = payload;
    });
  },
});

export const {
  resetState,
  resetMatcherDetail,
  updateUploadFileProgress,
  changeUploadFileProgressStatus,
  resetUploadFileProgress,
} = parserSlice.actions;

export default parserSlice.reducer;
