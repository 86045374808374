import {
  Button,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import React, { useState } from 'react';
import { ReactComponent as IconReceipt } from 'assets/icons/receipt.svg';
import { ReactComponent as IconDownload } from 'assets/icons/download.svg';

import { DATA_INVOICES_LIST_HEAD } from 'data/settings';
import { useSelector } from 'react-redux';
import { TableEmpty } from 'components/elements/TableEmpty';
import Date from 'components/elements/Date';
import { NumberFormatterDollar, crateNewDate } from 'utils';
import { ReactComponent as IconCards } from 'assets/icons/cards.svg';
import { Chip } from 'components/elements/Chip';
import { Link } from 'react-router-dom';
import { NumberFormatter, isWhatPercentOf } from 'utils/index';
import { differenceInDays, differenceInMonths, format, parseISO } from 'date-fns';
import { ROUTE_PATHS } from 'data/paths';
import { ReactComponent as IconChevronRight } from 'assets/icons-v2/chevron-right.svg';

export const BillingInvoices = () => {
  const { company_plan_data } = useSelector(state => state.company);
  const IS_PLAN_FREE = company_plan_data?.is_free;

  return (
    <div className="Page-BillingInvoices">
      <div className="BillingInvoices">
        <div className="White-Box BillingInvoices-Box">
          <div className="payment">
            <div className="box-heading">
              <div className="heading-start">
                <div className="title">Payment details</div>
              </div>
            </div>
            <Grid className="payment-info-list" container columnSpacing={'24px'}>
              <Grid item sm={6}>
                <CardPaymentPlanDetail />
              </Grid>
              <Grid item sm={6}>
                {!IS_PLAN_FREE ? <CardPaymentMethod /> : ''}
              </Grid>
            </Grid>
          </div>
        </div>

        {!IS_PLAN_FREE ? (
          <div className="White-Box BillingInvoices-Box">
            <div className="invoices">
              <div className="box-heading">
                <div className="heading-start">
                  <div className="title">Invoices</div>
                </div>
                <div className="heading-end"></div>
              </div>

              <div className="invoices-list-table">
                <InvoiceTable />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

const InvoiceTable = () => {
  const [page, setPage] = useState(0);

  const { invoices_list } = useSelector(state => state.company);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <TableContainer>
      <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
        <TableHead>
          <TableRow>
            {DATA_INVOICES_LIST_HEAD.map(headCell => (
              <TableCell key={headCell.id} align={'left'} padding={'none'}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices_list?.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell padding="none">{row?.paid_at ? <Date date={row?.paid_at} /> : '-'}</TableCell>
                <TableCell className="bold" component="th" scope="row" padding="none">
                  <Chip type="stroke" dot color={'ocean'} label={NumberFormatterDollar(row.total)} />
                </TableCell>
                <TableCell padding="none">
                  <Chip type="stroke" dot color={row?.status === 'paid' ? 'success' : 'red'} label={row?.status} />
                </TableCell>

                <TableCell padding="none">
                  <div className="table-cell-action">
                    <Button
                      size="small"
                      variant="outlined"
                      color="white"
                      endIcon={<IconDownload />}
                      onClick={event => {
                        window.open(row?.pdf);
                      }}
                    >
                      Download Invoice
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        {invoices_list?.length === 0 && (
          <TableBody>
            <TableRow>
              <TableCell colSpan={12}>
                <TableEmpty icon={'table-empty-endpoints'} text={'You have no invoice yet '} desc={''} />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={invoices_list?.length || 0}
        rowsPerPage={invoices_list?.length || 0}
        page={page}
        onPageChange={handleChangePage}
        SelectProps={{ className: 'table-select-rows-per-page' }}
      />
    </TableContainer>
  );
};

const CardPaymentPlanDetail = () => {
  const { company_plan_data } = useSelector(state => state.company);

  const total_credit = NumberFormatter(company_plan_data?.info.total_credit);
  const remaining_credit = NumberFormatter(company_plan_data?.info.remaining_credit);

  const price = NumberFormatterDollar(company_plan_data?.info?.total_price || 0);

  const usage = company_plan_data?.info.total_credit
    ? '0'
    : isWhatPercentOf(company_plan_data?.info.remaining_credit, company_plan_data?.info.total_credit);

  const next_payment =
    company_plan_data?.end_date && company_plan_data?.renewable
      ? format(parseISO(company_plan_data?.end_date), 'PP')
      : '-';

  const IS_PLAN_FREE = company_plan_data?.is_free;
  const IS_PLAN_MONTHLY = company_plan_data?.plan_type === 'MONTHLY';

  const difference_months = company_plan_data?.end_date
    ? 12 - differenceInMonths(parseISO(company_plan_data?.end_date), crateNewDate())
    : '';
  const difference_days = company_plan_data?.end_date
    ? 30 - differenceInDays(parseISO(company_plan_data?.end_date), crateNewDate())
    : '';

  return (
    <div className="CardPaymentPlanDetail">
      <div className="Card-Header">
        <div className="Header-Top">
          <div className="icon">
            <IconReceipt />
          </div>
          <div className="title">
            {IS_PLAN_FREE ? 'Trial Plan' : ''}
            {!IS_PLAN_FREE && IS_PLAN_MONTHLY ? 'Monthly Plan' : ''}
            {!IS_PLAN_FREE && !IS_PLAN_MONTHLY ? 'Yearly Plan' : ''}
          </div>
          {/* <div className="payment-status">
            <Chip color={'red'} label={'Payment Failed'} />
          </div> */}
        </div>
        <div className="Header-Bottom">
          <div className="credit-short-detail">
            <span className="price">{price} / </span>
            <span className="credit">{total_credit} Credits </span>
          </div>
        </div>
      </div>
      <div className="Card-Body">
        <div className="payment-detail-list">
          <div className="list-item">
            <div className="text">Usage</div>
            <div className="value">{usage}% of credits used</div>
          </div>
          <div className="list-item">
            <div className="text">Balance</div>
            <div className="value">
              {remaining_credit} / {total_credit} Credits
            </div>
          </div>
          {IS_PLAN_FREE || IS_PLAN_MONTHLY ? (
            ''
          ) : (
            <div className="list-item">
              <div className="text">Next Payment</div>
              <div className="value">{next_payment ? next_payment : ''}</div>
            </div>
          )}

          <div className="list-item">
            <div className="text">End of Package</div>
            {IS_PLAN_MONTHLY ? (
              <div className="value">
                <div className="progress">
                  <LinearProgress color="secondary" variant="determinate" value={(difference_days / 30) * 100} />
                </div>
                {difference_days} / 30 Days
              </div>
            ) : (
              <div className="value">
                <div className="progress">
                  <LinearProgress color="secondary" variant="determinate" value={(difference_months / 12) * 100} />
                </div>
                {difference_months} / 12 Month
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="Card-Footer">
        <Button
          component={Link}
          endIcon={<IconChevronRight />}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          className="icon-right"
          to={ROUTE_PATHS.CHECKOUT}
        >
          Edit plan
        </Button>
      </div>
    </div>
  );
};

const CardPaymentMethod = () => {
  return (
    <div className="CardPaymentMethod">
      <div className="Card-Header">
        <div className="Header-Top">
          <div className="icon">
            <IconCards />
          </div>
          <div className="title">Payment Method</div>
        </div>
      </div>
      <div className="Card-Body">
        <div className="text">Edit payments details, add and delete cards</div>
      </div>
      <div className="Card-Footer">
        <Button
          variant="contained"
          size="large"
          fullWidth
          className="icon-right icon-color-white"
          endIcon={<IconChevronRight />}
          target="_blank"
          href={'https://stripe.com/'}
        >
          Edit payment details
        </Button>
      </div>
    </div>
  );
};
