import { ProtectedLayout } from 'components/layouts/ProtectedLayout';
import React, { useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, Grid, Switch, Tab, Tabs } from '@mui/material';
import TabPanel from 'components/elements/TabPanel';
import { TabLabel } from 'components/elements/TabLabel';
import Buttons from 'components/elements/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyParserConfig, updateCompanyParserConfig } from 'features/parser/parserAction';
import { useSearchParams } from 'react-router-dom';
import { ChangeParserConfigObject } from 'utils';
import { Chip } from 'components/elements/Chip';
import { ChipDemoPro } from 'components/elements/ChipDemoPro';
import { getAllProduct } from 'features/product/productAction';
import {
  createOrReplaceUserPreference,
  getUserPreference,
} from 'features/UserPreferenceParser/UserPreferenceParserAction';

export const ParserSettings = () => {
  let [params] = useSearchParams();

  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(params.get('type'));
  const { parser_config } = useSelector(state => state.parser);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const [valueParserConfig, updateValueParserConfig] = useState(null);

  const handleOnChange = (configType, sectionItem, index) => {
    const new_parser_config = ChangeParserConfigObject(valueParserConfig, configType, sectionItem, index);
    updateValueParserConfig(new_parser_config);
    dispatch(updateCompanyParserConfig({ config: JSON.stringify(new_parser_config) }));
  };

  useEffect(() => {
    dispatch(getCompanyParserConfig());
    dispatch(getAllProduct());
  }, []);

  useEffect(() => {
    if (parser_config) {
      updateValueParserConfig(parser_config);
    }
  }, [parser_config]);

  return (
    <ProtectedLayout>
      <div className="Page Page-ParserSettings">
        <div className="ParserSettings">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="top-action">
                <Buttons.HistoryBack
                  text={'Back to Parser'}
                  to={`/products/parser/${tabValue}`}
                  state={{ from: tabValue }}
                />
              </div>
              <div className="title">{tabValue === 'resume' ? 'Resume' : 'Job'} Parser Settings </div>
              <div className="desc">
                Choose the fields for which you need information. Enable the fields you require and disable those which
                are not required. Enabling more fields will provide better resullts.
              </div>
            </div>
          </div>
          <div className="ParserSettings-Content">
            <div className="ParserSettings-Tabs">
              <Tabs className={'Tabs-Standart Tabs-ParserSettings'} value={tabValue} onChange={handleChangeTab}>
                <Tab label={<TabLabel label={parser_config?.[0].label || `Resume's`} />} value={'resume'} />
                <Tab
                  label={<TabLabel label={parser_config?.[1].label || `Job Descriptions`} chipType="pro" />}
                  value={'job'}
                />
              </Tabs>
            </div>
            <TabPanel value={tabValue} index={'resume'}>
              <ParserSettingsList data={valueParserConfig?.[0]} configType={0} onChange={handleOnChange} />
            </TabPanel>
            <TabPanel value={tabValue} index={'job'}>
              <ParserSettingsList data={valueParserConfig?.[1]} configType={1} onChange={handleOnChange} />
            </TabPanel>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};

const ParserSettingsList = ({ data, configType, onChange }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserPreference({ setting_key: 'ui_parser_preference' }));
    dispatch(getUserPreference({ setting_key: 'api_parser_preference' }));
  }, []);
  return (
    <div className="ParserSettings-List">
      {configType === 0 ? (
        <>
          <div className="list-section-item">
            <SettingParserPreference />
          </div>

          <div className="info-line">
            Switch between <strong>Hirize Alpha</strong> and <strong>Hirize Velocity</strong> at the dashboard and API
            levels. This allows you to test the new model before deploying it at the API level.
          </div>
        </>
      ) : null}
      {data?.children.map((sectionItem, sectionIndex) => {
        return (
          <div key={sectionIndex} className="list-section-item">
            <div className="list-section-title">{sectionItem.label}</div>
            <Grid container rowSpacing={{ xs: '16px' }} columnSpacing={{ xs: '108px' }} className="list-section">
              {sectionItem?.children?.map((item, index) => {
                return (
                  <Grid key={index} item sm={6}>
                    <div className="config-item">
                      <div className="config-name">{item.label}</div>

                      {item.value === 'profile_image' ? (
                        <div className="config-info">
                          <Chip size="small" color="primary" type="tint" label={'Contact'} />
                        </div>
                      ) : null}

                      {item.value === 'hirize_enriched_skills' ? (
                        <div className="config-info">
                          <ChipDemoPro type={'pro'} />
                        </div>
                      ) : null}

                      <div className="config-switch">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                onChange={() => onChange(configType, sectionIndex, index)}
                                checked={item.selected === null ? false : item.selected}
                                disabled={item.selected === null}
                              />
                            }
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

const SettingParserPreference = ({ label }) => {
  const dispatch = useDispatch();
  const { ui_parser_preference, api_parser_preference } = useSelector(state => state.userPreferenceParser);
  const handleChange = (event, setting_key, newValue) => {
    dispatch(createOrReplaceUserPreference({ preference_value: newValue, setting_key })).then(result => {
      if (result.payload.success) {
        dispatch(getUserPreference({ setting_key: 'ui_parser_preference' }));
        dispatch(getUserPreference({ setting_key: 'api_parser_preference' }));
      }
    });
  };

  const products_parser = [
    {
      id: 1,
      name: 'Hirize Alpha',
    },
    {
      id: 25,
      name: 'Hirize Velocity',
    },
  ];
  return (
    <div className="ParserSettings-Preference">
      <div className="list-section-item">
        <div className="list-section-title">Choose Parser Model</div>
        <Grid container rowSpacing={{ xs: '16px' }} columnSpacing={{ xs: '108px' }} className="list-section">
          {products_parser?.map((item, index) => {
            const isAPIParserClassic = item.id === 1;
            let api_checked;
            let api_new_value;
            if (isAPIParserClassic) {
              if (api_parser_preference === 1) {
                api_checked = true;
                api_new_value = 25;
              } else {
                api_checked = false;
                api_new_value = 1;
              }
            } else {
              if (api_parser_preference === 25) {
                api_checked = true;
                api_new_value = 1;
              } else {
                api_checked = false;
                api_new_value = 25;
              }
            }

            let ui_checked;
            let ui_new_value;
            if (isAPIParserClassic) {
              if (ui_parser_preference === 1) {
                ui_checked = true;
                ui_new_value = 25;
              } else {
                ui_checked = false;
                ui_new_value = 1;
              }
            } else {
              if (ui_parser_preference === 25) {
                ui_checked = true;
                ui_new_value = 1;
              } else {
                ui_checked = false;
                ui_new_value = 25;
              }
            }
            return (
              <Grid key={index} item sm={12}>
                <div className="config-item">
                  <div className="config-name">
                    {item.id === 1 ? (
                      <>
                        <strong>Hirize Alpha:</strong> Reliable and consistent parsing for standard tasks
                      </>
                    ) : (
                      <>
                        <strong>Hirize Velocity:</strong> High-speed parsing for more demanding applications
                      </>
                    )}
                  </div>
                  <div className="config-switch config-switch-model">
                    <FormGroup>
                      <FormControlLabel
                        label="API"
                        labelPlacement="start"
                        control={
                          <Switch
                            onChange={e => handleChange(e, 'api_parser_preference', api_new_value)}
                            checked={api_checked}
                          />
                        }
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        labelPlacement="start"
                        label="Dashboard"
                        control={
                          <Switch
                            onChange={e => handleChange(e, 'ui_parser_preference', ui_new_value)}
                            checked={ui_checked}
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};
