import { Button, Grid, Container } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { ReactComponent as IconHirizeH2 } from 'assets/icons/hirize-h-2.svg';
import { FormInput } from 'components/elements/FormInput';
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';
import { ReactComponent as IconNeuraLink } from 'assets/icons/neuralink.svg';
import { ReactComponent as IconUserFocus } from 'assets/icons/user-focus.svg';
import { ReactComponent as IconLight } from 'assets/icons/light.svg';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';
import { useDispatch } from 'react-redux';
import { postEvaluatorForm } from 'features/evaluator/evaluatorAction';
import { useParams } from 'react-router-dom';
import { Loading } from 'components/elements/Loading';
import { FORM_TEXT } from 'data/index';
import { PaternEmail } from 'utils/formPatterns';
import { IconButton } from '../../node_modules/@mui/material/index';
import { ReactComponent as IconRight } from 'assets/icons/chevron-right.svg';

export const EvaluatorForm = () => {
  const dispatch = useDispatch();

  let { evaluatorId } = useParams();

  const FORM_DEFAULT = {
    name: '',
    email: '',
    answer: [{ text: '' }, { text: '' }],
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: FORM_DEFAULT,
  });
  const SendEvaluatorCandidateForm = data => {
    dispatch(
      postEvaluatorForm({
        name: data.name,
        email: data.email,
        answer_list: JSON.stringify(data.answer.map(item => item.value)),
        code: evaluatorId,
      }),
    ).then(result => {
      if (result.payload.success) {
        reset(FORM_DEFAULT);
      }
    });
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'answer',
  });

  const clickAddAnswer = () => {
    if (fields.length >= 5) return;
    append({ text: '' });
  };

  return (
    <div className="Page-EvaluatorForm">
      <div className="Page-Header">
        <div className="logo">
          <img src="/images/logo.svg" alt="" />
        </div>
      </div>
      <div className="Page-Content">
        <Container>
          <Grid container columnSpacing={{ xs: '40px' }}>
            <Grid item xs={12} md={5}>
              <div className="Sidebar">
                <div className="heading">
                  <span className="icon">
                    <IconNeuraLink />
                  </span>
                  <span className="title">Neuralink</span>
                </div>

                <div className="info-list">
                  <div className="info-item">
                    <div className="info-heading">
                      <span className="icon">
                        <IconUserFocus />
                      </span>
                      <span className="title">Get Started</span>
                    </div>
                    <div className="text">
                      <p>
                        Please provide three examples of accomplishments that highlight your exceptional ability (e.g.,
                        products you shipped, projects you delivered on). Provide technical detail and use quantitative
                        metrics to describe impact.
                      </p>
                    </div>
                  </div>
                  <div className="info-item">
                    <div className="info-heading">
                      <span className="icon">
                        <IconLight />
                      </span>
                      <span className="title">Keep in Mind</span>
                    </div>
                    <div className="text">
                      <p>
                        When writing about your accomplishments, remember to be bold and inspiring. The bullet points
                        you provide are crucial in helping us understand who you are as a candidate.
                      </p>
                      <p>
                        Take this opportunity to showcase your unique strengths and capabilities, highlighting the
                        impact you have made in your previous projects or roles. Share specific details and use
                        quantitative metrics whenever possible to provide a clear picture of your achievements.
                      </p>
                      <p>
                        This is your chance to stand out and demonstrate the value you can bring to the table. So don't
                        hold back—let your accomplishments shine and leave a lasting impression.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="footer">
                  <ButtonPowered />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={7}>
              <div className="Evaluator-Content">
                <Box
                  component="form"
                  onSubmit={handleSubmit(data => SendEvaluatorCandidateForm(data))}
                  autoComplete="off"
                >
                  <div className="Form-Candidate-Info">
                    <Grid container rowSpacing={{ xs: '16px' }} columnSpacing={{ xs: '16px' }}>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          label={'Fullname'}
                          errors={errors}
                          name={'name'}
                          control={control}
                          placeholder={'Fullname*'}
                          isRequired={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormInput
                          label={'Email'}
                          errors={errors}
                          name={'email'}
                          control={control}
                          placeholder={'Email*'}
                          inputProps={{ type: 'email' }}
                          isRequired={true}
                          rules={{
                            required: FORM_TEXT.required,
                            pattern: PaternEmail,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="Form-Answers">
                    <Grid container rowSpacing={{ xs: '16px' }} columnSpacing={{ xs: '16px' }}>
                      {fields.map((item, index) => {
                        return (
                          <Grid className="form-item" key={item.id} item xs={12} sm={12}>
                            <FormInput
                              label={`Answer ${index + 1}`}
                              placeholder={`Answer ${index + 1}`}
                              name={`answer.${index}.value`}
                              errors={errors}
                              control={control}
                              isRequired={false}
                              multiline
                              minRows={3}
                            />

                            <div className="action-delete">
                              <IconButton
                                variant="outlined"
                                color="white"
                                size="small"
                                onClick={() => remove(index)}
                                disabled={fields.length <= 1}
                              >
                                <IconTrash />
                              </IconButton>
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <div className="Form-Actions">
                      <Button
                        onClick={clickAddAnswer}
                        variant="contained"
                        color="secondary"
                        size="medium"
                        endIcon={<IconPlus />}
                        disabled={fields.length >= 5}
                      >
                        Add Answer
                      </Button>

                      <Button
                        onClick={handleSubmit(SendEvaluatorCandidateForm)}
                        variant="contained"
                        color="primary"
                        size="medium"
                        endIcon={<IconRight />}
                      >
                        Submit Form
                      </Button>
                    </div>
                  </div>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Loading />
    </div>
  );
};

export const ButtonPowered = () => {
  return (
    <Button href="https://www.hirize.hr/" target="_blank" variant="outlined" color="white" endIcon={<IconHirizeH2 />}>
      Powered by
    </Button>
  );
};
