import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const registerUser = createAsyncThunk(
  'userMember/register',
  async ({ name, surname, email, password }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('api/members', { name, surname, email, password });

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const forgotPassword = createAsyncThunk('userMember/forgotPassword', async (postData, { rejectWithValue }) => {
  try {
    const { data } = await axios.post('api/members/forgot-password', postData);

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const passwordUpdate = createAsyncThunk('userMember/passwordUpdate', async (postData, { rejectWithValue }) => {
  /* const data = {
    email: null,
    reset_token: null,
    password: null
  } */
  try {
    const { data } = await axios.post('api/members/password-update', JSON.stringify(postData));

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const invitedMember = createAsyncThunk('userMember/invitedMember', async (postData, { rejectWithValue }) => {
  const { invite_code } = postData;
  try {
    const { data } = await axios.get(`api/members/invited/${invite_code}`);

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const createdInvitedMemberPassword = createAsyncThunk(
  'userMember/createdInvitedMemberPassword',
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('api/members/invited/password', postData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const verifyEmailAddress = createAsyncThunk(
  'userMember/verifyEmailAddress',
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('api/profile/verify/email', postData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const verifyResendCode = createAsyncThunk(
  'userMember/verifyResendCode',
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('api/profile/verify/resend', postData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
