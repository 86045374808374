import React, { useEffect, useLayoutEffect } from 'react';
import { ReactComponent as IconSuccess } from 'assets/icons/success.svg';
import { ReactComponent as IconError } from 'assets/icons/alert.svg';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { paymentCheckStatus } from 'features/subscription/subscriptionAction';
import { useSearchParams } from 'react-router-dom';
import { changePaymentType } from 'features/subscription/subscriptionSlice';
import { isObjectEmpty } from 'utils';
import { ProtectedLayout } from 'components/layouts/ProtectedLayout';
import { ROUTE_PATHS } from 'data/paths';

export const PaymentResult = () => {
  const [all_params] = useSearchParams();

  const dispatch = useDispatch();
  const { success_subscription, payment_status } = useSelector(state => state.subscription);
  useLayoutEffect(() => {
    const params = Object.fromEntries([...all_params]);
    if (isObjectEmpty(params)) {
      dispatch(changePaymentType('normal'));
    } else {
      dispatch(paymentCheckStatus({ id: params.session_id }));
      dispatch(changePaymentType('3d'));
    }
  }, []);

  useEffect(() => {}, [success_subscription]);
  return (
    <ProtectedLayout>
      <div className="Page-PaymentResult">
        <div className="page-content">
          {success_subscription == null ? null : (
            <div className="payment-result">
              <div className="icon">{success_subscription ? <IconSuccess /> : <IconError />}</div>
              <div className="title">{success_subscription ? 'Payment Received' : 'Payment Failed!'}</div>
              <div className="text">
                {success_subscription
                  ? 'You can find your invoices in the settings page under account.'
                  : payment_status
                  ? payment_status
                  : 'Payment Failed!'}{' '}
              </div>
              <div className="actions">
                <Button component={Link} variant="outlined" color="white" to={ROUTE_PATHS.DASHBOARD}>
                  Back to the Dashboard
                </Button>
                {success_subscription ? (
                  <Button component={Link} variant="contained" to={ROUTE_PATHS.PRODUCTS}>
                    Go to Products
                  </Button>
                ) : (
                  <Button component={Link} variant="contained" to={ROUTE_PATHS.CHECKOUT}>
                    Go to Checkout
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </ProtectedLayout>
  );
};
