import { createSlice } from '@reduxjs/toolkit';
import { createOrReplaceUserPreference, getUserPreference } from './UserPreferenceParserAction';

const initialState = {
  success: null,
  message: null,
  ui_parser_preference: null,
  api_parser_preference: null,
};

export const userPreferenceParserSlice = createSlice({
  name: 'UserPreferenceParser',
  initialState,

  extraReducers: builder => {
    // createOrReplaceUserPreference
    builder.addCase(createOrReplaceUserPreference.pending, state => {
      state.success = null;
    });
    builder.addCase(createOrReplaceUserPreference.fulfilled, (state, { payload }) => {
      state.success = payload.success;
    });
    builder.addCase(createOrReplaceUserPreference.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // getUserPreference
    builder.addCase(getUserPreference.pending, state => {
      state.success = null;
    });
    builder.addCase(getUserPreference.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      if (payload.setting_key === 'ui_parser_preference') {
        state.ui_parser_preference = Number(payload.data.data === false ? 1 : payload.data.data);
      } else {
        state.api_parser_preference = Number(payload.data.data === false ? 1 : payload.data.data);
      }
    });
    builder.addCase(getUserPreference.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });
  },
});

export default userPreferenceParserSlice.reducer;
