import axios from 'axios';
import { toBase64 } from 'utils';
import { getAllParserJobList } from './jobParserAction';
import { updateClickedJob } from './jobParserSlice';
import { changeUploadFileProgressStatus, updateClickedResume, updateUploadFileProgress } from './parserSlice';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const getAllParserList = createAsyncThunk('parser/getAllParserList', async (data, { rejectWithValue }) => {
  const { tag_ids, current_page, keyword = null } = data;

  const tag_list = tag_ids ? tag_ids?.map(i => i.id) : null;

  const postData = {
    tag_ids: JSON.stringify(tag_list),
    current_page,
    keyword,
  };
  try {
    const { data } = await axios.post('api/parser-ui/request/all', postData);

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const makeParserRequest = createAsyncThunk(
  'parser/makeParserRequest',
  async (data, { dispatch, rejectWithValue }) => {
    const { files, tags } = data;

    dispatch(updateUploadFileProgress({ files }));

    const request_list = files.map(async (file, index) => {
      const postData = {
        payload: await toBase64(file),
        file_name: file.name,
        file: null,
        tag_ids: JSON.stringify(tags),
        index,
      };
      return axios.post('api/parser-ui/make-request', postData).then(res => {
        const { index } = JSON.parse(res.config.data);
        const { success } = res.data;

        dispatch(changeUploadFileProgressStatus({ index, status: success ? 'success' : 'error' }));
      });
    });

    try {
      const data = await axios.all(request_list).then(
        axios.spread((...res) => {
          return res.map(item => item.data);
        }),
      );
      return { data };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const createCompanyParserTags = createAsyncThunk(
  'parser/createCompanyParserTags',
  async (data, { dispatch, rejectWithValue }) => {
    const postData = {
      tag: data.tag,
    };
    try {
      const { data } = await axios.post('api/parser-ui/tag', postData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    } finally {
      dispatch(getAllCompanyParserTags());
    }
  },
);

export const getAllCompanyParserTags = createAsyncThunk(
  'parser/getAllCompanyParserTags',
  async (data, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('api/parser-ui/tag/all');

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const removeResume = createAsyncThunk('parser/removeResume', async (data, { dispatch, rejectWithValue }) => {
  const { ids, current_page, parserType } = data;
  try {
    const { data } = await axios.post(`api/parser-ui/delete`, { ids });

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  } finally {
    if (parserType === 'job') {
      dispatch(getAllParserJobList({ tag_ids: null, current_page }));
    }

    if (parserType === 'resume') {
      dispatch(getAllParserList({ tag_ids: null, current_page }));
    }
  }
});

export const getResumeDetail = createAsyncThunk(
  'parser/getResumeDetail',
  async (data, { dispatch, rejectWithValue }) => {
    const { id } = data;
    try {
      const { data } = await axios.get(`api/parser-ui/${id}`);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const removeTagResume = createAsyncThunk(
  'parser/removeTagResume',
  async (data, { dispatch, rejectWithValue }) => {
    const { tag_id, row_id, current_page, parserType } = data;

    const postData = {
      tag_ids: JSON.stringify(tag_id),
      ids: JSON.stringify([row_id]),
    };
    try {
      const { data } = await axios.post(`api/parser-ui/tag/delete`, postData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    } finally {
      if (parserType === 'resume') {
        dispatch(getAllParserList({ tag_ids: null, current_page })).then(result => {
          if (result.payload.success) {
            const row = result.payload.data.list.find(i => i.id === row_id);
            dispatch(updateClickedResume(row));
          }
        });
      }
      if (parserType === 'job') {
        dispatch(getAllParserJobList({ tag_ids: null, current_page })).then(result => {
          if (result.payload.success) {
            const row = result.payload.data.list.find(i => i.id === row_id);
            dispatch(updateClickedJob(row));
          }
        });
      }
    }
  },
);

export const addTagResume = createAsyncThunk('parser/addTagResume', async (data, { dispatch, rejectWithValue }) => {
  const { tag_id, row_id, current_page, parserType } = data;

  const postData = {
    tag_ids: tag_id,
    ids: [row_id],
  };
  try {
    const { data } = await axios.post(`api/parser-ui/tag/add`, postData);

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  } finally {
    dispatch(getAllParserList({ tag_ids: null, current_page })).then(result => {
      if (parserType === 'resume') {
        dispatch(getAllParserList({ tag_ids: null, current_page })).then(result => {
          if (result.payload.success) {
            const row = result.payload.data.list.find(i => i.id === row_id);
            dispatch(updateClickedResume(row));
          }
        });
      }
      if (parserType === 'job') {
        dispatch(getAllParserJobList({ tag_ids: null, current_page })).then(result => {
          if (result.payload.success) {
            const row = result.payload.data.list.find(i => i.id === row_id);
            dispatch(updateClickedJob(row));
          }
        });
      }
    });
  }
});

export const getCompanyParserConfig = createAsyncThunk(
  'parser/getCompanyParserConfig',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`api/parser-ui/config`);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const updateCompanyParserConfig = createAsyncThunk(
  'parser/updateCompanyParserConfig',
  async (postData, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`api/parser-ui/config`, postData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    } finally {
      dispatch(getCompanyParserConfig());
    }
  },
);

export const changeFileName = createAsyncThunk(
  'parser/changeFileName',
  async (postData, { dispatch, rejectWithValue }) => {
    const { id, name, parserType, current_page } = postData;
    try {
      const { data } = await axios.put(`api/parser-ui/${id}/name`, { name });

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    } finally {
      if (parserType === 'job') {
        dispatch(getAllParserJobList({ tag_ids: null, current_page }));
      }

      if (parserType === 'resume') {
        dispatch(getAllParserList({ tag_ids: null, current_page }));
      }
    }
  },
);
