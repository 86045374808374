import { createSlice } from '@reduxjs/toolkit';
import { FORM_TEXT } from 'data';
import { snackActions } from 'libs/snackbar';
import {
  deleteCompanyMember,
  getAllCompanyMember,
  getAuthorityList,
  getPendingInviteList,
  getPermissionTemplate,
  inviteCompanyMember,
  updateCompanyMember,
} from './companyMemberAction';

const initialState = {
  success: null,
  message: null,
  successInvite: null,
  allCompanyMembers: null,
  pendingInviteList: null,
  authorityList: null,
  permissionTemplate: null,
};

export const companyMemberSlice = createSlice({
  name: 'companyMember',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // inviteCompanyMember

    builder.addCase(inviteCompanyMember.pending, state => {
      state.success = null;
    });
    builder.addCase(inviteCompanyMember.fulfilled, (state, { payload }) => {
      state.successInvite = payload.success;
      snackActions.success(FORM_TEXT.form_successful_message);
    });
    builder.addCase(inviteCompanyMember.rejected, (state, { payload }) => {
      state.success = payload.success;
    });

    // updateCompanyMember
    builder.addCase(updateCompanyMember.pending, state => {
      state.success = null;
    });
    builder.addCase(updateCompanyMember.fulfilled, (state, { payload }) => {
      state.success = payload.success;
    });
    builder.addCase(updateCompanyMember.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // deleteCompanyMember
    builder.addCase(deleteCompanyMember.pending, state => {
      state.success = null;
    });
    builder.addCase(deleteCompanyMember.fulfilled, (state, { payload }) => {
      state.success = payload.success;
    });
    builder.addCase(deleteCompanyMember.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // getAllCompanyMember
    builder.addCase(getAllCompanyMember.pending, state => {
      state.success = null;
    });
    builder.addCase(getAllCompanyMember.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.allCompanyMembers = payload.data;
    });
    builder.addCase(getAllCompanyMember.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // getPendingInviteList
    builder.addCase(getPendingInviteList.pending, state => {
      state.success = null;
    });
    builder.addCase(getPendingInviteList.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.pendingInviteList = payload.data;
    });
    builder.addCase(getPendingInviteList.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // getAuthorityList
    builder.addCase(getAuthorityList.pending, state => {
      state.success = null;
    });
    builder.addCase(getAuthorityList.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.authorityList = payload.data;
    });
    builder.addCase(getAuthorityList.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // getPermissionTemplate
    builder.addCase(getPermissionTemplate.pending, state => {
      state.success = null;
    });
    builder.addCase(getPermissionTemplate.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.permissionTemplate = [payload.data];
    });
    builder.addCase(getPermissionTemplate.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });
  },
});

export default companyMemberSlice.reducer;
