import { useState } from 'react';

const useTableCheckbox = (tableList = []) => {
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);

  const handleClickTableCheckBox = (event, id) => {
    const selectedIndex = selectedCheckbox.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCheckbox, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCheckbox.slice(1));
    } else if (selectedIndex === selectedCheckbox.length - 1) {
      newSelected = newSelected.concat(selectedCheckbox.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCheckbox.slice(0, selectedIndex),
        selectedCheckbox.slice(selectedIndex + 1),
      );
    }

    setSelectedCheckbox(newSelected);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = tableList.map(n => n.id);
      setSelectedCheckbox(newSelected);
      return;
    }
    setSelectedCheckbox([]);
  };

  const isSelectedCheckbox = id => selectedCheckbox.indexOf(id) !== -1;

  return [handleClickTableCheckBox, handleSelectAllClick, isSelectedCheckbox, selectedCheckbox, setSelectedCheckbox];
};

export default useTableCheckbox;
