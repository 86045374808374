import { createSlice } from '@reduxjs/toolkit';
import { createEvaluator, postEvaluatorForm, getEvaluatorList, getEvaluatorResult } from './evaluatorAction';
import { snackActions } from 'libs/snackbar';

const initialState = {
  success: null,
  success_evaluator_list: null,
  success_create_evaluator: null,
  evaluator_list: null,
  evaluator_detail: null,
};

export const evaluatorSlice = createSlice({
  name: 'evaluator',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // createEvaluator
    builder.addCase(createEvaluator.pending, state => {
      state.success_create_evaluator = null;
    });
    builder.addCase(createEvaluator.fulfilled, (state, { payload }) => {
      state.success_create_evaluator = payload.success;
    });
    builder.addCase(createEvaluator.rejected, (state, { payload }) => {
      state.success_create_evaluator = payload.success;
    });

    // getEvaluatorList
    builder.addCase(getEvaluatorList.pending, state => {
      state.success_evaluator_list = null;
    });
    builder.addCase(getEvaluatorList.fulfilled, (state, { payload }) => {
      state.evaluator_list = payload.data.sort((a, b) => b.id - a.id);
      state.success_evaluator_list = payload.success;
    });
    builder.addCase(getEvaluatorList.rejected, (state, { payload }) => {
      state.success_evaluator_list = payload;
    });

    // postEvaluatorForm
    builder.addCase(postEvaluatorForm.pending, state => {
      state.success = null;
    });
    builder.addCase(postEvaluatorForm.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      snackActions.success('Form submitted successfully!');
    });
    builder.addCase(postEvaluatorForm.rejected, (state, { payload }) => {
      state.success = payload.success;
    });

    // getEvaluatorResult
    builder.addCase(getEvaluatorResult.pending, state => {
      state.success = null;
    });
    builder.addCase(getEvaluatorResult.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.evaluator_detail = payload.data;
    });
    builder.addCase(getEvaluatorResult.rejected, (state, { payload }) => {
      state.success = payload.success;
    });
  },
});

export default evaluatorSlice.reducer;
