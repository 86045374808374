import React from 'react';
import { ProtectedHeader as Header } from 'components/layouts/ProtectedHeader';
import { ProtectedSidebar as Sidebar } from 'components/layouts/ProtectedSidebar';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccount } from 'features/user/accountAction';
import { getProfile } from 'features/profile/profileAction';
import { Loading } from 'components/elements/Loading';
import { getCompanyPlan } from 'features/company/companyAction';
import { ROUTE_PATHS } from 'data/paths';

export const ProtectedLayout = ({ loading = false, allowedRoles, children, background = 'gray' }) => {
  const { isOpenSidebar, isLoading } = useSelector(state => state.ui);

  return (
    <AuthControl>
      <div
        className={`Layout Layout-Protected background-${background} ${isOpenSidebar ? 'sidebar-opened' : ''}  ${
          isLoading || loading ? 'loading' : ''
        }`}
      >
        <Header background={background} />
        <Sidebar />

        <div className="Layout-Page">{children}</div>
        <Loading loading={loading} />
      </div>
    </AuthControl>
  );
};

export const AuthControl = ({ children }) => {
  const { access_token } = useSelector(state => state.userLogin);
  const { account_data } = useSelector(state => state.account);
  const { profile } = useSelector(state => state.profile);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!account_data) {
      dispatch(getAccount());
    }
    if (!profile) {
      dispatch(getProfile());
    }
    if (!profile) {
      dispatch(getCompanyPlan());
    }
  }, []);

  if (!access_token) {
    return <Navigate to={ROUTE_PATHS.LOGIN} replace />;
  }
  return children;
};
