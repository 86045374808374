import { createSlice } from '@reduxjs/toolkit';
import { snackActions } from 'libs/snackbar';
import {
  registerUser,
  forgotPassword,
  passwordUpdate,
  invitedMember,
  createdInvitedMemberPassword,
  verifyEmailAddress,
  verifyResendCode,
} from './userMemberAction';

const initialState = {
  success: null,
  message: null,
  invited_member_detail: null,
  successPasswordUpdate: null,
  data_register_email_password: null,
  success_verify_email: null,
  is_verify_expired: null,
  loading: false,
  dataForgotPassword: {
    email: null,
    code: null,
    new_password: null,
  },
};

export const userMemberSlice = createSlice({
  name: 'userRegister',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetStatus: state => {
      state.success = null;
      state.message = null;
    },
    updateDataForgotPassword: (state, { payload }) => {
      const { field, value } = payload;
      state.dataForgotPassword[field] = value;
    },
    updateInvitedMemberDetailPassword: (state, { payload }) => {
      state.invited_member_detail.new_password = payload.password;
    },
    updateRegisterEmailPassword: (state, { payload }) => {
      state.data_register_email_password = payload;
    },
  },
  extraReducers: builder => {
    // register user
    builder.addCase(registerUser.pending, state => {
      state.success = null;
    });
    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      snackActions.success('Signup Successful!');
    });
    builder.addCase(registerUser.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // forgot password
    builder.addCase(forgotPassword.pending, state => {
      state.success = null;
    });
    builder.addCase(forgotPassword.fulfilled, (state, { payload }) => {
      state.success = payload.success;
    });
    builder.addCase(forgotPassword.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // password update
    builder.addCase(passwordUpdate.pending, state => {
      state.successPasswordUpdate = null;
    });
    builder.addCase(passwordUpdate.fulfilled, (state, { payload }) => {
      state.successPasswordUpdate = payload.success;
      snackActions.success('Password has been successfully updated!');
    });
    builder.addCase(passwordUpdate.rejected, (state, { payload }) => {
      state.successPasswordUpdate = false;
      state.message = payload;
    });

    // password update
    builder.addCase(invitedMember.pending, state => {
      state.success = null;
    });
    builder.addCase(invitedMember.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.invited_member_detail = payload.data[0];
    });
    builder.addCase(invitedMember.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // password update
    builder.addCase(createdInvitedMemberPassword.pending, state => {
      state.success = null;
    });
    builder.addCase(createdInvitedMemberPassword.fulfilled, (state, { payload }) => {
      state.success = payload.success;
    });
    builder.addCase(createdInvitedMemberPassword.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // verifyEmailAddress
    builder.addCase(verifyEmailAddress.pending, state => {
      state.success_verify_email_address = null;
      state.loading = true;
    });
    builder.addCase(verifyEmailAddress.fulfilled, (state, { payload }) => {
      state.success_verify_email_address = payload.success;
      state.loading = false;
    });
    builder.addCase(verifyEmailAddress.rejected, (state, { payload }) => {
      state.success_verify_email_address = payload.success;
      state.message = payload.message;
      state.is_verify_expired = payload.code === 400;
      state.loading = false;
    });

    // verifyResendCode
    builder.addCase(verifyResendCode.pending, state => {
      state.success_verify_email_address = null;
    });
    builder.addCase(verifyResendCode.fulfilled, (state, { payload }) => {
      state.success_verify_email_address = payload.success;
      if (payload.success) {
        snackActions.success('We sent verification e-mail again!');
      }
    });
    builder.addCase(verifyResendCode.rejected, (state, { payload }) => {
      state.success_verify_email_address = payload.success;
      state.message = payload.message;
    });
  },
});

export const {
  resetState,
  resetStatus,
  updateDataForgotPassword,
  changeForgotPasswordActivePage,
  updateInvitedMemberDetailPassword,
  updateRegisterEmailPassword,
} = userMemberSlice.actions;

export default userMemberSlice.reducer;
