import { Button, Dialog, TextField } from '@mui/material';
import React from 'react';
import IconParser from 'assets/icons/products/job-parser.png';

export const AddJobParser = props => {
  const { open, onClose, onClickConfirm, valueJobDescription, setvalueJobDescription } = props;

  const handleChangeJobDescription = event => {
    const { value } = event.target;
    setvalueJobDescription(value.trim());
  };

  const onClick = () => {
    onClickConfirm(valueJobDescription);
    onClose();
  };

  return (
    <Dialog className="Modal-Container Modal-AddJobParser" maxWidth={'sm'} onClose={onClose} open={open}>
      <div className="Modal-Box">
        <div className="Modal-Header">
          <div className="icon icon-img">
            <img src={IconParser} alt="" />
          </div>
          <div className="title">Add Job Description</div>
          <div className="desc"></div>
        </div>
        <div className="Modal-Content">
          <div className="job-description-form">
            <div className="job-description">
              <TextField
                placeholder="Paste job description here"
                multiline
                rows={6}
                fullWidth
                onChange={handleChangeJobDescription}
                variant="outlined"
                value={valueJobDescription}
              />
            </div>
          </div>
        </div>
        <div className="Modal-Footer">
          <Button onClick={onClose} variant="outlined" color="white" fullWidth size="large">
            Cancel
          </Button>
          <Button variant="contained" size="large" fullWidth onClick={onClick}>
            Add Job
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
