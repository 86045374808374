import axios from 'axios';
import { toBase64 } from 'utils';
import { changeUploadFileProgressStatus, updateUploadFileProgress } from './matcherSlice';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const getAllSeniorityList = createAsyncThunk(
  'matcher/getAllSeniorityList',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`api/matcher-ui/seniority/all`);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const createMatcher = createAsyncThunk(
  'matcher/createMatcher',
  async (postData, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`api/matcher-ui/`, postData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const getAllMatcherList = createAsyncThunk(
  'matcher/getAllMatcherList',
  async (data, { dispatch, rejectWithValue }) => {
    const { page, order, orderBy, matcher_type } = data;
    const postData = {
      current_page: page,
      order: {
        [orderBy.toUpperCase()]: order.toUpperCase(),
      },
      matcher_type,
    };
    try {
      const { data } = await axios.post(`api/matcher-ui/all`, postData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const getMatcherDetail = createAsyncThunk(
  'matcher/getMatcherDetail',
  async (data, { dispatch, rejectWithValue }) => {
    const { id } = data;
    try {
      const { data } = await axios.get(`api/matcher-ui/${id}`);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const searchMatcherRequestList = createAsyncThunk(
  'matcher/searchMatcherRequestList',
  async (data, { dispatch, rejectWithValue }) => {
    const { id, current_page, priority, skills, order, orderBy, scope } = data;
    const postData = {
      priority: priority,
      skills: skills,
      current_page: current_page,
      id: id,
      scope: scope,
      order: {
        [orderBy.toUpperCase()]: order.toUpperCase(),
      },
    };
    try {
      const { data } = await axios.post(`api/matcher-ui/request/all`, postData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const getResumeDetail = createAsyncThunk(
  'matcher/getResumeDetail',
  async (data, { dispatch, rejectWithValue }) => {
    const { id, is_medimind, resume_id, scope } = data;
    try {
      const { data } = await axios.get(
        `api/parser-ui/${id}${is_medimind ? `?log_id=${resume_id}&scope=${scope}` : ''}`,
      );
      return { result: data, id };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const getMatcherMedicalScope = createAsyncThunk(
  'matcher/getMatcherMedicalScope',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`api/matcher-ui/medical-scope/all`);

      return { result: data };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const addResumeMatcher = createAsyncThunk(
  'matcher/addResumeMatcher',
  async (data, { rejectWithValue, dispatch }) => {
    const { files, matcher_id } = data;
    dispatch(updateUploadFileProgress({ files }));
    const request_list = files.map(async (file, index) => {
      return axios
        .post(`api/matcher-ui/make-request`, {
          matcher_id: matcher_id,
          payload: await toBase64(file),
          file_name: file.name,
          file: null,
          index,
        })
        .then(res => {
          const { index } = JSON.parse(res.config.data);
          const { success } = res.data;

          dispatch(changeUploadFileProgressStatus({ index, status: success ? 'success' : 'error' }));
        });
    });

    try {
      const data = await axios.all(request_list).then(
        axios.spread((...res) => {
          return res.map(item => item.data);
        }),
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const deleteMatcher = createAsyncThunk('matcher/deleteMatcher', async (data, { dispatch, rejectWithValue }) => {
  const { id } = data;
  try {
    const { data } = await axios.delete(`api/matcher-ui/${id}`);

    return { result: data };
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const deleteMatcherResume = createAsyncThunk(
  'matcher/deleteMatcherResume',
  async (data, { dispatch, rejectWithValue }) => {
    const { id } = data;
    try {
      const { data } = await axios.delete(`api/matcher-ui/request/${id}`);

      return { result: data };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
