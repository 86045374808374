import { createSlice } from '@reduxjs/toolkit';
import { snackActions } from 'libs/snackbar';
import {
  activeCompanyEndpointKey,
  addCompanyEndpoint,
  createCompanyEndpointKey,
  deactiveCompanyEndpointKey,
  deleteCompanyEndpointKey,
  getCompanyEndpointDetail,
  getCompanyEndpoints,
  updateCompanyEndpointDetail,
} from './endpointAction';

const initialState = {
  success: null,
  message: null,
  company_endpoints: null,
  company_endpoint_detail: null,
};

export const parserSlice = createSlice({
  name: 'endpoint',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: builder => {
    // addCompanyEndpoint
    builder.addCase(addCompanyEndpoint.pending, state => {
      state.success = null;
    });
    builder.addCase(addCompanyEndpoint.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      snackActions.success('Endpoint created successfully!');
    });
    builder.addCase(addCompanyEndpoint.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // getCompanyEndpoints
    builder.addCase(getCompanyEndpoints.pending, state => {
      state.success = null;
    });
    builder.addCase(getCompanyEndpoints.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.company_endpoints = payload.data;
    });
    builder.addCase(getCompanyEndpoints.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // getCompanyEndpointDetail
    builder.addCase(getCompanyEndpointDetail.pending, state => {
      state.success = null;
    });
    builder.addCase(getCompanyEndpointDetail.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.company_endpoint_detail = payload.data;
    });
    builder.addCase(getCompanyEndpointDetail.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // updateCompanyEndpointDetail
    builder.addCase(updateCompanyEndpointDetail.pending, state => {
      state.success = null;
    });
    builder.addCase(updateCompanyEndpointDetail.fulfilled, (state, { payload }) => {
      state.success = payload.success;
    });
    builder.addCase(updateCompanyEndpointDetail.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // createCompanyEndpointKey
    builder.addCase(createCompanyEndpointKey.pending, state => {
      state.success = null;
    });
    builder.addCase(createCompanyEndpointKey.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      snackActions.success('Endpoint created successfully!');
    });
    builder.addCase(createCompanyEndpointKey.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // deleteCompanyEndpointKey
    builder.addCase(deleteCompanyEndpointKey.pending, state => {
      state.success = null;
    });
    builder.addCase(deleteCompanyEndpointKey.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      snackActions.success('Endpoint deleted successfully!');
    });
    builder.addCase(deleteCompanyEndpointKey.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // activeCompanyEndpointKey
    builder.addCase(activeCompanyEndpointKey.pending, state => {
      state.success = null;
    });
    builder.addCase(activeCompanyEndpointKey.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      snackActions.success('Endpoint activeted successfully!');
    });
    builder.addCase(activeCompanyEndpointKey.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // deactiveCompanyEndpointKey
    builder.addCase(deactiveCompanyEndpointKey.pending, state => {
      state.success = null;
    });
    builder.addCase(deactiveCompanyEndpointKey.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      snackActions.success('Endpoint deactiveted successfully!');
    });
    builder.addCase(deactiveCompanyEndpointKey.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });
  },
});

export const { resetState } = parserSlice.actions;

export default parserSlice.reducer;
