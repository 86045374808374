import React, { useState } from 'react';

import { ReactComponent as IconArrowRight2 } from 'assets/icons/arrow-right-2.svg';
import { ReactComponent as IconCheck2 } from 'assets/icons/check-2.svg';
import { ReactComponent as IconStripe } from 'assets/icons/stripe.svg';

import { useLocation } from 'react-router-dom';
import { Button, Grid, Tab, Tabs } from '@mui/material';
import { useEffect } from 'react';
import { PricingSummaryProduct } from 'components/cards/PricingSummaryProduct';
import { DATA_STEPPER_LIST } from 'data/checkout';
import { useDispatch, useSelector } from 'react-redux';
import { NumberFormatter, NumberFormatterDollar } from 'utils';
import { createSubscription } from 'features/subscription/subscriptionAction';
import { ProtectedLayout } from 'components/layouts/ProtectedLayout';
import { BoxInfo } from 'components/boxes/BoxInfo';
import {
  getAllProduct,
  getAllProductFunctions,
  getProductCalculateRequestCountOptions,
} from 'features/product/productAction';
import { getCartPlan } from 'features/purchase/purchaseAction';
import { BuildPlan } from './build-plan';

export const Checkout = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [billingPlan, updateBillingPlan] = useState('monthly');

  const { cart_data, success_get_products } = useSelector(state => state.purchase);

  const isBasketEmpty = !cart_data || cart_data?.products?.length === 0;
  const page_name = pathname.split('/')[2];
  const isMonthly = billingPlan === 'monthly';

  const changeBillingPlan = (event, value) => {
    updateBillingPlan(value);
  };

  const clickBuyNow = () => {
    dispatch(
      createSubscription({
        plan_id: cart_data.id,
        subscription_type: billingPlan,
      }),
    ).then(res => {
      const result = res.payload[0];

      if (!!result.payment_url) {
        window.location.replace(result.payment_url);
      }
    });
  };

  useEffect(() => {
    dispatch(getAllProduct());
    dispatch(getAllProductFunctions());
    dispatch(getProductCalculateRequestCountOptions());
  }, []);

  useEffect(() => {
    if (success_get_products) {
      dispatch(getCartPlan());
    }
  }, [success_get_products]);

  return (
    <ProtectedLayout background="white">
      <div className="Page Page-Checkout">
        <div className="Checkout">
          <div className="Checkout-Header">
            {/* <div className="checkout-steps">
              <StepperCheckout />
            </div> */}
          </div>

          <div className="Checkout-Content">
            <Grid container columnSpacing={'24px'}>
              <Grid item sm={page_name === 'payment-result' ? 12 : 8}>
                <div className="Checkout-Outlet">
                  <BuildPlan />
                </div>
              </Grid>

              {page_name !== 'payment-result' && (
                <Grid item md={4}>
                  <div className="Checkout-PricingTable">
                    <div className="switch-pricing-plan">
                      <Tabs
                        className="Tabs-PricingPlan"
                        onChange={(event, value) => changeBillingPlan(event, value)}
                        value={billingPlan}
                        aria-label="Pricing Plans"
                      >
                        <Tab disableRipple value="monthly" label="Monthly plan" />
                        <Tab disableRipple value="annual" label="Annual plan" />
                      </Tabs>
                      <div className="annual-discount">
                        <img src="/images/annually-discount.svg" alt="" />
                      </div>
                    </div>
                    <div className="pricing-table">
                      <div className="pricing-total">
                        <div className="total-label">Total</div>
                        <div className="total-info">
                          <span className="price-value">
                            <div className="price">
                              {isMonthly
                                ? NumberFormatterDollar(cart_data?.info?.total_price || 0)
                                : NumberFormatterDollar(cart_data?.info.annual_price || 0)}
                            </div>
                            <div className="billing-plan">{isMonthly ? '/mo' : '/yr'}</div>
                          </span>
                          {!isBasketEmpty ? (
                            <div className="credit-info">
                              {NumberFormatter(
                                isMonthly ? cart_data?.info?.total_credit : cart_data?.info?.total_credit,
                              )}{' '}
                              Credits
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {!isBasketEmpty ? (
                        <div className="pricing-list">
                          {cart_data?.products?.map((item, index) => {
                            if (item.name === 'Parser') return null;
                            return (
                              <div key={index} className="pricing-item">
                                <PricingSummaryProduct data={item} type="normal" />
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                      <div className="checkout-info">
                        {isBasketEmpty ? (
                          <BoxInfo
                            title={'Basket is empty'}
                            text={`Create a bundle to get started.`}
                            theme={'blue'}
                            size={'small'}
                          />
                        ) : (
                          <BoxInfo
                            title={'Discount'}
                            text={`Credit limits were fixed and a general discount was applied.`}
                            theme={'blue'}
                            size={'small'}
                          />
                        )}
                      </div>
                      <div className="checkout-action">
                        <Button
                          disabled={isBasketEmpty}
                          variant="contained"
                          fullWidth
                          color="primary"
                          onClick={clickBuyNow}
                        >
                          Buy Now
                        </Button>
                      </div>
                    </div>
                    <div className="stripe-area">
                      <span className="text">Guaranteed safe & secure checkout</span>
                      <span className="stripe-icon">
                        <IconStripe />
                      </span>
                    </div>
                    <div className="checkout-message">
                      <BoxInfo
                        title={'Credits & Usage'}
                        text={`We make usage estimation easy! We calculate your credit requirements. You can use our credit for
                        any product.`}
                        theme={'gray'}
                        size={'medium'}
                      />
                    </div>
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};

// eslint-disable-next-line no-unused-vars
const StepperCheckout = () => {
  const { pathname } = useLocation();

  const [currentStep, changeCurrentStep] = useState(pathname.split('/')[2]);

  const currentStepIndex = !!currentStep
    ? DATA_STEPPER_LIST.findIndex(i => i.id === currentStep) + 1
    : DATA_STEPPER_LIST[0]['number'];
  useEffect(() => {
    changeCurrentStep(pathname.split('/')[2]);
  }, [pathname]);
  return (
    <div className="Stepper-Checkout">
      <div className="stepper-list">
        {DATA_STEPPER_LIST.map((item, index) => {
          if (item.display === 'hide') return null;
          return (
            <div
              key={item.id}
              className={`stepper-item ${
                currentStepIndex === item.number ? 'active' : currentStepIndex > item.number ? 'done' : 'next'
              }`}
            >
              <div className="stepper-number">{currentStepIndex > item.number ? <IconCheck2 /> : item.number}</div>
              <div className="stepper-label">{item.label}</div>
              {index < 2 && (
                <div className="stepper-arrow">
                  <IconArrowRight2 />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
