import { useState } from 'react';

const useOrderTable = (initOrder, initOrderBy) => {
  const [order, setOrder] = useState(initOrder);
  const [orderBy, setOrderBy] = useState(initOrderBy);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  return [order, orderBy, createSortHandler];
};

export default useOrderTable;
