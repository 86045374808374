import React, { useEffect } from 'react';

import { Box, Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import { FormInput } from 'components/elements/FormInput';
import { useDispatch, useSelector } from 'react-redux';
import { getCompany, updateCompany } from 'features/company/companyAction';

export const CompanyAccount = () => {
  const dispatch = useDispatch();
  const { company } = useSelector(state => state.company);
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({});

  const SendCompanyAccountForm = data => {
    const { company_name, domain, address, description } = data;
    dispatch(
      updateCompany({
        company_name,
        company_website: domain,
        description,
        address,
      }),
    );
  };

  useEffect(() => {
    dispatch(getCompany());
  }, []);

  useEffect(() => {
    if (company) {
      const { name, web_site, address, description } = company;
      reset({
        company_name: name || '',
        domain: web_site || '',
        address: address || '',
        description: description || '',
      });
    }
  }, [company]);

  return (
    <div className="Page-CompanyAccount">
      <div className="CompanyAccount">
        <div className="White-Box CompanyAccount-Form">
          <Box component="form" onSubmit={handleSubmit(data => SendCompanyAccountForm(data))} autoComplete="off">
            <Grid container rowSpacing={'32px'} columnSpacing={'24px'}>
              <Grid item xs={12}>
                <div className="box-heading">
                  <div className="heading-start">
                    <div className="title">Company Account</div>
                    <div className="desc">Manage your organisation.</div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <FormInput
                  label={'Company Name'}
                  errors={errors}
                  name={'company_name'}
                  control={control}
                  placeholder={'Company Name*'}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  label={'Domain'}
                  errors={errors}
                  name={'domain'}
                  control={control}
                  placeholder={'Domain'}
                  isRequired={false}
                />
              </Grid>

              <Grid item xs={12}>
                <FormInput
                  label={'Address'}
                  errors={errors}
                  name={'address'}
                  control={control}
                  placeholder={'Address'}
                  isRequired={false}
                />
              </Grid>

              <Grid item xs={12}>
                <FormInput
                  label={'Description'}
                  errors={errors}
                  name={'description'}
                  control={control}
                  placeholder={'Description'}
                  isRequired={false}
                  multiline={true}
                  rows={3}
                />
              </Grid>

              <Grid className="form-actions" item xs={12} sm={12}>
                <Button onClick={handleSubmit(data => SendCompanyAccountForm(data))} variant="contained">
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
};
