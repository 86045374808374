import { Dashboard } from 'pages/dashboard';

import { PasswordLayout } from 'pages/password/layout-password';
import { ForgotPasswordEmailForm } from 'pages/password/forgot-password/email-form';
import { ForgotPasswordCodeVerification } from 'pages/password/forgot-password/code-verification';
import { ForgotPasswordResetPassword } from 'pages/password/forgot-password/reset-password';
import { CreatePasswordNewPassword } from 'pages/password/create-password/new-password';
import { CreatePasswordResult } from 'pages/password/create-password/result';

import { Login } from 'pages/auth/login';
import { Register } from 'pages/auth/register';

import { SourceSetup } from 'pages/source-setup';
import { EndPoints } from 'pages/endpoint/endpoint-list';
import { EndPointForm } from 'pages/endpoint/endpoint-form';
import { ProfileEdit } from 'pages/settings/profile-edit';
import { Settings as LayoutSettings } from 'pages/settings/layout-settings';
import { CompanyAccount } from 'pages/settings/company-account';
import { AccessManagement } from 'pages/settings/access-management';
import { BillingInvoices } from 'pages/settings/billing-invoices';

import { Products } from 'pages/products/index';

import { Parser } from 'pages/products/parser/layout-parser';
import { ParserResume } from 'pages/products/parser/parser-resume';
import { ParserResumeDetail } from 'pages/products/parser/parser-resume-detail';
import { ParserJob } from 'pages/products/parser/parser-job';
import { ParserJobDetail } from 'pages/products/parser/parser-job-detail';
import { ParserSettings } from 'pages/products/parser/parser-settings';

import { MatcherList } from 'pages/products/matcher/matcher-list';
import { MatcherDetail } from 'pages/products/matcher/matcher-detail';

import { HirizeIQ } from 'pages/products/hirize-iq';

import { Checkout } from 'pages/checkout';

import { PaymentResult } from 'pages/checkout/payment-result';

import { Analytics } from 'pages/analytics';
import NotFound404 from 'pages/404';
import { RolesRoute } from 'components/layouts/RolesRoute';
import { EvaluatorList } from 'pages/evaluator';
import { EvaluatorForm } from 'pages/evaluator-form';
import { ChatCV } from 'pages/products/chat-cv';
import { ConnectedApps } from 'pages/connected-apps';
import { ROUTE_PATHS } from 'data/paths';
import { Verification } from 'pages/verification';
import { HirizeFinList } from 'pages/products/hirize-fin/invoice-list';
import { HirizeFinDetail } from 'pages/products/hirize-fin/invoice-detail';
const ROLES = {
  MEMBER: 'ROLE_MEMBER',
  ADMIN: 'ROLE_ADMIN',
  MEMBER_ADD: 'ROLE_MEMBER_ADD',
  MEMBER_EDIT: 'ROLE_MEMBER_EDIT',
  BILLING: 'ROLE_BILLING',
  DEVELOPER: 'ROLE_DEVELOPER',
};

export const routes = [
  {
    path: ROUTE_PATHS.HOME,
    element: <Dashboard />,
  },
  {
    path: ROUTE_PATHS.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: ROUTE_PATHS.PRODUCTS,
    children: [
      {
        index: true,
        path: ROUTE_PATHS.PRODUCTS,
        element: <Products />,
      },
      {
        element: <Parser />,
        children: [
          {
            path: ROUTE_PATHS.PRODUCTS_PARSER_RESUME,
            element: <ParserResume />,
            index: true,
          },
          {
            path: ROUTE_PATHS.PRODUCTS_PARSER_JOB,
            element: <ParserJob />,
          },
        ],
      },
      {
        path: ROUTE_PATHS.PRODUCTS_PARSER_RESUME_DETAIL + ':resumeId',
        element: <ParserResumeDetail />,
      },
      {
        path: ROUTE_PATHS.PRODUCTS_PARSER_JOB_DETAIL + ':jobId',
        element: <ParserJobDetail />,
      },
      {
        path: ROUTE_PATHS.PRODUCTS_PARSER_SETTINGS,
        element: <ParserSettings />,
      },
      {
        path: ROUTE_PATHS.PRODUCTS_MATCHER_LIST,
        element: <MatcherList />,
      },
      {
        path: ROUTE_PATHS.PRODUCTS_MATCHER_DETAIL + ':matcherId',
        element: <MatcherDetail />,
      },
      {
        path: ROUTE_PATHS.PRODUCTS_MEDIMIND_LIST,
        element: <MatcherList />,
      },
      {
        path: ROUTE_PATHS.PRODUCTS_MEDIMIND_DETAIL + ':matcherId',
        element: <MatcherDetail />,
      },
      {
        path: ROUTE_PATHS.PRODUCTS_HIRIZE_IQ,
        element: <HirizeIQ />,
      },
      {
        path: ROUTE_PATHS.PRODUCTS_EVALUATOR,
        element: <EvaluatorList />,
      },
      {
        path: ROUTE_PATHS.PRODUCTS_CHATCV,
        element: <ChatCV />,
      },
      {
        path: ROUTE_PATHS.PRODUCTS_HIRIZE_FIN_LIST,
        element: <HirizeFinList />,
      },
      {
        path: ROUTE_PATHS.PRODUCTS_HIRIZE_FIN_DETAIL + ':id',
        element: <HirizeFinDetail />,
      },
    ],
  },

  {
    path: ROUTE_PATHS.ENDPOINTS,
    children: [
      {
        path: ROUTE_PATHS.ENDPOINTS_LIST,
        element: (
          <RolesRoute allowedRoles={[ROLES.ADMIN, ROLES.DEVELOPER]}>
            <EndPoints />
          </RolesRoute>
        ),
        index: true,
      },
      {
        path: ROUTE_PATHS.ENDPOINTS_NEW,
        element: (
          <RolesRoute allowedRoles={[ROLES.ADMIN, ROLES.DEVELOPER]}>
            <EndPointForm />
          </RolesRoute>
        ),
      },
      {
        path: ROUTE_PATHS.ENDPOINTS_EDIT + ':id',
        element: (
          <RolesRoute allowedRoles={[ROLES.ADMIN, ROLES.DEVELOPER]}>
            <EndPointForm />
          </RolesRoute>
        ),
      },
    ],
  },
  {
    path: ROUTE_PATHS.CONNECTED_APPS,
    element: <ConnectedApps />,
  },

  {
    path: ROUTE_PATHS.SOURCE_SETUP + ':sourceId',
    element: <SourceSetup />,
  },
  {
    path: ROUTE_PATHS.ANALYTICS,
    element: <Analytics />,
  },
  {
    element: <LayoutSettings />,
    children: [
      {
        path: ROUTE_PATHS.SETTINGS_COMPANY_ACCOUNT,
        element: <CompanyAccount />,
        index: true,
      },
      {
        path: ROUTE_PATHS.SETTINGS_ACCESS_MANAGEMENT,
        element: <AccessManagement />,
      },
      {
        path: ROUTE_PATHS.SETTINGS_BILLING_INVOICES,
        element: <BillingInvoices />,
      },
    ],
  },
  {
    path: ROUTE_PATHS.PROFILE,
    element: <ProfileEdit />,
  },
  {
    path: ROUTE_PATHS.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTE_PATHS.REGISTER,
    element: <Register />,
  },
  {
    element: <PasswordLayout />,
    children: [
      {
        path: ROUTE_PATHS.FORGOT_PASSWORD_EMAIL,
        element: <ForgotPasswordEmailForm />,
      },
      {
        path: ROUTE_PATHS.FORGOT_PASSWORD_CODE_VERIFICATION,
        element: <ForgotPasswordCodeVerification />,
      },
      {
        path: ROUTE_PATHS.FORGOT_PASSWORD_RESET_PASSWORD,
        element: <ForgotPasswordResetPassword />,
      },
    ],
  },
  {
    element: <PasswordLayout />,
    children: [
      {
        path: ROUTE_PATHS.CREATE_PASSWORD_NEW_PASSWORD,
        element: <CreatePasswordNewPassword />,
      },
      {
        path: ROUTE_PATHS.CREATE_PASSWORD_RESULT,
        element: <CreatePasswordResult />,
      },
    ],
  },
  {
    path: ROUTE_PATHS.CHECKOUT,
    element: <Checkout />,
    index: true,
  },
  {
    path: ROUTE_PATHS.CHECKOUT_PAYMENT_RESULT,
    element: <PaymentResult />,
  },
  {
    path: ROUTE_PATHS.EVALUATOR_FORM + ':evaluatorId',
    element: <EvaluatorForm />,
  },
  {
    path: '/verification',
    element: <Verification />,
  },
  /* {
    path: '/ui-kit',
    element: <UIKIT />,
  }, */
  {
    path: '*',
    element: <NotFound404 />,
  },
];
