import { ROUTE_PATHS } from 'data/paths';
import { URL_CHAT_CV } from 'constant';

export const DATA_PRODUCTS = {
  1: {
    pathname: ROUTE_PATHS.PRODUCTS_PARSER_RESUME,
    isRedirect: false,
  },
  2: {
    pathname: ROUTE_PATHS.PRODUCTS_PARSER_JOB,
    isRedirect: false,
  },
  3: {
    pathname: ROUTE_PATHS.PRODUCTS_MATCHER_LIST,
    isRedirect: false,
  },
  4: {
    pathname: '',
    isRedirect: false,
  },
  5: {
    pathname: '',
    isRedirect: false,
  },
  6: {
    pathname: '',
    isRedirect: false,
  },
  7: {
    pathname: '',
    isRedirect: false,
  },
  8: {
    pathname: ROUTE_PATHS.PRODUCTS_HIRIZE_IQ,
    isRedirect: false,
  },
  21: {
    pathname: ROUTE_PATHS.PRODUCTS_MEDIMIND_LIST,
    isRedirect: false,
  },
  23: {
    pathname: URL_CHAT_CV,
    isRedirect: true,
  },
  24: {
    pathname: ROUTE_PATHS.PRODUCTS_HIRIZE_FIN_LIST,
    isRedirect: false,
  },
  25: {
    pathname: ROUTE_PATHS.PRODUCTS_PARSER_RESUME,
    isRedirect: false,
  },
};
