import { ProtectedLayout } from 'components/layouts/ProtectedLayout';
import React, { useRef, useState } from 'react';

import { Box, Button, Grid, IconButton, InputLabel } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { PaternEmail, validateBusinessEmail } from 'utils/formPatterns';
import { FORM_TEXT } from 'data';
import { FormInput } from 'components/elements/FormInput';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getProfile, updateProfile, updateProfilePicture } from 'features/profile/profileAction';
import { ReactComponent as IconCamera } from 'assets/icons/camera.svg';
import { checkPasswordStrength } from 'utils';
import { ProfileAvatar } from 'components/layouts/ProtectedSidebar';
import { ReactComponent as IconEye } from 'assets/icons/eye.svg';
import { ReactComponent as IconEyeSlash } from 'assets/icons/eye-slash.svg';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { PASSWORD_LEVEL } from 'constant';

export const ProfileEdit = () => {
  const dispatch = useDispatch();
  const [phoneNumberInfo, setPhoneNumberInfo] = useState(null);
  const refInputProfilePicture = useRef(null);
  const { profile } = useSelector(state => state.profile);
  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    control,
  } = useForm({});
  const watchNewPassword = watch('new_password', null);

  const SendProfileEditForm = async data => {
    dispatch(
      updateProfile({
        ...data,
        phone: {
          code: Number(phoneNumberInfo.countryCallingCode),
          number: Number(phoneNumberInfo.nationalNumber),
        },
      }),
    ).then(res => {
      if (res.payload.success) {
        dispatch(getProfile());
      }
    });
    reset({
      new_password: '',
      current_password: '',
      confirm_password: '',
    });
  };

  const changeProfilePicture = event => {
    const { files } = event.target;

    if (files && files.length) {
      dispatch(updateProfilePicture(files));
    }
  };

  const handleChangePhone = (value, info) => {
    setValue('phone', value);
    setPhoneNumberInfo(info);
    matchIsValidTel(value);
  };

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    if (profile) {
      const { name, surname, email, mobile_phone } = profile;

      reset({
        first_name: name,
        last_name: surname,
        email: email,
        phone: mobile_phone ? `+ ${mobile_phone.code} ${mobile_phone.number}` : '',
      });
      setPhoneNumberInfo({
        countryCallingCode: mobile_phone?.code,
        nationalNumber: mobile_phone?.number,
      });
    }
  }, [profile]);

  return (
    <ProtectedLayout>
      <div className="Page Page-ProfileEdit">
        <div className="ProfileEdit">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="title">Edit Profile</div>
              <div className="desc">Create your account for a more specialized experience.</div>
            </div>
          </div>
          <div className="White-Box ProfileEdit-Form">
            <Box
              component="form"
              onSubmit={handleSubmit((event, data) => SendProfileEditForm(data))}
              autoComplete="off"
            >
              <Grid container rowSpacing={'32px'} columnSpacing={'24px'}>
                <Grid item sm={12}>
                  <div className="box-heading">
                    <div className="heading-start">
                      <div className="title">Profile Details</div>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12}>
                  <InputLabel className="Label-TextField">Profile picture</InputLabel>
                  <div className="profile-picture">
                    <div className="picture-avatar" onClick={() => refInputProfilePicture.current.click()}>
                      <ProfileAvatar sx={{ width: 80, height: 80 }} />
                      <div className="avatar-hover">
                        <div className="icon">
                          <IconCamera />
                          <input
                            style={{ display: 'none' }}
                            accept=".jpg, .jpeg, .png"
                            ref={refInputProfilePicture}
                            onChange={changeProfilePicture}
                            type="file"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <FormInput
                    label={'First Name'}
                    errors={errors}
                    name={'first_name'}
                    control={control}
                    placeholder={'First Name*'}
                    isRequired={true}
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormInput
                    label={'Last Name'}
                    errors={errors}
                    name={'last_name'}
                    control={control}
                    placeholder={'Last Name*'}
                    isRequired={true}
                  />
                </Grid>

                <Grid item sm={6}>
                  <FormInput
                    label={'Email'}
                    errors={errors}
                    name={'email'}
                    control={control}
                    placeholder={'Email*'}
                    isRequired={true}
                    rules={{ required: FORM_TEXT.required, pattern: PaternEmail, validate: validateBusinessEmail }}
                  />
                </Grid>

                <Grid item sm={6}>
                  <InputLabel className="Label-TextField">
                    Phone Number <i className="required">*</i>
                  </InputLabel>
                  <Controller
                    control={control}
                    name="phone"
                    rules={{
                      validate: matchIsValidTel,
                    }}
                    render={({ field, fieldState }) => (
                      <MuiTelInput
                        {...field}
                        focusOnSelectCountry
                        defaultCountry="TR"
                        helperText={fieldState.invalid ? 'Phone number is invalid' : ''}
                        error={fieldState.invalid}
                        onChange={handleChangePhone}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12}>
                  <div className="box-heading heading-change-password">
                    <div className="heading-start">
                      <div className="title">Change Password</div>
                    </div>
                  </div>
                </Grid>

                <PasswordGrid label="Current Password" name="current_password" errors={errors} control={control} />

                <Grid item sm={6}></Grid>
                <PasswordGrid
                  watchNewPassword={watchNewPassword}
                  showLevel
                  label="New Password"
                  name="new_password"
                  errors={errors}
                  control={control}
                />
                <PasswordGrid
                  rules={{
                    validate: val => {
                      if (watchNewPassword !== val) {
                        return 'Your passwords do no match';
                      }
                    },
                  }}
                  label="Confirm Password"
                  name="confirm_password"
                  errors={errors}
                  control={control}
                />

                <Grid className="form-actions" item xs={12} sm={12}>
                  <Button variant="contained" onClick={handleSubmit(SendProfileEditForm)}>
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};

const PasswordGrid = ({ label, name, errors, control, showLevel, watchNewPassword, rules }) => {
  const [strenghtLevel, setStrenghtLevel] = useState(null);
  useEffect(() => {
    const level = checkPasswordStrength(watchNewPassword);

    setStrenghtLevel(level);
  }, [watchNewPassword]);
  const [passwordType, changePasswordType] = useState('password');
  const handleClickShowPassword = () => {
    changePasswordType(prev => {
      if (prev === 'password') {
        return 'text';
      } else if (prev === 'text') {
        return 'password';
      }
    });
  };

  return (
    <Grid item sm={6}>
      <div className="form-input-password-group">
        <FormInput
          rules={rules}
          label={label}
          errors={errors}
          name={name}
          control={control}
          placeholder={label}
          isRequired={false}
          inputType={passwordType}
        />
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          size="small"
          className="button-passwordType"
        >
          {passwordType === 'password' ? <IconEye /> : <IconEyeSlash />}
        </IconButton>
      </div>
      {showLevel ? (
        <div className="Password-Strenght">
          <div className="label">Password Strenght:</div>
          <div className={`strenght-levels level-${strenghtLevel ? strenghtLevel : 'empty'}`}>
            <span className="level"></span>
            <span className="level"></span>
            <span className="level"></span>
            {strenghtLevel ? <div className="level-name">{PASSWORD_LEVEL[strenghtLevel]}</div> : ''}
          </div>
        </div>
      ) : (
        ''
      )}
    </Grid>
  );
};
