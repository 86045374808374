import React from 'react';

export const TooltipStandartBox = ({ title, content }) => {
  return (
    <div className="Tooltip-Standart-Box">
      <div className="title">{title}</div>
      <div className="content">{content}</div>
    </div>
  );
};
