import { ProtectedLayout } from 'components/layouts/ProtectedLayout';
import { JsonViewer } from '@textea/json-viewer';
import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router-dom';
import Buttons from 'components/elements/buttons';
import TabPanel from 'components/elements/TabPanel';
import { ReactComponent as IconDownload } from 'assets/icons/download.svg';
import { ReactComponent as IconDoc } from 'assets/icons/doc.svg';

import { useDispatch, useSelector } from 'react-redux';
import { getResumeDetail } from 'features/parser/parserAction';
import { ParserDetailList } from 'components/ParserDetailList';
import { GeneratePdf } from 'utils/pdfExport';
import { ROUTE_PATHS } from 'data/paths';
import { Packer } from 'docx';
import { saveAs } from 'file-saver';
import { DOC_CA_MINING } from './docx_templates/doc_ca_mining';
import { DOC_HIRIZE } from './docx_templates/doc_hirize';

export const ParserResumeDetail = () => {
  const dispatch = useDispatch();
  let { resumeId } = useParams();
  const { parser_detail_data } = useSelector(state => state.parser);
  const { account_data } = useSelector(state => state.account);
  const [tabValue, setTabValue] = useState('overview');

  const handleChangeTab = (event, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    dispatch(getResumeDetail({ id: resumeId }));
  }, [account_data]);

  const refContent = useRef();

  const DownloadResumeDoc = async () => {
    let doc;
    if (account_data?.company?.id === 241) {
      doc = await DOC_CA_MINING(parser_detail_data);
    } else {
      doc = await DOC_HIRIZE(parser_detail_data);
    }
    Packer.toBlob(await doc).then(blob => {
      saveAs(blob, `${resumeId}.docx`);
    });
  };
  return (
    <ProtectedLayout>
      <div className="Page Page-ParserDetail">
        <div className="ParserDetail">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="top-action">
                <Buttons.HistoryBack text={'Back to Parser'} to={ROUTE_PATHS.PRODUCTS_PARSER_RESUME} />
              </div>
            </div>
          </div>
          <div className="ParserDetail-Content">
            <div className="ParserDetail-Tabs">
              <Tabs className={'Tabs-Standart Tabs-ParserDetail'} value={tabValue} onChange={handleChangeTab}>
                <Tab label="Overview" value={'overview'} />
                <Tab label="JSON" value={'json'} />
              </Tabs>
              <div className="action">
                {tabValue === 'overview' ? (
                  <>
                    <IconButton color="white" onClick={() => GeneratePdf(refContent, resumeId)}>
                      <IconDownload />
                    </IconButton>
                    <IconButton color="white" onClick={DownloadResumeDoc}>
                      <IconDoc />
                    </IconButton>
                  </>
                ) : null}
              </div>
            </div>
            <div className="ParserDetail-Page">
              <TabPanel value={tabValue} index={'overview'}>
                {parser_detail_data && <ParserDetailList myRef={refContent} data={parser_detail_data} />}
              </TabPanel>
              <TabPanel value={tabValue} index={'json'}>
                <div className="json-viewer">{parser_detail_data && <JsonViewer value={parser_detail_data} />}</div>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};
