import { Checkbox, MenuItem, Select } from '@mui/material';
import { Chip } from 'components/elements/Chip';
import { ReactComponent as IconArrowDown } from 'assets/icons/arrow-down.svg';

import React from 'react';

import { NumberFormatter } from 'utils';

export const ProductCheckout = ({
  data,
  type,
  changeSelectProduct,
  isSelectedProduct,
  options,
  optitons_profits,
  changeNumberDocuments,
}) => {
  const { icon, name, description, document_count, total_credit, isSelected } = data;

  const profits = optitons_profits?.filter(option_item => {
    return option_item.product_id === data.id;
  });

  return (
    <div className={`Card-ProductCheckout ${isSelected ? 'is-selected' : ''}`}>
      <div className="Card-Header">
        <div className="product-icon">
          <img src={icon} alt={name} />
        </div>
        <div className="product-toggle">
          {isSelected ? (
            <Checkbox onChange={event => changeSelectProduct(event.target.checked, data, type)} checked={isSelected} />
          ) : null}
          {/*  {isSelected && !is_feature ? (
            <IconButton color="white" onClick={() => changeSelectProduct(!isSelected, data, type)} size="large">
              <IconChecked />
            </IconButton>
          ) : null} */}
        </div>
      </div>

      <div className="Card-Content">
        <div className="product-title">{name}</div>
        <div className="product-desc">{description}</div>
        <div className="product-document-select">
          <div className="select">
            <Select
              className="Select-Standart "
              IconComponent={IconArrowDown}
              value={document_count}
              defaultValue={0}
              /* disabled={!isSelected} */
              onChange={event => changeNumberDocuments(event, data, type)}
              MenuProps={{
                disableScrollLock: true,
                className: 'Select-Standart-Menu',
              }}
            >
              <MenuItem value={0}>Choose Number of Documents</MenuItem>
              {options?.map(item => {
                const value_profit = profits?.filter(i => item?.id >= i.document_limit) || [];
                const last_value_profit = Number(value_profit?.[value_profit?.length - 1]?.profit) * 100 || 0;
                return (
                  <MenuItem className="MenuItem-Document-Checkout" key={item.id} value={item.id}>
                    <span className="label">{item.label}</span>
                    {optitons_profits && last_value_profit !== 0 ? (
                      <div className="option-chip">
                        <Chip
                          color="primary"
                          type="tint"
                          size="xsmall"
                          label={`%${Math.floor(last_value_profit || 0)} off`}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </div>
      </div>
      <div className={`Card-Footer`}>
        <div className="credit-label">Credits:</div>
        <div className="credit-value">{NumberFormatter(total_credit) || 0} credits</div>
        {/* {false ? (
          <div className="feature-action">
            <Button
              onClick={() => changeSelectProduct(!isSelected, data, type)}
              size={'small'}
              endIcon={isSelected ? <IconClose /> : <IconPlus />}
              variant="contained"
              color="primary"
            >
              {isSelected ? 'Remove from cart' : 'Add to cart'}
            </Button>
          </div>
        ) : (
          <>
            <div className="credit-label">Credits:</div>
            <div className="credit-value">{NumberFormatter(total_credit) || 0} credits</div>
          </>
        )} */}
      </div>
    </div>
  );
};
