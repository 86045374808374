import { Box, Button, Divider, FormControl, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { PublicLayout } from 'components/layouts/PublicLayout';
import { Controller, useForm } from 'react-hook-form';
import { FORM_TEXT } from 'data';

import { ReactComponent as IconGoogle } from 'assets/icons/socialmedia/google.svg';
import { ReactComponent as IconOffice } from 'assets/icons/socialmedia/office.svg';
import { ReactComponent as IconLinkedin } from 'assets/icons/socialmedia/linkedin.svg';
import { ReactComponent as IconMail } from 'assets/icons/socialmedia/mail.svg';

import { Link } from 'react-router-dom';
import { PaternEmail, validateBusinessEmail } from 'utils/formPatterns';
import Buttons from 'components/elements/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from 'features/userMember/userMemberAction';
import { InputFilledPassword } from 'components/elements/InputFilledPassword';
import { userLogin } from 'features/user/userLoginAction';
import { ROUTE_PATHS } from 'data/paths';

export const Register = () => {
  const [registerType, changeRegisterType] = useState('email');
  const handleOnClickBack = () => {
    if (registerType === 'email') {
      window.open('https://hirize.hr/', '_blank');
    } else {
      changeRegisterType('social');
    }
  };
  return (
    <PublicLayout>
      <div className="Page Page-Register">
        <div className="Register">
          <div className="Register-Header">
            <Buttons.AuthBack
              text={registerType === 'email' ? 'Back to Hirize' : 'Back to Sign Up'}
              onClick={handleOnClickBack}
            />
          </div>
          {registerType === 'social' && <RegisterWithSocial changeRegisterType={changeRegisterType} />}

          {registerType === 'email' && <RegisterForm />}
          <div className="Register-Footer">
            <div className="text-terms">
              By signing up for Hirize you agree to the <a href="https://hirize.hr/privacy-notice">Privacy Statement</a>{' '}
              and <a href="https://hirize.hr/terms-of-use">Terms of Service</a>
            </div>
            <div className="redirect-login">
              Already have an account? <Link to={ROUTE_PATHS.LOGIN}>Login</Link>
            </div>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

const RegisterForm = () => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      password: '',
      email: '',
      name: '',
      surname: '',
    },
  });
  const { isLoading } = useSelector(state => state.ui);

  const SendRegisterForm = data => {
    dispatch(registerUser(data)).then(result => {
      if (result.payload.success) {
        dispatch(
          userLogin({
            email: data.email,
            password: data.password,
          }),
        );
      }
    });
  };
  return (
    <div className="Register-Form">
      <div className="Heading">
        <div className="title">Sign Up With E-Mail</div>
        <div className="desc">You may change your information later.</div>
      </div>
      <Box component="form" onSubmit={handleSubmit(data => SendRegisterForm(data))} autoComplete="off">
        <Grid container rowSpacing={{ xs: '20px', sm: '24px' }} columnSpacing={{ xs: '0', sm: '16px' }}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size={'small'}>
              <Controller
                render={({ field }) => (
                  <TextField
                    error={!!errors?.['name']?.['message']}
                    helperText={errors?.['name']?.['message']}
                    label="First Name*"
                    variant="filled"
                    value={''}
                    {...field}
                  />
                )}
                name="name"
                control={control}
                rules={{ required: FORM_TEXT.required }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size={'small'}>
              <Controller
                render={({ field }) => (
                  <TextField
                    error={!!errors?.['surname']?.['message']}
                    helperText={errors?.['surname']?.['message']}
                    label="Last Name*"
                    variant="filled"
                    {...field}
                  />
                )}
                name="surname"
                control={control}
                rules={{ required: FORM_TEXT.required }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl fullWidth size={'small'}>
              <Controller
                render={({ field }) => (
                  <TextField
                    error={!!errors?.['email']?.['message']}
                    helperText={errors?.['email']?.['message']}
                    label="Work e-mail*"
                    variant="filled"
                    {...field}
                  />
                )}
                name="email"
                control={control}
                rules={{ required: FORM_TEXT.required, pattern: PaternEmail, validate: validateBusinessEmail }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputFilledPassword control={control} errors={errors} name={'password'} />
          </Grid>

          <Grid className="action" item xs={12}>
            <Button
              onClick={handleSubmit(SendRegisterForm)}
              fullWidth
              className="button-type-auth"
              variant="contained"
              size="large"
              disabled={isLoading}
            >
              Sign Up Hirize
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

const RegisterWithSocial = ({ changeRegisterType }) => {
  return (
    <div className="Register-Form-Social">
      <div className="Heading">
        <div className="title">Sign Up</div>
        <div className="desc">For a more fluid experience, you can use social logins</div>
      </div>
      <div className="Social-Login">
        <Button fullWidth className="button-social-login" startIcon={<IconGoogle />}>
          Continue with Google
        </Button>
        <Button fullWidth className="button-social-login" startIcon={<IconOffice />}>
          Continue with Office
        </Button>
        <Button fullWidth className="button-social-login" startIcon={<IconLinkedin />}>
          Continue with Linkedin
        </Button>
      </div>
      <Divider className="divider">OR</Divider>
      <Button
        onClick={() => changeRegisterType('email')}
        fullWidth
        className="button-type-auth"
        variant="contained"
        size="large"
        startIcon={<IconMail />}
      >
        Continue with E-mail
      </Button>
    </div>
  );
};
