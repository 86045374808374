import { ROUTE_PATHS } from 'data/paths';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

export const PublicLayout = ({ children }) => {
  const { access_token } = useSelector(state => state.userLogin);

  return (
    <>
      {access_token ? (
        <Navigate to={ROUTE_PATHS.HOME} />
      ) : (
        <div className="Layout Layout-Public">
          <div className="Layout-Start">{children}</div>
          <div className="Layout-End">
            <div className="Layout-End-Header">
              <div className="logo">
                <Link to={ROUTE_PATHS.HOME}>
                  <img src="/images/logo.svg" alt="" />
                </Link>
              </div>
              <div className="text-content">
                <div className="text">
                  With Hirize, we experienced an impressive increase in <br /> qualified applicants - a massive 30%
                  jump! <br />
                  Streamlining our recruiting process has never been <br /> more successful.
                </div>
                <div className="author">
                  <div className="author-name">Jer Langhans</div>
                  <div className="author-title">CEO, Paired Sourcing</div>
                </div>
              </div>
              <div className="cover-image">
                <img src="/images/dashboard.png" alt="Hirize Dashboard" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
