import { createSlice } from '@reduxjs/toolkit';
import { getAccount } from './accountAction';

const initialState = {
  success: null,
  message: null,
  account_data: null,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    resetAccountState: () => initialState,
  },
  extraReducers: builder => {
    // login user
    builder.addCase(getAccount.pending, state => {
      state.success = null;
    });
    builder.addCase(getAccount.fulfilled, (state, { payload }) => {
      state.success = true;
      state.account_data = payload;
    });
    builder.addCase(getAccount.rejected, (state, { payload }) => {
      state.success = payload;
    });
  },
});
export const { resetAccountState } = accountSlice.actions;

export default accountSlice.reducer;
