import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as IconRemove } from 'assets/icons/remove.svg';

export const InputTag = ({ viewType = 'standart', value, setValue, label, placeholder }) => {
  const [inputValue, setInputValue] = useState('');
  let inputRef = useRef();
  useEffect(() => {
    inputRef.focus();
  }, []);
  return (
    <div className={`InputTag-Container view-type-${viewType}`}>
      <Autocomplete
        multiple
        freeSolo
        options={[]}
        value={value}
        className={`InputTag`}
        inputValue={inputValue}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        ChipProps={{
          size: 'small',
          className: 'Color-primary Type-solid',
          deleteIcon: <IconRemove />,
        }}
        onInputChange={(event, newInputValue) => {
          const options = newInputValue.split(',');

          if (options.length > 1) {
            setInputValue('');

            setValue(
              value
                .concat(options)
                .map(x => x.trim())
                .filter(x => x),
            );
          } else {
            setInputValue(newInputValue);
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={placeholder}
            inputRef={input => {
              inputRef = input;
            }}
          />
        )}
      />
    </div>
  );
};
