import { ReactComponent as IconUploadPc } from 'assets/icons/apps/upload-pc.svg';
import { ReactComponent as IconZapier } from 'assets/icons/apps/zapier.svg';
import { ReactComponent as IconMake } from 'assets/icons/apps/make.svg';
import { ReactComponent as IconBubble } from 'assets/icons/apps/bubble.svg';
import { ReactComponent as IconSheets } from 'assets/icons/apps/sheets.svg';
import { ReactComponent as IconForms } from 'assets/icons/apps/forms.svg';

import { ReactComponent as IconGreenHouse } from 'assets/icons/apps/green-house.svg';
import { ReactComponent as IconEdenAI } from 'assets/icons/apps/edenai.svg';

export const APPS_ICONS = {
  1: {
    icon: <IconGreenHouse />,
    learn_link: 'https://developers.greenhouse.io/',
  },
  2: {
    icon: <IconEdenAI />,
    learn_link: 'https://docs.edenai.co/docs',
  },
  3: {
    icon: <IconZapier />,
    learn_link: 'https://platform.zapier.com/quickstart/how-zapier-works',
  },
  4: {
    icon: <IconZapier />,
    learn_link: 'https://docs.edenai.co/docs',
  },
};

export const DATA_APP_LIST = [
  {
    id: 'upload-pc',
    title: 'Upload from PC',
    desc: 'Upload profiles directly from you computer.',
    icon: <IconUploadPc />,
  },
  {
    id: 'zapier',
    title: 'Zapier',
    desc: 'Extract applications from +1500 relevant tools and web apps.',
    linkText: 'zapier.com/',
    linkUrl: 'https://zapier.com/',
    icon: <IconZapier />,
  },
  {
    id: 'make',
    title: 'Make',
    desc: 'From tasks and workflows to apps and systems, build and automate anything in one powerful visual platform.',
    linkText: 'make.com/',
    linkUrl: 'https://www.make.com/en',
    icon: <IconMake />,
  },
  {
    id: 'bubble',
    title: 'Bubble',
    desc: 'Develop interactive prototypes in minutes without writing any code.',
    linkText: 'bubble.io/',
    linkUrl: 'https://bubble.io/',
    icon: <IconBubble />,
  },
  {
    id: 'google-sheets',
    title: 'Google Sheets',
    desc: 'Use Google sheets to import/export data from or to spreadsheets',
    linkText: 'google.com/sheets/about/',
    linkUrl: 'https://google.com/sheets/about/',
    icon: <IconSheets />,
  },
  {
    id: 'google-forms',
    title: 'google-forms',
    desc: 'Use Google forms to create job applications, extract data from questionaires and resumes.',
    linkText: 'google.com/forms/about/',
    linkUrl: 'https://google.com/forms/about/',
    icon: <IconForms />,
  },
];
