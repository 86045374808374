import { ProtectedLayout } from 'components/layouts/ProtectedLayout';
import { DATA_APP_LIST } from 'data/connect';
import React from 'react';
import { Autocomplete, Button, InputLabel, TextField } from '@mui/material';
import Buttons from 'components/elements/buttons';
import { ROUTE_PATHS } from 'data/paths';

const DATA_ENDPOINT_LIST = [
  { label: 'http_api_1', value: 'http_api_1' },
  { label: 'http_api_2', value: 'http_api_2' },
  { label: 'http_api_3', value: 'http_api_3' },
];

export const SourceSetup = () => {
  const DATA_SOURCE = DATA_APP_LIST.find(i => i.id === 'zapier');

  return (
    <ProtectedLayout>
      <div className="Page Page-SourceSetup">
        <div className="SourceSetup">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="top-action">
                <Buttons.HistoryBack text={'Back to Connect'} to={ROUTE_PATHS.CONNECTED_APPS} />
              </div>
              <div className="title">Source Setup</div>
              <div className="desc">Extract applications from +1500 relevant tools and web apps.</div>
            </div>
          </div>
          <div className="SourceSetup-Form">
            <div className="form-header">
              <div className="form-header-start">
                <div className="source-info">
                  <div className="source-icon">{DATA_SOURCE.icon}</div>
                  <div className="source-detail">
                    <div className="source-name">{DATA_SOURCE.title}</div>
                    <div className="source-link">
                      {!!DATA_SOURCE.linkText ? (
                        <Buttons.ExternalLink text={DATA_SOURCE.linkText} path={DATA_SOURCE.linkText} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-header-end">
                <div className="authorize-action">
                  <Button variant="contained" size="large" color="info">
                    Authorize
                  </Button>
                </div>
                <div className="authorize-message">Please authorize us so that we can connect you with Hirize.</div>
              </div>
            </div>
            <div className="form-body">
              <InputLabel className="Label-Autocomplete">
                Choose Product End-Point<i className="required">*</i>
              </InputLabel>
              <Autocomplete
                disablePortal
                sx={{ width: '100%' }}
                options={DATA_ENDPOINT_LIST}
                className="AutoComplete-Standart"
                renderInput={params => (
                  <TextField
                    helperText="Identifies this source within your workspace"
                    placeholder="Choose Product End-Point"
                    {...params}
                  />
                )}
              />
            </div>
            <div className="form-footer">
              <Button sx={{ minWidth: '170px' }} variant="contained" color="primary">
                Create Source
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};
