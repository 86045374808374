/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Button, IconButton, Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DATA_SOCIAL_MEDIA } from 'data';
import { useSelector } from 'react-redux';
import { ROUTE_PATHS } from 'data/paths';

export default function NotFound404() {
  const navigate = useNavigate();
  const { access_token } = useSelector(state => state.userLogin);

  return (
    <div className="Page-NotFound404">
      <section className="NotFound404">
        <Container>
          <Grid>
            <Grid item>
              <div className="NotFound404-Content">
                <div className="heading">
                  <h1>404!</h1>
                </div>
                <div className="desc">
                  <p>
                    Oops... <br />
                    The page you're looking for does not exist. <br /> No worries you can always go back to the
                    {access_token ? ' dashboard' : ' login'}, just hit the button.
                  </p>
                </div>
                <div className="actions">
                  {access_token ? (
                    <Button onClick={() => navigate(ROUTE_PATHS.DASHBOARD)} size="large" variant="contained">
                      Go to Dashboard
                    </Button>
                  ) : (
                    <Button onClick={() => navigate(ROUTE_PATHS.LOGIN)} size="large" variant="contained">
                      Go to Login
                    </Button>
                  )}
                </div>
                <SocialMedia404 />
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </div>
  );
}

export const SocialMedia404 = () => {
  return (
    <div className="social-media">
      <div className="social-media-title">Stay in Contact</div>
      <nav className="social-media-list">
        {DATA_SOCIAL_MEDIA.map(item => {
          return (
            <IconButton
              size="small"
              variant="outlined"
              color="white"
              target="_blank"
              rel="noreferrer"
              key={item.id}
              href={item.link}
            >
              {item.icon}
            </IconButton>
          );
        })}
      </nav>
    </div>
  );
};
