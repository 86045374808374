export const DATA_EVALUATOR_HEAD = [
  {
    id: 'candidate_name',
    label: 'CANDIDATE NAME',
  },
  {
    id: 'email',
    label: 'EMAIL',
  },
  {
    id: 'submit_date',
    label: 'SUBMIT DATE',
  },
  /* {
    id: 'score',
    label: 'SCORE',
  }, */
  {
    id: 'action',
    label: 'ACTION',
  },
];
