import axios from 'axios';
import { toggleLoading } from 'features/ui/uiSlice';
import { snackActions } from 'libs/snackbar';

let store;

export const injectStore = _store => {
  store = _store;
};

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common['Client_Id'] = process.env.REACT_APP_CLIENT_ID;
axios.defaults.headers.common['Client_Secret'] = process.env.REACT_APP_CLIENT_SECRET;

axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(
  config => {
    store.dispatch(toggleLoading(true));
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      config.headers['Authorization'] = 'Bearer ' + access_token;
    }
    return config;
  },
  request => {
    // Edit request config

    return request;
  },
  error => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  response => {
    // Edit response config
    store.dispatch(toggleLoading(false));

    return response;
  },
  error => {
    store.dispatch(toggleLoading(false));
    const originalRequest = error.config;
    const refresh_token = localStorage.getItem('refresh_token');

    if (error.response && error.response.data.message) {
      if (error.response.config.url === 'api/purchase/temp-plan') return;
      snackActions.error(error.response.data.message);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios
        .post('oauth/v2/token', {
          refresh_token: refresh_token,
          grant_type: 'refresh_token',
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
        })
        .then(res => {
          if (res.status === 200) {
            // 1) put token to LocalStorage
            const { data } = res;
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('expires_in', data.expires_in);

            // 2) Change Authorization header
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');

            // 3) return originalRequest object with Axios.
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  },
);

export default axios;
