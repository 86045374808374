import React from 'react';
import { ReactComponent as IconSortDecrease } from 'assets/icons/sort-decrease.svg';
import { ReactComponent as IconSortPassive } from 'assets/icons/sort-passive.svg';
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { TableSortLabel } from '@mui/material';

export const TableHeadSortLabel = ({ orderBy, headCell, order, onClick }) => {
  return (
    <TableSortLabel
      active
      direction={orderBy === headCell.id ? order : 'asc'}
      onClick={onClick}
      IconComponent={orderBy === headCell.id ? IconSortDecrease : IconSortPassive}
    >
      {headCell.label}
      {orderBy === headCell.id ? (
        <Box component="span" sx={visuallyHidden}>
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </Box>
      ) : null}
    </TableSortLabel>
  );
};
