import React from 'react';
import { ReactComponent as IconEmpty } from 'assets/icons/file.svg';

export const TableEmpty = ({ icon = null, text = '', desc = '', children }) => {
  const icon_src = icon ? `/images/${icon}.svg` : null;
  return (
    <div className="TableEmpty">
      <span className="icon">{icon ? <img src={icon_src} alt="" /> : <IconEmpty />}</span>
      <span className="text">{text}</span>
      <span className="desc">{desc}</span>
      <div className="TableEmpty-Content">{children}</div>
    </div>
  );
};
