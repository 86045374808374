import { Button, Popover } from '@mui/material';
import { format, subYears } from 'date-fns';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { crateNewDate } from 'utils';
import { allStaticRanges } from 'utils/dates';
import { ReactComponent as IconArrowDown } from 'assets/icons/arrow-down.svg';

const newDate = crateNewDate();

export const RangeDatepicker = ({ value, onChange, updateSelectedLabel }) => {
  const onChangePicker = item => {
    onChange([item.selection]);
    updateSelectedLabel(`${format(item.selection.startDate, 'PP')} - ${format(item.selection.endDate, 'PP')}`);
    allStaticRanges.forEach(rangeItem => {
      if (rangeItem.isSelected(item.selection)) {
        updateSelectedLabel(rangeItem.label);
      }
    });
  };
  return (
    <div>
      <DateRangePicker
        onChange={item => onChangePicker(item)}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        ranges={value}
        direction="horizontal"
        preventSnapRefocus={true}
        calendarFocus="backwards"
        maxDate={newDate}
        minDate={subYears(newDate, 2)}
        staticRanges={allStaticRanges}
      />
    </div>
  );
};

export const PopoverRangeDatepicker = ({ value, onChange, selectedLabel, updateSelectedLabel }) => {
  const [anchorElDateRangePicker, setAnchorElDateRangePicker] = useState(null);

  return (
    <div className="action">
      <Button
        onClick={event => setAnchorElDateRangePicker(event.currentTarget)}
        className={'button-dateRangePicker'}
        endIcon={<IconArrowDown />}
      >
        {selectedLabel}
      </Button>
      <Popover
        onClose={() => setAnchorElDateRangePicker(null)}
        open={Boolean(anchorElDateRangePicker)}
        anchorEl={anchorElDateRangePicker}
        className={'Popover-CompanyTagList'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <RangeDatepicker value={value} onChange={onChange} updateSelectedLabel={updateSelectedLabel} />
      </Popover>
    </div>
  );
};
