import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

// JOB PARSER

export const jobParserMakeRequest = createAsyncThunk(
  'jobParser/jobParserMakeRequest',
  async (data, { dispatch, rejectWithValue }) => {
    const { job_description, tag_ids } = data;
    const postData = {
      job_description,
      tag_ids: JSON.stringify(tag_ids),
    };
    try {
      const { data } = await axios.post(`api/parser-ui/job-parser/make-request`, postData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const getAllParserJobList = createAsyncThunk(
  'jobParser/getAllParserJobList',
  async (data, { dispatch, rejectWithValue }) => {
    const { tag_ids, current_page, keyword = null } = data;

    const tag_list = tag_ids ? tag_ids?.map(i => i.id) : null;

    const postData = {
      tag_ids: JSON.stringify(tag_list),
      current_page,
      keyword,
    };
    try {
      const { data } = await axios.post(`api/parser-ui/request/job/all`, postData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const getJobParserDetail = createAsyncThunk(
  'jobParser/getJobParserDetail',
  async (data, { dispatch, rejectWithValue }) => {
    const { id } = data;
    try {
      const { data } = await axios.get(`api/parser-ui/job-parser/${id}`);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
