import { ROUTE_PATHS } from 'data/paths';
import React from 'react';
import { Link, Outlet } from 'react-router-dom';

export const PasswordLayout = () => {
  const this_year = new Date().getFullYear();
  return (
    <div className="Layout Layout-Password">
      <div className="LayoutPassword">
        <div className="LayoutPassword-Header">
          <div className="logo">
            <Link to={ROUTE_PATHS.HOME}>
              <img src="/images/logo-white.svg" alt="" />
            </Link>
          </div>
        </div>
        <div className="LayoutPassword-Content">
          <div className="Content-Card">
            <Outlet />
          </div>
        </div>
        <div className="LayoutPassword-Footer">
          <div className="copyright">
            © {this_year || 2023} All Rights Reserved. Hirize Inc. Cookie Preferences, Privacy, and Terms.
          </div>
        </div>
      </div>
    </div>
  );
};
