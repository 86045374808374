import { ProtectedLayout } from 'components/layouts/ProtectedLayout';

import React from 'react';
import { ConnectApp as CardConnectApp } from 'components/cards/ConnectApp';
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectedApps } from 'features/connected-apps/connectedAppsAction';

export const ConnectedApps = () => {
  const { connected_apps, success_connect_apps } = useSelector(state => state.connected_apps);

  const dispatch = useDispatch();

  const handlerGetConnectedApps = () => {
    dispatch(getConnectedApps());
  };

  useEffect(() => {
    handlerGetConnectedApps();
  }, []);

  useEffect(() => {
    if (success_connect_apps !== null) {
      handlerGetConnectedApps();
    }
  }, [success_connect_apps]);

  return (
    <ProtectedLayout>
      <div className="Page Page-ConnectedApps">
        <div className="ConnectedApps">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="title">Connected Apps</div>
              <div className="desc">Supercharge your workflow + connect tools you use.</div>
            </div>
          </div>
          <div className="ConnectedApps-App-List">
            <Grid className="app-list" container columnSpacing={'12px'} rowSpacing={'24px'}>
              {connected_apps
                ?.filter(item => item.company !== 'Zapier Export')
                .map(item => {
                  if (false) {
                    return null;
                  } else {
                    return (
                      <Grid key={item.id} item xs={12} sm={6} md={6} lg={6} xl={4}>
                        <CardConnectApp data={item} />
                      </Grid>
                    );
                  }
                })}
            </Grid>
          </div>

          <div className="Zapier-Integration-Templates">
            <div className="Heading">
              <div className="title">Zapier Integration Templates</div>
              <div className="desc">Quickly automate Hirize integrations with Zapier's templates</div>
            </div>
            <Grid container columnSpacing={'12px'} rowSpacing={'24px'}>
              <Grid item xs={8} sm={6} md={6} lg={6} xl={8}>
                <zapier-zap-templates
                  sign-up-email="email_of_your_user@example.com"
                  sign-up-first-name="first_name_of_your_user"
                  sign-up-last-name="last_name_of_your_user"
                  client-id="FgWYx49CSw79fsny9Ssx22oDq3LKGgBSNePA0J3s"
                  theme="light"
                  ids="1742361,1751767,1755689"
                  limit={5}
                  link-target="new-tab"
                  presentation="row"
                  use-this-zap="show"
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};
