import { createSlice } from '@reduxjs/toolkit';
import { snackActions } from 'libs/snackbar';
import { getJobParserDetail, getAllParserJobList, jobParserMakeRequest } from './jobParserAction';

const initialState = {
  success: null,
  message: null,
  job_list: null,
  page_number: 0,
  clicked_job: null,
  job_parser_detail_data: null,
};

export const parserSlice = createSlice({
  name: 'jobParser',
  initialState,
  reducers: {
    resetState: () => initialState,
    updatePageNumber: (state, { payload }) => {
      const { page_number } = payload;

      state.page_number = page_number;
    },
    updateClickedJob: (state, { payload }) => {
      state.clicked_job = payload;
    },
  },
  extraReducers: builder => {
    // JOB PARSER

    // jobParserMakeRequest
    builder.addCase(jobParserMakeRequest.pending, state => {
      state.success = null;
    });
    builder.addCase(jobParserMakeRequest.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      snackActions.success('Job description added successfully!');
    });
    builder.addCase(jobParserMakeRequest.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // getAllParserJobList
    builder.addCase(getAllParserJobList.pending, state => {
      state.success = null;
    });
    builder.addCase(getAllParserJobList.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.job_list = payload.data;
    });
    builder.addCase(getAllParserJobList.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // getJobParserDetail
    builder.addCase(getJobParserDetail.pending, state => {
      state.success = null;
    });
    builder.addCase(getJobParserDetail.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.job_parser_detail_data = payload.data;
    });
    builder.addCase(getJobParserDetail.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });
  },
});

export const { resetState, updateClickedJob } = parserSlice.actions;

export default parserSlice.reducer;
