import { ProtectedLayout } from 'components/layouts/ProtectedLayout';

import React, { useState } from 'react';
import { Button, Grid, IconButton, Tab, Tabs, TextField } from '@mui/material';

import { ReactComponent as IconQuestionBig } from 'assets/icons/question-big.svg';
import { ReactComponent as IconCopy } from 'assets/icons/copy.svg';
import { ReactComponent as IconDownload } from 'assets/icons/download.svg';
import { DropzoneStandart } from 'components/DropzoneStandart';
import { ACCEPT_FILE_FORMATS, MAX_FILE_SIZE } from 'data';
import TabPanel from 'components/elements/TabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { makeHirizeIQRequest } from 'features/hirizeiq/hirizeiqAction';

import { resetHirizeIQResult, updateFileProgressStatus } from 'features/hirizeiq/hirizeiqSlice';
import { useRef } from 'react';
import { copyResult, deleteMultipleSpaces } from 'utils';
import Date from 'components/elements/Date';
import { GeneratePdf } from 'utils/pdfExport';
const LIMIT_JOB_DESCRIPTION = 500;
export const HirizeIQ = () => {
  const dispatch = useDispatch();
  const { hirize_iq_result, file_progress_status } = useSelector(state => state.hirizeiq);
  const [tabValue, setTabValue] = useState('new-outputs');
  const [valueJobDescription, setvalueJobDescription] = useState('');
  const [acceptedFile, setAcceptedFile] = useState(null);
  const lengthValueJobDescription = valueJobDescription.split(' ').length - 1;
  const isLimitExceed = lengthValueJobDescription > LIMIT_JOB_DESCRIPTION;

  const refContent = useRef();

  const handleChangeTab = (event, value) => {
    setTabValue(value);
  };

  const resetForm = () => {
    dispatch(updateFileProgressStatus({ progress: null }));
    dispatch(resetHirizeIQResult());
    setvalueJobDescription('');
    setAcceptedFile(null);
  };
  const handleOnDropChange = acceptedFile => {
    dispatch(updateFileProgressStatus({ progress: null }));
    setAcceptedFile(acceptedFile[0]);
  };

  const handleDeleteFile = event => {
    event.stopPropagation();
    setAcceptedFile(null);
  };

  const handleChangeJobDescription = event => {
    const { value } = event.target;
    setvalueJobDescription(value);
  };

  const clickGenerate = () => {
    dispatch(resetHirizeIQResult());
    dispatch(
      makeHirizeIQRequest({
        file: acceptedFile,
        job_description: deleteMultipleSpaces(valueJobDescription),
      }),
    );
  };

  const onClickGeneratePdf = () => {
    const filename = 'hirize-iq_' + acceptedFile.name;
    GeneratePdf(refContent, filename);
  };

  const onClickCopyResult = () => {
    const text = refContent?.current?.innerText || '';
    copyResult(text, 'Interview questions copied!');
  };

  return (
    <ProtectedLayout>
      <div className="Page Page-HirizeIQ">
        <div className="HirizeIQ">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="title">HirizeIQ</div>
              <div className="desc">
                Hirize Interview Questions helps you build candidate and job specific summaries and interview questions
              </div>
            </div>
          </div>
          <Grid container columnSpacing={{ xs: '24px' }} className="HirizeIQ-Content">
            <Grid item sm={4} className="HirizeIQ-Generate">
              <div className="HirizeIQ-Generate-Box">
                <div className="heading">
                  <div className="title">Generate IQ</div>
                  <div className="heading-action">
                    <Button variant="outlined" color="white" onClick={resetForm}>
                      Reset
                    </Button>
                  </div>
                </div>
                <div className="generate-form">
                  <div className="form-item">
                    <div className="form-title">Candidate’s Resume</div>
                    <DropzoneStandart
                      onDropChange={handleOnDropChange}
                      accept={ACCEPT_FILE_FORMATS}
                      maxSize={MAX_FILE_SIZE}
                      showIcon={false}
                      text={'PDF, Doc, PNG or JPG (max. 2 MB)'}
                      file={acceptedFile}
                      progress={file_progress_status}
                      deleteFile={handleDeleteFile}
                    />
                  </div>

                  <div className="form-item">
                    <div className="form-title">Job Description</div>
                    <div className="job-description">
                      <TextField
                        placeholder="Paste job description here"
                        multiline
                        minRows={6}
                        maxRows={16}
                        onChange={handleChangeJobDescription}
                        variant="outlined"
                        value={valueJobDescription}
                        error={isLimitExceed}
                        helperText={isLimitExceed ? 'Please enter maximum 500 words!' : ''}
                      />
                      <div className={`job-description-length-counter ${isLimitExceed ? 'limit-exceed' : ''}`}>
                        <span className="length-current">{lengthValueJobDescription}</span>
                        <div className="length-limit">/{LIMIT_JOB_DESCRIPTION}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="action">
                  <Button
                    onClick={clickGenerate}
                    variant="contained"
                    fullWidth
                    size="large"
                    disabled={valueJobDescription?.length === 0 || !acceptedFile || isLimitExceed}
                  >
                    Generate
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item sm={8} className="HirizeIQ-Result">
              <div className="HirizeIQ-Result-Box">
                <div className="heading">
                  <div className="HirizeIQ-Result-Tabs">
                    <Tabs className={'Tabs-Standart Tabs-HirizeIQ-Result'} value={tabValue} onChange={handleChangeTab}>
                      <Tab label="New Outputs" value={'new-outputs'} />

                      <Tab
                        label="History"
                        value={'history'}
                        disabled={!hirize_iq_result || hirize_iq_result?.history.length === 0}
                      />
                    </Tabs>
                  </div>
                  <div className="result-actions">
                    <IconButton
                      size="small"
                      onClick={onClickCopyResult}
                      disabled={!hirize_iq_result || !hirize_iq_result?.result}
                    >
                      <IconCopy />
                    </IconButton>
                    <IconButton disabled={!hirize_iq_result} size="small" onClick={onClickGeneratePdf}>
                      <IconDownload />
                    </IconButton>
                  </div>
                </div>
                <TabPanel value={tabValue} index={'new-outputs'}>
                  <div ref={refContent} className="HirizeIQ-NewOutputs">
                    {hirize_iq_result && hirize_iq_result?.result ? (
                      <ResultBox data={hirize_iq_result?.result} showDate={false} />
                    ) : (
                      <NoResult />
                    )}
                  </div>
                </TabPanel>
                <TabPanel value={tabValue} index={'history'}>
                  <div ref={refContent} className="HirizeIQ-History">
                    {hirize_iq_result?.history?.map((history, index) => {
                      return (
                        <div className="history-item" key={index}>
                          <ResultBox data={history?.response?.result} date={history.created_at} />
                        </div>
                      );
                    })}
                  </div>
                </TabPanel>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </ProtectedLayout>
  );
};

const NoResult = () => {
  return (
    <div className="NoResult">
      <div className="icon">
        <IconQuestionBig />
      </div>
      <div className="text">
        Upload a Resume and add a job description to generate personalized and in-depth interview questions.
      </div>
    </div>
  );
};

const ResultBox = ({ data, date = '' }) => {
  return (
    <div className="ResultBox">
      {!!date && (
        <div className="date">
          <Date date={date} />
        </div>
      )}
      <div className="summary-note">
        <div className="title">Summary Note:</div>
        <div className="text">
          <p>{data?.summary}</p>
        </div>
      </div>
      <div className="interview-questions">
        <div className="title">Interview Questions:</div>
        <ul className="questions">
          {data?.questions.map((question, index) => {
            return <li key={index}>{question}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};
