import { createSlice } from '@reduxjs/toolkit';
import { getAllProduct, getAllProductFunctions } from 'features/product/productAction';
import { calculationProduct, deleteProductPurchasePackage, getCartPlan } from './purchaseAction';

const updatePurchaseProductList = (data, selectedProducts) => {
  return data.map(item => {
    const selectedItem = selectedProducts.find(dataItem => {
      if (dataItem.document_count === 5000) {
        dataItem.document_count = 6000;
      } else if (dataItem.document_count === 10000 || dataItem.document_count === 20000) {
        dataItem.document_count = 15000;
      } else if (dataItem.document_count === 40000) {
        dataItem.document_count = 60000;
      }
      return dataItem.id === item.id;
    });
    /* const isFree = selectedProducts.find(dataItem => dataItem.name === 'Parser'); */
    const isFree = selectedItem && selectedItem.name === 'Parser';

    if (selectedItem && isFree) {
      return {
        ...item,
        document_count: 0,
        total_credit: 0,
        isSelected: false,
      };
    }

    return {
      ...item,
      document_count: selectedItem ? selectedItem.document_count : 0,
      total_credit: selectedItem ? selectedItem.total_credit : 0,
      isSelected: selectedItem ? true : false,
    };
  });
};

const initialState = {
  success: null,
  success_get_products: null,
  message: null,
  cart_data: null,
  purchase_product_list: null,
  purchase_other_product_list: null,
};

export const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    resetPurchaseState: () => initialState,
  },
  extraReducers: builder => {
    // get all product
    builder.addCase(getAllProduct.pending, state => {
      state.success_get_products = null;
    });
    builder.addCase(getAllProduct.fulfilled, (state, { payload }) => {
      state.success_get_products = payload.success;
      state.purchase_product_list = payload.data?.map(item => {
        return {
          ...item,
          isSelected: false,
          document_count: 0,
          total_credit: 0,
        };
      });
    });
    builder.addCase(getAllProduct.rejected, (state, { payload }) => {
      state.success_get_products = payload.success;
    });

    // get all product functions
    builder.addCase(getAllProductFunctions.pending, state => {
      state.success_get_products = null;
    });
    builder.addCase(getAllProductFunctions.fulfilled, (state, { payload }) => {
      state.success_get_products = payload.success;
      state.purchase_other_product_list = payload.data
        ?.filter(item => item.id === 5)
        .map(item => {
          return {
            ...item,
            isSelected: false,
            document_count: 0,
            total_credit: 0,
          };
        });
    });
    builder.addCase(getAllProductFunctions.rejected, (state, { payload }) => {
      state.success_get_products = payload.success;
    });

    // calculationProduct
    builder.addCase(calculationProduct.pending, state => {
      state.success = null;
    });
    builder.addCase(calculationProduct.fulfilled, (state, { payload }) => {
      const { success, data } = payload;
      state.success = success;
      state.cart_data = data;

      state.purchase_product_list = updatePurchaseProductList(state.purchase_product_list, data.products);
      state.purchase_other_product_list = updatePurchaseProductList(state.purchase_other_product_list, data.products);
    });
    builder.addCase(calculationProduct.rejected, (state, { payload }) => {
      state.success = payload.success;
    });

    // deleteProductPurchasePackage
    builder.addCase(deleteProductPurchasePackage.pending, state => {
      state.success = null;
    });
    builder.addCase(deleteProductPurchasePackage.fulfilled, (state, { payload }) => {
      const { success, data } = payload;
      state.success = success;
      state.cart_data = data;

      state.purchase_product_list = updatePurchaseProductList(state.purchase_product_list, data.products);
      state.purchase_other_product_list = updatePurchaseProductList(state.purchase_other_product_list, data.products);
    });
    builder.addCase(deleteProductPurchasePackage.rejected, (state, { payload }) => {
      state.success = payload.success;
    });

    // getCartPlan
    builder.addCase(getCartPlan.pending, state => {
      state.success = null;
    });
    builder.addCase(getCartPlan.fulfilled, (state, { payload }) => {
      const { success, data } = payload;
      state.success = success;
      state.cart_data = data;
      state.purchase_product_list = updatePurchaseProductList(state.purchase_product_list, data.products);
      state.purchase_other_product_list = updatePurchaseProductList(state.purchase_other_product_list, data.products);
    });
    builder.addCase(getCartPlan.rejected, (state, { payload }) => {
      state.success = payload.success;
    });
  },
});

export const { resetPurchaseState } = purchaseSlice.actions;

export default purchaseSlice.reducer;
