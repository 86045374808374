import {
  AlignmentType,
  Document,
  ExternalHyperlink,
  Footer,
  Header,
  ImageRun,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from 'docx';
import { NO_BORDER, PARAGRAPH_TEXT } from './doc_common';

const DOC_LIST_RESUME = [
  {
    text: 'Candidate Name',
    key: 'name',
    value: null,
  },

  {
    text: 'Nationality',
    key: 'nationality',
    value: null,
  },

  {
    text: 'Current Location',
    key: 'current_location',
    value: null,
  },

  {
    text: 'Current Position',
    key: 'current_position',
    value: null,
  },

  {
    text: 'Date of Birth',
    key: 'date_of_birth',
    value: null,
  },

  {
    text: 'Languages',
    key: 'languages',
    value: null,
  },

  {
    text: 'Notice Period',
    key: 'notice_period',
    value: null,
  },

  {
    text: 'Current Salary',
    key: 'current_salary',
    value: null,
  },

  {
    text: 'Expected Salary',
    key: 'expected_salary',
    value: null,
  },

  {
    text: 'International Experience',
    key: 'international_experience1',
    value: null,
  },
  {
    text: 'Additional Information',
    key: 'additional_information',
    value: null,
  },
];

const DOC_HEADIGN = text => {
  return new Table({
    columnWidths: [10000],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: NO_BORDER,
            width: {
              size: 10000,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                spacing: {
                  before: 0,
                  after: 0,
                },
                indent: {
                  left: 0,
                },
                shading: {
                  type: ShadingType.SOLID,
                  color: '7F7F7F',
                },
                children: [
                  new TextRun({
                    text: text,
                    bold: true,
                    color: 'ffd966',
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });
};

const DOC_TABLE_RESUME = data => {
  DOC_LIST_RESUME[0]['value'] = data.basic_info.name;
  DOC_LIST_RESUME[2]['value'] = `${data?.contact_info?.city || ''} ${data?.contact_info?.country || ''}`;
  DOC_LIST_RESUME[3]['value'] = data.basic_info.current_position;
  return new Table({
    columnWidths: [2500, 7500],
    rows: DOC_LIST_RESUME.map(item => {
      return new TableRow({
        children: [
          new TableCell({
            width: {
              size: 2500,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.text,
                    bold: true,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 7500,
              type: WidthType.DXA,
            },
            children: [PARAGRAPH_TEXT(item.value ? item.value : '')],
          }),
        ],
      });
    }),
  });
};

const DOC_TABLE_BULLET_POINTS = new Table({
  columnWidths: [10000],
  rows: [
    new TableRow({
      children: [
        new TableCell({
          width: {
            size: 10000,
            type: WidthType.DXA,
          },
          borders: { bottom: NO_BORDER.bottom },
          children: [PARAGRAPH_TEXT()],
        }),
      ],
    }),
    new TableRow({
      height: { value: 100 },
      children: [
        new TableCell({
          width: {
            size: 10000,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              text: '',
              bullet: {
                level: 0,
              },
            }),
          ],
        }),
      ],
    }),
    new TableRow({
      children: [
        new TableCell({
          width: {
            size: 10000,
            type: WidthType.DXA,
          },
          borders: { top: NO_BORDER.top },
          children: [PARAGRAPH_TEXT()],
        }),
      ],
    }),
  ],
});

const DOC_TABLE_EDUCATION_QUALIFICATIONS = data => {
  if (data === undefined) {
    data = [];
  }
  const data_length = data ? data.length : 0;
  const empty_data = Array(3 - (data_length > 2 ? 2 : data_length)).fill('1');
  return new Table({
    columnWidths: [1000, 4000, 5000],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 1000,
              type: WidthType.DXA,
            },
            children: [PARAGRAPH_TEXT('Year', true, false)],
          }),
          new TableCell({
            width: {
              size: 4000,
              type: WidthType.DXA,
            },
            children: [PARAGRAPH_TEXT('Institution', true, false)],
          }),
          new TableCell({
            width: {
              size: 5000,
              type: WidthType.DXA,
            },
            children: [PARAGRAPH_TEXT('Qualification', true, false)],
          }),
        ],
      }),

      ...data?.map(item => {
        return new TableRow({
          children: [
            new TableCell({
              width: {
                size: 1000,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT(item.graduation_year)],
            }),
            new TableCell({
              width: {
                size: 4000,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT(`${item.school_name} / ${item.education_level}`)],
            }),
            new TableCell({
              width: {
                size: 5000,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT(`${item.major}`)],
            }),
          ],
        });
      }),

      ...empty_data.map(() => {
        return new TableRow({
          children: [
            new TableCell({
              width: {
                size: 1000,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT()],
            }),
            new TableCell({
              width: {
                size: 4000,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT()],
            }),
            new TableCell({
              width: {
                size: 5000,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT()],
            }),
          ],
        });
      }),
    ],
  });
};

const DOC_TABLE_EMPLOYMENT_SUMMARY = data => {
  if (data === undefined) {
    data = [];
  }
  const data_length = data ? data.length : 0;
  const empty_data = Array(7 - (data_length > 6 ? 6 : data_length)).fill('1');
  return new Table({
    columnWidths: [3333, 3333, 3333],
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3333,
              type: WidthType.DXA,
            },
            children: [PARAGRAPH_TEXT('Company', true, false)],
          }),
          new TableCell({
            width: {
              size: 3333,
              type: WidthType.DXA,
            },
            children: [PARAGRAPH_TEXT('Position', true, false)],
          }),
          new TableCell({
            width: {
              size: 3333,
              type: WidthType.DXA,
            },
            children: [PARAGRAPH_TEXT('Period', true, false)],
          }),
        ],
      }),
      ...data?.map(item => {
        return new TableRow({
          children: [
            new TableCell({
              width: {
                size: 3333,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT(item.company)],
            }),
            new TableCell({
              width: {
                size: 3333,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT(`${item.seniority} ${item.job_title}`)],
            }),
            new TableCell({
              width: {
                size: 3333,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT(`${item.start_date} ${item.end_date}`)],
            }),
          ],
        });
      }),
      ...empty_data.map(() => {
        return new TableRow({
          children: [
            new TableCell({
              width: {
                size: 3333,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT()],
            }),
            new TableCell({
              width: {
                size: 3333,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT()],
            }),
            new TableCell({
              width: {
                size: 3333,
                type: WidthType.DXA,
              },
              children: [PARAGRAPH_TEXT()],
            }),
          ],
        });
      }),
    ],
  });
};

const DOC_TABLE_EMPLOYMENT_DETAILS = data => {
  return new Table({
    columnWidths: [10000],
    rows: [
      ...data?.map(item => {
        return new TableRow({
          children: [
            new TableCell({
              margins: {
                top: 100,
                bottom: 100,
              },
              width: {
                size: 10000,
                type: WidthType.DXA,
              },
              children: [
                PARAGRAPH_TEXT(),
                PARAGRAPH_TEXT('Company / project info', true, false),
                PARAGRAPH_TEXT(`${item.job_title} / ${item.company}`, false, true),
                PARAGRAPH_TEXT('', false, true),

                PARAGRAPH_TEXT('Job description:', true, false),
                PARAGRAPH_TEXT(item.job_description || '', false, true),
                PARAGRAPH_TEXT('', false, true),

                PARAGRAPH_TEXT('Reason for Availability:', true, false),
                PARAGRAPH_TEXT('', false, true),
              ],
            }),
          ],
        });
      }),
      ...[1, 2, 3].map(item => {
        return new TableRow({
          children: [
            new TableCell({
              margins: {
                top: 100,
                bottom: 100,
              },
              width: {
                size: 10000,
                type: WidthType.DXA,
              },
              children: [
                PARAGRAPH_TEXT(),
                PARAGRAPH_TEXT('Company / project info', true, false),
                PARAGRAPH_TEXT('', false, true),

                PARAGRAPH_TEXT('Job description:', true, false),
                PARAGRAPH_TEXT('', false, true),

                PARAGRAPH_TEXT('Reason for Availability:', true, false),
                PARAGRAPH_TEXT('', false, true),
              ],
            }),
          ],
        });
      }),
    ],
  });
};

export const DOC_CA_MINING = async data => {
  const image = await fetch('/images/ca-mining.png').then(r => r.blob());
  return new Document({
    styles: {
      default: {
        document: {
          run: {
            font: 'Arial',
          },
          paragraph: {
            spacing: {
              before: 60,
              after: 60,
            },
            indent: {
              left: 120,
            },
          },
        },
      },
    },

    sections: [
      {
        headers: {
          default: new Header({
            alignment: AlignmentType.CENTER,
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new ImageRun({
                    alignment: AlignmentType.CENTER,
                    data: image,
                    transformation: {
                      width: 400,
                      height: 125,
                    },
                  }),
                ],
              }),
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                children: [new TextRun({ text: 'Strictly Private & Confidential', bold: true, size: 18 })],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    size: 14,
                    text: 'This C.V. is subject to our Terms of Business and Confidentiality. No contact may be made with the candidate, employer or referee without the express permission of the consultant. Should the recipient of this CV interview this candidate whose details are contained herein and subsequently recruit this candidate, then they hereby agree to employ this candidate through CA Global in accordance with CA Global Terms of Business.',
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [new TextRun({ text: 'Leanne Laas', bold: true, size: 20 })],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [new TextRun({ text: 'T +27 21 659 9200', bold: true, size: 15 })],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    bold: true,
                    size: 15,
                    text: 'Email: ',
                  }),
                  new ExternalHyperlink({
                    children: [
                      new TextRun({
                        bold: true,
                        size: 15,
                        text: 'llaas@camining.com/',
                        style: 'Hyperlink',
                      }),
                    ],
                    link: 'mailto:llaas@camining.com',
                  }),
                  new TextRun({
                    bold: true,
                    size: 15,
                    text: ' Website: ',
                  }),
                  new ExternalHyperlink({
                    children: [
                      new TextRun({
                        bold: true,
                        size: 15,
                        text: 'www.camining.com',
                        style: 'Hyperlink',
                      }),
                    ],
                    link: 'https://camining.com/',
                  }),

                  new TextRun({
                    bold: true,
                    size: 15,
                    text: ' / ',
                  }),
                  new ExternalHyperlink({
                    children: [
                      new TextRun({
                        bold: true,
                        size: 15,
                        text: 'www.mining-recruitment-jobs.com',
                        style: 'Hyperlink',
                      }),
                    ],
                    link: 'https://mining-recruitment-jobs.com/',
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [new TextRun({ bold: true, size: 15, text: 'CA Mining (Pty) Ltd / 2011/142637/07' })],
                alignment: AlignmentType.CENTER,
              }),
            ],
          }),
        },
        children: [
          DOC_HEADIGN('CURRICULUM VITAE'),
          PARAGRAPH_TEXT(),
          DOC_TABLE_RESUME(data),
          PARAGRAPH_TEXT(),

          DOC_HEADIGN('PERSONAL SYNOPSIS'),
          PARAGRAPH_TEXT(),
          DOC_TABLE_BULLET_POINTS,
          PARAGRAPH_TEXT(),

          DOC_HEADIGN('EDUCATION AND QUALIFICATIONS'),
          PARAGRAPH_TEXT(),
          DOC_TABLE_EDUCATION_QUALIFICATIONS(data?.education),
          PARAGRAPH_TEXT(),

          DOC_HEADIGN('COURSES & CERTIFICATES'),
          PARAGRAPH_TEXT(),
          DOC_TABLE_BULLET_POINTS,
          PARAGRAPH_TEXT(),

          DOC_HEADIGN('PROFESSIONAL MEMBERSHIPS & REGISTRATIONS'),
          PARAGRAPH_TEXT(),
          DOC_TABLE_BULLET_POINTS,
          PARAGRAPH_TEXT(),

          DOC_HEADIGN('COMPUTER LITERACY'),
          PARAGRAPH_TEXT(),
          DOC_TABLE_BULLET_POINTS,
          PARAGRAPH_TEXT(),

          DOC_HEADIGN('EMPLOYMENT SUMMARY'),
          PARAGRAPH_TEXT(),
          DOC_TABLE_EMPLOYMENT_SUMMARY(data?.work_experience?.works),
          PARAGRAPH_TEXT(),

          DOC_HEADIGN('EMPLOYMENT DETAILS'),
          PARAGRAPH_TEXT(),
          DOC_TABLE_EMPLOYMENT_DETAILS(data?.work_experience?.works),
          PARAGRAPH_TEXT(),
        ],
      },
    ],
  });
};
