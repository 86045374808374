import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const createSubscription = createAsyncThunk(
  'purchase/createSubscription',
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('api/subscription', postData);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const paymentCheckStatus = createAsyncThunk(
  'purchase/paymentCheckStatus',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/subscription/payment/${id}/check-status`);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
