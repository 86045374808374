import { BorderStyle, Paragraph, TextRun } from 'docx';
export const NO_BORDER = {
  top: {
    style: BorderStyle.NONE,
    size: 0,
  },
  bottom: {
    style: BorderStyle.NONE,
    size: 0,
  },
  left: {
    style: BorderStyle.NONE,
    size: 0,
  },
  right: {
    style: BorderStyle.NONE,
    size: 0,
  },
};

export const PARAGRAPH_TEXT = (text, bold = false, italics = false) => {
  return new Paragraph({
    children: [
      new TextRun({
        text: text ? text : '',
        bold,
        italics,
      }),
    ],
  });
};
