import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const getProfile = createAsyncThunk('profile/getProfile', async (body, { rejectWithValue }) => {
  try {
    const { data } = await axios.get('api/profile');
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const updateProfile = createAsyncThunk('profile/updateProfile', async (body, { rejectWithValue }) => {
  try {
    const { confirm_password, current_password, email, first_name, last_name, new_password, phone } = body;

    const postData = {
      name: first_name,
      surname: last_name,
      email,
      phone: JSON.stringify(phone),
      current_password: current_password,
      password: new_password,
      confirm_password: confirm_password,
    };
    const { data } = await axios.put('api/profile', postData);

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const updateProfilePicture = createAsyncThunk(
  'profile/updateProfilePicture',
  async (files, { dispatch, rejectWithValue }) => {
    let formdata = new FormData();
    formdata.append('image', files);

    try {
      const { data } = await axios.post(
        'api/profile/picture',
        {
          file: files[0],
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      await dispatch(getProfile());

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const deleteProfilePicture = createAsyncThunk(
  'profile/deleteProfilePicture',
  async (data, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete('api/profile/picture');

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
