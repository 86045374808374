import { Grid } from '@mui/material';
import { ProductCheckout } from 'components/cards/ProductCheckout';

import { calculationProduct, deleteProductPurchasePackage } from 'features/purchase/purchaseAction';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const BuildPlan = () => {
  const dispatch = useDispatch();
  const { product_count_options, profit_product_count_options } = useSelector(state => state.product);

  const { purchase_product_list, purchase_other_product_list, cart_data } = useSelector(state => state.purchase);
  const { company_plan_data } = useSelector(state => state.company);

  const IS_EDIT_PLAN = !company_plan_data?.is_free;
  const handleDeleteProductPurchasePackage = (data, type) => {
    dispatch(
      deleteProductPurchasePackage({
        productId: data.id,
        packageId: cart_data?.id,
        productType: type,
      }),
    );
  };

  const handleCalculationProduct = (data, value, type) => {
    dispatch(
      calculationProduct({
        product_id: data.id,
        document_count: parseInt(value),
        purchase_plan_id: cart_data?.products.length > 0 ? cart_data?.id || null : null,
        productType: type,
      }),
    );
  };

  const handleChangeSelectedProduct = (value, data, type) => {
    if (value) {
      handleCalculationProduct(data, value, type);
    } else {
      handleDeleteProductPurchasePackage(data, type);
    }
  };
  const handleChangeSelectedProductDocuments = (event, data, type) => {
    const { value } = event.target;
    if (value === 0) {
      handleDeleteProductPurchasePackage(data, type);
    } else {
      handleCalculationProduct(data, parseInt(value), type);
    }
  };

  return (
    <div className="Page-BuildPlan">
      <div className="page-heading">
        <div className="title">{IS_EDIT_PLAN ? 'Edit Your Subscription' : 'Build Your Subscription'}</div>
      </div>
      <div className="page-content">
        <div className="product-list">
          <h3 className="section-title">Choose Products</h3>
          <Grid container columnSpacing={'24px'} rowSpacing={'16px'}>
            {purchase_product_list?.length > 0 &&
              purchase_product_list?.map((item, index) => {
                return (
                  <Grid key={index} item sm={6}>
                    <ProductCheckout
                      type={'normal'}
                      options={product_count_options && product_count_options}
                      optitons_profits={profit_product_count_options}
                      data={item}
                      changeSelectProduct={handleChangeSelectedProduct}
                      changeNumberDocuments={handleChangeSelectedProductDocuments}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </div>

        <div className="product-list product-list-other">
          <h3 className="section-title">Other Products</h3>

          <Grid container columnSpacing={'24'} rowSpacing={'16'}>
            {purchase_other_product_list?.length > 0 &&
              purchase_other_product_list?.map((item, index) => {
                return (
                  <Grid key={index} item sm={6}>
                    <ProductCheckout
                      type={'feature'}
                      options={product_count_options && product_count_options}
                      data={item}
                      changeSelectProduct={handleChangeSelectedProduct}
                      changeNumberDocuments={handleChangeSelectedProductDocuments}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </div>
    </div>
  );
};
