import { FormControl, InputLabel, TextField } from '@mui/material';
import { FORM_TEXT } from 'data';
import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

export const FormInput = forwardRef(function FormInput(props, ref) {
  const {
    label,
    errors,
    isRequired = true,
    name,
    mask = null,
    rules = {},
    control,
    helperText,
    viewType = 'normal',
    ...others
  } = props;
  return (
    <>
      {!!label && (
        <InputLabel className="Label-TextField">
          {label} {isRequired ? <i className="required">*</i> : ''}
        </InputLabel>
      )}
      <FormControl fullWidth size={'small'}>
        <Controller
          name={name}
          control={control}
          rules={{ ...rules, required: isRequired ? FORM_TEXT.required : null }}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                error={!!errors?.[name]?.['message']}
                helperText={errors?.[name]?.['message'] || (helperText && helperText)}
                variant="outlined"
                value={value || ''}
                onChange={onChange}
                className={`type-${viewType}`}
                inputRef={ref}
                InputProps={{
                  inputComponent: mask ? CustomInputMask : undefined,
                  inputProps: {
                    mask,
                  },
                }}
                {...others}
              />
            );
          }}
        />
      </FormControl>
    </>
  );
});

const CustomInputMask = forwardRef(function CustomInputMask(props, ref) {
  return <InputMask inputRef={ref} {...props} />;
});
