import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { ProtectedLayout } from 'components/layouts/ProtectedLayout';
import { CONTACT_LIST, CONTACT_US } from 'data/dashboard';
import { useDispatch, useSelector } from 'react-redux';

import { getAllProduct } from 'features/product/productAction';
import { Product as CardProduct } from 'components/cards/Product';

export const Dashboard = () => {
  const dispatch = useDispatch();

  const { allProduct } = useSelector(state => state.product);

  useEffect(() => {
    dispatch(getAllProduct());
  }, []);

  const filter_dashboard_products = allProduct?.filter(
    product => product.id === 3 || product.id === 1 || product.id === 8,
  );

  return (
    <ProtectedLayout>
      <div className="Page Page-Dashboard">
        <div className="Dashboard">
          <div className="Dashboard-GetStarted">
            <div className="Dashboard-Box-Heading">
              <div className="title">Explore Hirize</div>
              <div className="desc">
                Discover how Hirize can revolutionize your recruitment process with cutting-edge AI solutions.
              </div>
            </div>
            <div className="Dashboard-Content">
              <div className="Products-List">
                <Grid className="grid-product-list" container columnSpacing={'24px'} rowSpacing={'24px'}>
                  {filter_dashboard_products &&
                    [
                      filter_dashboard_products?.[0],
                      filter_dashboard_products?.[2],
                      filter_dashboard_products?.[1],
                    ]?.map((item, index) => {
                      return (
                        <Grid key={index} item sm={4}>
                          <CardProduct type="normal" data={item} />
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            </div>
          </div>

          <Grid container columnSpacing={'24px'}>
            <Grid item xs={12} sm={6}>
              <ConnectList />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ContactUs />
            </Grid>
          </Grid>
        </div>
      </div>
    </ProtectedLayout>
  );
};

const ContactUs = () => {
  return (
    <div className="Dashboard-ContactUs">
      <div className="Dashboard-Box-Heading">
        <div className="title">Help center</div>
      </div>
      <div className="ContactUs-Content">
        <Grid className="contact-list" gap={'12px'} container>
          {CONTACT_US.map(item => {
            return (
              <Grid key={item.id} item sm={12}>
                <CardDashboardInfo type="contact" data={item} />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

const ConnectList = () => {
  return (
    <div className="Dashboard-ConnectList">
      <div className="Dashboard-Box-Heading">
        <div className="title">Connect with other tools to boost your efficiency</div>
      </div>
      <div className="ConnectList-Content">
        <Grid className="connect-list" gap={'12px'} container>
          {CONTACT_LIST.map(item => {
            return (
              <Grid key={item.id} item sm={12}>
                <CardDashboardInfo type="connect" data={item} />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

const CardDashboardInfo = ({ data, type = 'contact' }) => {
  const { icon, text, desc, href, icon_image = null } = data;
  return (
    <a href={href}>
      <div className={`Card-DashboardInfo type-${type}`}>
        {icon ? <div className="icon">{icon}</div> : null}
        {icon_image ? (
          <div className="icon-image">
            <img src={icon_image} alt={text} />
          </div>
        ) : null}
        <div className="info">
          <div className="text">{text}</div>
          <div className="desc">{desc}</div>
        </div>
      </div>
    </a>
  );
};
