import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const calculationProduct = createAsyncThunk('purchase/calculationProduct', async (data, { rejectWithValue }) => {
  const postData = {
    ...data,
  };

  try {
    const { data } = await axios.post('api/purchase/calculation', postData);
    return {
      ...data,
      productType: postData.productType,
    };
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const deleteProductPurchasePackage = createAsyncThunk(
  'purchase/deleteProductPurchasePackage',
  async (postData, { rejectWithValue }) => {
    const { packageId, productId } = postData;

    try {
      const { data } = await axios.delete(`api/purchase/calculation/${packageId}/${productId}`, postData);
      return {
        ...data,
        productType: postData.productType,
      };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const getCartPlan = createAsyncThunk('purchase/getCardPlan', async (postData, { rejectWithValue }) => {
  try {
    const { data } = await axios.get('api/purchase/temp-plan');

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
