import { ProtectedLayout } from 'components/layouts/ProtectedLayout';
import React, { useEffect, useState } from 'react';
import { Button, IconButton, Tab, Tabs } from '@mui/material';
import { ReactComponent as IconSettings2 } from 'assets/icons/settings-2.svg';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { TabLabel } from 'components/elements/TabLabel';
import { ROUTE_PATHS } from 'data/paths';
import { useSelector } from 'react-redux';

export const Parser = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { loading } = useSelector(state => state.parser);

  const pageValue = location.pathname.split('/')[3];

  const [tabValue, setTabValue] = useState(pageValue);

  const handleChangeTab = (event, value) => {
    navigate('/products/parser/' + value);
    setTabValue(value);
  };

  useEffect(() => {
    const pageValue = location.pathname.split('/')[3];

    setTabValue(pageValue);
  }, [location]);

  return (
    <ProtectedLayout loading={loading}>
      <div className="Page Page-Parser">
        <div className="Parser">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="title">Parser</div>
              <div className="desc">Upload up to 50 files.</div>
            </div>
          </div>
          <div className="Parser-Content">
            <div className="Parser-Tabs">
              <Tabs className={'Tabs-Standart Tabs-Parser'} value={tabValue} onChange={handleChangeTab}>
                <Tab label={<TabLabel label="Resume's" />} value={'resume'} />
                <Tab label={<TabLabel label="Job Descriptions" chipType="pro" />} value={'job'} />
              </Tabs>
              <div className="parser-content-tab-action">
                <Button
                  variant="contained"
                  component={Link}
                  color="secondary"
                  to={`${ROUTE_PATHS.PRODUCTS_PARSER_SETTINGS}?type=${tabValue}`}
                >
                  Switch Model
                </Button>
                <IconButton
                  component={Link}
                  color="white"
                  to={`${ROUTE_PATHS.PRODUCTS_PARSER_SETTINGS}?type=${tabValue}`}
                >
                  <IconSettings2 />
                </IconButton>
              </div>
            </div>
            <div className="Parser-Page">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};
