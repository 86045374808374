import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as IconChevronRight } from 'assets/icons/chevron-right.svg';
import React from 'react';
import { DATA_PRODUCTS } from 'data/products';
import { useSelector } from 'react-redux';
import { Chip } from 'components/elements/Chip';

export const Product = ({ data, type }) => {
  const { key, icon, name, description } = data;
  const { profile } = useSelector(state => state.profile);
  const IS_FEATURE = type === 'feature';

  const PRODUCT_EXTRA = DATA_PRODUCTS?.[data.id];

  const product_url = !!PRODUCT_EXTRA?.['pathname'] ? DATA_PRODUCTS?.[data.id]?.['pathname'] : '#';

  const card_url = IS_FEATURE
    ? `https://hirize.hr/contact?name=${profile?.name ?? ''}&surname=${profile?.surname ?? ''}`
    : product_url;

  let cardProps = {
    component: IS_FEATURE || PRODUCT_EXTRA?.isRedirect ? 'a' : Link,
    ...((IS_FEATURE || PRODUCT_EXTRA?.isRedirect) && { target: '_blank' }),
    ...(IS_FEATURE || PRODUCT_EXTRA?.isRedirect ? { href: card_url } : { to: card_url }),
  };

  return (
    <TagProductCard {...cardProps}>
      <div className="Card-Product">
        <div className="Card-Header">
          <div className="product-icon">
            <img src={icon} alt={name} />
          </div>
        </div>

        <div className="Card-Content">
          <div className="product-title">
            {name} {key === 'HIRIZE_INVPARSER' ? <Chip color="secondary" type="tint" size="xsmall" label="Beta" /> : ''}
          </div>
          <div className="product-desc">
            {description}{' '}
            {key === 'PARSER' ? (
              <>
                <br />
                <br /> New advanced model Hirize Velocity is live now!{' '}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className={`Card-Footer`}>
          <div className="Card-Footer-Left">
            {IS_FEATURE ? (
              <Button rel="noreferrer" target={'_blank'} href={null} size={'medium'} variant="outlined" color="white">
                Contact Sales
              </Button>
            ) : (
              <Button href={null} size={'xsmall'} endIcon={<IconChevronRight />} variant="contained" color="primary">
                Try Now
              </Button>
            )}
          </div>
        </div>
      </div>
    </TagProductCard>
  );
};

const TagProductCard = ({ component = Link, children, ...props }) => React.createElement(component, props, children);
