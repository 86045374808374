import { createSlice } from '@reduxjs/toolkit';
import { makeHirizeIQRequest } from './hirizeiqAction';

const initialState = {
  success: null,
  message: null,
  hirize_iq_result: null,
  file_progress_status: null,
};

export const hirizeiqSlice = createSlice({
  name: 'hirizeiq',
  initialState,
  reducers: {
    updateFileProgressStatus: (state, { payload }) => {
      const { progress } = payload;
      state.file_progress_status = progress;
    },
    resetHirizeIQResult: state => {
      state.hirize_iq_result = null;
    },
  },

  extraReducers: builder => {
    // makeHirizeIQRequest
    builder.addCase(makeHirizeIQRequest.pending, state => {
      state.success = null;
    });
    builder.addCase(makeHirizeIQRequest.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.hirize_iq_result = payload.data;
    });
    builder.addCase(makeHirizeIQRequest.rejected, (state, { payload }) => {
      state.success = payload;
    });
  },
});

export const { updateFileProgressStatus, resetHirizeIQResult } = hirizeiqSlice.actions;

export default hirizeiqSlice.reducer;
