import 'styles/main.scss';
import themeSettings from 'libs/theme';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';

import store from 'libs/store';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { ThemeProvider } from '@mui/system';
import { SnackbarProvider } from 'notistack';
import { routes } from 'libs/routes';
import { injectStore } from 'libs/axios';
import { SnackbarUtilsConfigurator } from 'libs/snackbar';
injectStore(store);
function App() {
  return (
    <SnackbarProvider
      maxSnack={9}
      autoHideDuration={2000}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
    >
      <SnackbarUtilsConfigurator />

      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themeSettings}>
            <div className="App">{useRoutes(routes)}</div>
          </ThemeProvider>
          <CssBaseline />
        </StyledEngineProvider>
      </Provider>
    </SnackbarProvider>
  );
}

export default App;
