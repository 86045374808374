import { InputLabel, Select } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { ReactComponent as IconArrowDown } from 'assets/icons/arrow-down.svg';

export const FormSelect = props => {
  const { name, label, type = 'normal', control, defaultValue, placeholder, children, isRequired = true } = props;
  const labelId = `${name}-label`;

  return (
    <>
      {label ? (
        <InputLabel className="Label-TextField" id={labelId}>
          {label} {isRequired ? <i className="required">*</i> : ''}
        </InputLabel>
      ) : (
        ''
      )}
      <Controller
        render={({ field }) => (
          <Select
            fullWidth
            className={`Select-Standart type-${type}`}
            IconComponent={IconArrowDown}
            labelId={labelId}
            placeholder={placeholder}
            MenuProps={{
              disableScrollLock: true,
              className: `Select-Standart-Menu type-${type}`,
            }}
            {...field}
          >
            {children}
          </Select>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </>
  );
};
