import { ReactComponent as IconLoading } from 'assets/icons/loading.svg';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import { ReactComponent as IconError } from 'assets/icons/error.svg';
export const DATA_PARSER_LIST_HEAD = [
  {
    id: 'name',
    label: 'FILE NAME',
    width: '20%',
    align: 'left',
  },
  {
    id: 'date',
    label: 'DATE UPLOADED',
    width: '20%',
    align: 'left',
  },
  {
    id: 'integration_platform',
    label: 'INTEGRATION PLATFORM',
    width: '20%',
    align: 'left',
  },
  {
    id: 'tags',
    label: 'TAGS',
    width: '50%',
    align: 'left',
  },
  {
    id: 'actions',
    label: 'ACTIONS',
    width: '10%',
    align: 'right',
  },
];

export const DATA_JOB_PARSER_LIST_HEAD = [
  {
    id: 'name',
    label: 'JOB TITLE',
    width: '20%',
    align: 'left',
  },
  {
    id: 'date',
    label: 'DATE CREATED',
    width: '20%',
    align: 'left',
  },
  {
    id: 'tags',
    label: 'TAGS',
    width: '50%',
    align: 'left',
  },
  {
    id: 'actions',
    label: 'ACTIONS',
    width: '10%',
    align: 'right',
  },
];

export const STATUS_LIST = {
  success: {
    label: 'Completed',
    icon: <IconCheck />,
  },
  error: {
    label: 'Error',
    icon: <IconError />,
  },
  loading: {
    label: 'Loading',
    icon: <IconLoading />,
  },
};

export const DATA_CONTACT_LIST = [
  {
    name: 'phone_number',
    text: 'Phone Number',
    fullwidth: false,
  },
  {
    name: 'email',
    text: 'E-Mail',
    fullwidth: false,
  },
  {
    name: 'street_address',
    text: 'Street Address',
    fullwidth: true,
  },
  {
    name: 'zip_code',
    text: 'Postal Code',
    fullwidth: false,
  },
  {
    name: 'city',
    text: 'City',
    fullwidth: false,
  },
  {
    name: 'region',
    text: 'Region',
    fullwidth: false,
  },
  {
    name: 'country',
    text: 'Country',
    width: '50',
  },
];
