import { Button, Drawer, IconButton, Tooltip } from '@mui/material';
import { ReactComponent as IconArrowRight2 } from 'assets/icons/arrow-right-2.svg';
import { TooltipStandartBox } from 'components/elements/TooltipStandartBox';
import { DATA_SOCIAL_MEDIA_ICONS } from 'data';
import { DATA_CONTACT_LIST } from 'data/parser';
import React from 'react';
import { ReactComponent as IconClose } from 'assets/icons/close.svg';
import { convertMonthsToTexFormat, convertURLtoSocialMediaName } from 'utils';
import { ReactComponent as IconExternalLink } from 'assets/icons/external-link.svg';
import Date from 'components/elements/Date';
import { Chip } from 'components/elements/Chip';
import { ChipDemoPro, ChipPro } from 'components/elements/ChipDemoPro';
import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import { ReactComponent as IconError } from 'assets/icons/error.svg';
import { ReactComponent as IconChevronRight } from 'assets/icons-v2/chevron-right.svg';
import { useSelector } from 'react-redux';

export const ParserDetailBox = ({ children, title, label, match_score, onClick, direction = 'column', gap = '12' }) => {
  const no_score = match_score === null || match_score === undefined;
  const color = no_score ? 'default' : match_score === 100 ? 'green' : match_score === 0 ? 'red' : 'default';
  const is_color_green = color === 'green';
  return (
    <div className={`ParserDetail-Box direction-${direction} gap-${gap} color-${color}`}>
      <div className="box-heading">
        <div className="heading-left">
          <div className="title">{title}</div>
          {label && <Chip color="primary" type="stroke" label={label} dot />}
        </div>

        <div className="heading-right">
          <div className="match-text">
            {no_score ? (
              ''
            ) : (
              <Chip
                color={is_color_green ? 'success' : 'red'}
                type="tint"
                size="small"
                label={is_color_green ? 'Matched' : 'Not Matched'}
                icon={is_color_green ? <IconCheck /> : <IconError />}
              />
            )}
          </div>
          {onClick && (
            <div className="action">
              <Button
                size={'small'}
                onClick={onClick}
                endIcon={<IconChevronRight />}
                variant="outlined"
                color="transparent"
              >
                Show Work Details
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="box-content">{children}</div>
    </div>
  );
};

export const BoxContentHeading = ({ name, title, social }) => {
  return (
    <div className="ParserDetail-Heading">
      <div className="Heading-Left">
        <div className="name">{name}</div>
        <div className="job-title">{title}</div>
      </div>
      <div className="Heading-Right">
        {social && (
          <div className="social-media">
            {social.map((item, index) => {
              return typeof item === 'string' ? (
                <SocialLink item={item} key={index} />
              ) : (
                <IconButton
                  size="small"
                  variant="outlined"
                  color="white"
                  rel="noreferrer"
                  target={'_blank'}
                  href={item.url}
                >
                  {DATA_SOCIAL_MEDIA_ICONS[item.name] ? DATA_SOCIAL_MEDIA_ICONS[item.name] : <IconExternalLink />}
                </IconButton>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export const BoxContentSummary = ({ text }) => {
  return (
    <div className="ParserDetail-Summary">
      <div className="Summary-Text">{text}</div>
    </div>
  );
};

export const BoxContentItem1 = ({ text_1, text_2, text_3 }) => {
  return (
    <div className="BoxContent-Item-1">
      <div className="item-start">
        <div className="title">{text_1}</div>
        <div className="desc">{text_2}</div>
      </div>
      <div className="item-end">
        <div className="date">{text_3 ? text_3 : ''}</div>
      </div>
    </div>
  );
};

export const BoxContentItem2 = ({ data }) => {
  const { school_name, major, education_level, graduation_year } = data;

  return (
    <div className="BoxContent-Item-2">
      <div className="item-start">
        <div className="department">{major}</div>
        <div className="school_name">{school_name}</div>
      </div>
      <div className="item-end">
        <div className="degree">{education_level}</div>
        <div className="year">{graduation_year}</div>
      </div>
    </div>
  );
};

export const BoxContentItemChipList = ({ title, list, compatible_skills, type = 'normal' }) => {
  return (
    <div className={`BoxContent-Item-ChipList type-${type}`}>
      <div className="title">
        {title}
        {type === 'pro' ? (
          <Tooltip
            title={
              <TooltipStandartBox
                title={'Hirize Enriched Skills'}
                content={'Upgrade your pack and see Semantic keywords for an advanced experience.'}
              />
            }
            PopperProps={{ className: 'Tooltip-Standart' }}
          >
            <ChipDemoPro type={'pro'} />
          </Tooltip>
        ) : null}
      </div>
      <div className="list">
        {list.map((item, index) => {
          const is_compatible_skill = compatible_skills
            ? Object.entries(compatible_skills || null)?.map(([key, value]) => {
                const result =
                  item.toLocaleLowerCase().replace(/\s/g, '') === value.toLocaleLowerCase().replace(/\s/g, '');
                return result;
              })
            : null;
          return (
            <Chip
              key={index}
              color={is_compatible_skill?.[0] ? 'success' : 'primary'}
              dot
              type="tint"
              label={item}
              size={'small'}
            />
          );

          /* return (
            <div key={index} className={`item ${is_compatible_skill[0] ? 'has-compatible' : ''}`}>
              {item}
            </div>
          ); */
        })}
      </div>
    </div>
  );
};

export const BoxContentItemContact = ({ data }) => {
  return (
    <div className={`BoxContent-Item-Contact `}>
      {DATA_CONTACT_LIST.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <div className={`item ${item.fullwidth ? 'fullwidth' : ''}`}>
              <div className="title">{item.text}</div>
              <div className="value">{data?.[item.name] ? data?.[item.name] : '-'}</div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export const BoxContentItemJobDesc = ({ data }) => {
  return (
    <div className="BoxContent-job-desc">
      <p>{data}</p>
    </div>
  );
};

export const DrawerParserDetail = ({ open, onClose, label, children }) => {
  return (
    <Drawer elevation={0} anchor={'right'} open={open} onClose={onClose}>
      <div className="Drawer-ParserDetail">
        <div className="Drawer-ParserDetail-Header">
          <div className="title">WORK EXPERIENCE</div>
          {label ? (
            <div className="label">
              <Chip label={label} />
            </div>
          ) : null}

          <div className="action">
            <IconButton onClick={onClose} variant="outlined" color="white">
              <IconClose />
            </IconButton>
          </div>
        </div>
        <div className="Drawer-ParserDetail-Content">{children}</div>
      </div>
    </Drawer>
  );
};

export const DrawerContentWorkExperience = ({ data }) => {
  const { profile } = useSelector(state => state.profile);

  return (
    <div className="DrawerContent-WorkExperience">
      {data &&
        data?.works?.map((item, index) => {
          return (
            <div key={index} className="item">
              <div className="heading">
                <div className="heading-left">
                  <div className="title">{item?.company || '-'}</div>
                  <div className="desc">{item?.job_title}</div>
                </div>
                <div className="heading-right">
                  <div className="date">
                    {' '}
                    <TwoDate start_date={item.start_date} end_date={item.end_date} />
                  </div>
                  <div className="time">{item?.months ? convertMonthsToTexFormat(item?.months) : null}</div>
                </div>
              </div>
              {profile?.is_pro ? (
                <div className="content">
                  <div className="content-title">Job Summary</div>
                  <div className="content-detail">{item?.job_description}</div>
                </div>
              ) : (
                <div className="content pro-content">
                  <div className="content-detail">
                    Easily reduce candidate search to seconds with Hirize cutting-edge AI Resume Parser while
                    maintaining accuracy. Discover a new way of reaching top talent, faster than ever before.
                  </div>
                  <div className="pro-content-info">
                    <ChipPro />
                    <div className="info-text">
                      Upgrade to access this feature,{' '}
                      <a target="_blank" href="https://hirize.hr/contact" rel="noreferrer">
                        contact sales
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

const SocialLink = ({ item }) => {
  const HTTP = 'https://';
  const social_media_name = convertURLtoSocialMediaName(item);
  const href = item.includes(HTTP) ? item : HTTP + item;
  return (
    <IconButton size="small" variant="outlined" color="white" rel="noreferrer" target={'_blank'} href={href}>
      {DATA_SOCIAL_MEDIA_ICONS[social_media_name] ? DATA_SOCIAL_MEDIA_ICONS[social_media_name] : <IconExternalLink />}
    </IconButton>
  );
};

export const TwoDate = ({ start_date, end_date }) => {
  return (
    <>
      <Date format="MM/Y" date={start_date} /> -{' '}
      {end_date === 'Present' ? end_date : <Date format="MM/Y" date={end_date} />}
    </>
  );
};
