import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { Chip } from './elements/Chip';

const NAN2DASH = value => (value === 'NaN' ? '-' : value);

export const InvoiceParserDetail = ({ data }) => {
  const {
    invoice_number,
    invoice_date,
    due_date,
    vendor_details,
    customer_details,
    line_items,
    sub_total,
    taxes,
    total_amount,
    currency,
    terms_and_conditions,
    notes,
    payment_details,
  } = data;

  const INVOICE_SUMMARY = [
    {
      label: 'Invoice Number',
      value: NAN2DASH(invoice_number),
    },

    {
      label: 'Invoice Date',
      value: NAN2DASH(invoice_date),
    },
    {
      label: 'Due Date',
      value: NAN2DASH(due_date),
    },
    {
      label: 'Sub Total',
      value: NAN2DASH(sub_total) + NAN2DASH(currency),
    },
    {
      label: 'Total Amount',
      value: NAN2DASH(total_amount) + NAN2DASH(currency),
    },

    {
      label: 'Terms and Conditions',
      value: NAN2DASH(terms_and_conditions),
    },
    {
      label: 'Notes',
      value: NAN2DASH(notes),
    },
  ];

  const INVOICE_PAYMENT_DETAILS = [
    {
      label: 'Bank Name',
      value: NAN2DASH(payment_details?.bank_name),
    },
    {
      label: 'Payment Method',
      value: NAN2DASH(payment_details?.method),
    },
    {
      label: 'Account Number',
      value: NAN2DASH(payment_details?.account_number),
    },
    {
      label: 'Routing Number',
      value: NAN2DASH(payment_details?.routing_number),
    },
    {
      label: 'Account Name',
      value: NAN2DASH(payment_details?.account_name),
    },
  ];
  const VENDOR_DETAILS = [
    {
      label: 'Vendor Name',
      value: NAN2DASH(vendor_details?.vendor_name),
    },
    {
      label: 'Vendor Address',
      value: Object.keys(vendor_details?.vendor_address)
        .map(i => NAN2DASH(vendor_details?.vendor_address[i]))
        .join(', '),
    },

    {
      label: 'Vendor Phone',
      value: NAN2DASH(vendor_details.vendor_contact.phone),
    },
    {
      label: 'Vendor Phone',
      value: NAN2DASH(vendor_details.vendor_contact.email),
    },
  ];

  const CUSTOMER_DETAILS = [
    {
      label: 'Customer Name',
      value: NAN2DASH(customer_details?.customer_name),
    },
    {
      label: 'Customer Address',
      value: Object.keys(customer_details?.customer_address)
        .map(i => NAN2DASH(customer_details?.customer_address[i]))
        .join(', '),
    },

    {
      label: 'Customer Phone',
      value: NAN2DASH(customer_details?.customer_contact?.phone),
    },
    {
      label: 'Customer Phone',
      value: NAN2DASH(customer_details?.customer_contact?.email),
    },
  ];
  return (
    <div className="InvoiceParserDetail">
      <Grid container rowSpacing={{ xs: '16px' }} columnSpacing={{ xs: '16px' }}>
        <Grid item xs={6} md={6}>
          <CardInvoiceStandart title={'Invoice Summary'} subtitle={''} list={INVOICE_SUMMARY} />
        </Grid>
        <Grid item xs={6} md={6}>
          <CardInvoiceStandart title={'Payment Details'} subtitle={''} list={INVOICE_PAYMENT_DETAILS} />
        </Grid>
        <Grid item xs={6} md={6}>
          <CardInvoiceStandart title={'Vendor Details'} subtitle={''} list={VENDOR_DETAILS} />
        </Grid>
        <Grid item xs={6} md={6}>
          <CardInvoiceStandart title={'Customer Details'} subtitle={''} list={CUSTOMER_DETAILS} />
        </Grid>
        <Grid item xs={6} md={6}>
          <CardInvoiceInvoiceItems
            title={'Items'}
            subtitle={`${line_items.length} item${line_items.length > 1 ? 's' : ''}`}
            list={line_items}
            currency={currency}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <CardInvoiceTaxes
            title={'Taxes'}
            subtitle={`${taxes.length} tax${taxes.length > 1 ? 'es' : ''}`}
            list={taxes}
            currency={currency}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const CardInvoiceStandart = ({ title, subtitle, list }) => {
  return (
    <CardInvoice title={title} subtitle={subtitle}>
      <div className="Card-Invoice-Standart">
        {list
          .filter(item => item.value)
          .map((item, index) => {
            const value = NAN2DASH(item.value);
            return (
              <div key={index} className="list-item">
                <div className="label">{item.label}</div>
                <div className="value">{value}</div>
                {item.value_extra && <div className="extra-value">{item.value_extra}</div>}
              </div>
            );
          })}
      </div>
    </CardInvoice>
  );
};

const CardInvoiceInvoiceItems = ({ title, subtitle, list, currency }) => {
  return (
    <CardInvoice title={title} subtitle={subtitle}>
      <TableContainer>
        <Table aria-label="Invoice Items">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Total Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{NAN2DASH(row?.item_id)}</TableCell>
                <TableCell component="th" scope="row">
                  {NAN2DASH(row?.description)}
                </TableCell>
                <TableCell>{NAN2DASH(row?.quantity)}</TableCell>
                <TableCell>
                  {NAN2DASH(row?.unit_price)} {currency}
                </TableCell>
                <TableCell>
                  {NAN2DASH(row?.total_price)} {currency}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardInvoice>
  );
};

const CardInvoiceTaxes = ({ title, subtitle, list, currency }) => {
  return (
    <CardInvoice title={title} subtitle={subtitle}>
      <div className="Card-Invoice-Taxes">
        <TableContainer>
          <Table aria-label="Invoice Items">
            <TableHead>
              <TableRow>
                <TableCell>Tax Type</TableCell>
                <TableCell>Tax Rate</TableCell>
                <TableCell>Tax Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{NAN2DASH(row?.tax_type)}</TableCell>
                  <TableCell>{NAN2DASH(row?.tax_rate)}</TableCell>
                  <TableCell>
                    {NAN2DASH(row?.tax_amount)} {currency}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </CardInvoice>
  );
};

const CardInvoice = ({ title, subtitle, children }) => {
  return (
    <div className="Card-Invoice">
      <div className="Card-Invoice-Header">
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle && <Chip color="primary" type="stroke" label={subtitle} dot />}</div>
      </div>
      <div className="Card-Invoice-Content">{children}</div>
    </div>
  );
};
