import { ProtectedLayout } from 'components/layouts/ProtectedLayout';

import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as IconRight } from 'assets/icons/arrow-right-2.svg';

import { ReactComponent as IconSearch } from 'assets/icons/search.svg';
import { ReactComponent as IconClose } from 'assets/icons/close.svg';

import { TableEmpty } from 'components/elements/TableEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { deleteInvoice, getAllHirizeFinList, parseInvoce } from 'features/hirizeFin/hirizeFinAction';
import { ROUTE_PATHS } from 'data/paths';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';
import { DialogConfirm } from 'components/modals/DialogConfirm';
import { DATA_HIRIZE_FIN_LIST_HEAD } from 'data/hirize-fin';
import { useDropzone } from 'react-dropzone';
import { ACCEPT_FILE_FORMATS, MAX_FILE_SIZE } from 'data';
import { ReactComponent as IconUpload } from 'assets/icons/upload.svg';
import { getAccount } from 'features/user/accountAction';
import { resetUploadFileProgress } from 'features/hirizeFin/hirizeFinSlice';
import useTableCheckbox from 'hooks/useTableCheckbox';
import { TableStatus } from 'components/modules/ParserBlock';

export const HirizeFinList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const { list_invoice, invoice_upload_progress, loading } = useSelector(state => state.hirizeFin);

  const onDrop = useCallback(acceptedFiles => {
    handleUploadInvoice(acceptedFiles);
  }, []);
  const [valueTableSearch, setValueTableSearch] = useState('');
  const onChangeValueTableSearch = event => {
    const { value } = event.target;
    setValueTableSearch(value);
    if (value === '') {
      handleGetAllHirizeFinList();
    }
  };

  const [handleClickTableCheckBox, handleSelectAllClick, isSelectedCheckbox, selectedCheckbox, setSelectedCheckbox] =
    useTableCheckbox(list_invoice);

  const [openDeleteRowDialogConfirm, setOpenDeleteRowDialogConfirm] = useState(false);

  const [clicked_row, setClickedRow] = useState(null);

  const {
    getRootProps,
    getInputProps,
    open: openDialog,
  } = useDropzone({
    onDrop,
    noClick: true,
    accept: ACCEPT_FILE_FORMATS,
    maxSize: MAX_FILE_SIZE,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleUploadInvoice = files => {
    dispatch(parseInvoce({ files })).then(() => {
      dispatch(resetUploadFileProgress());
      dispatch(getAccount());
      handleGetAllHirizeFinList();
    });
  };

  const handleGetAllHirizeFinList = search => {
    dispatch(
      getAllHirizeFinList({
        keyword: search ? search : '',
      }),
    );
  };

  const handleGetAllHirizeFinListSearch = () => {
    handleGetAllHirizeFinList(valueTableSearch);
  };

  const handleDeleteSearchValue = () => {
    setValueTableSearch('');
    handleGetAllHirizeFinList('');
  };

  const handlerClickDeleteInvoice = (event, row) => {
    event.stopPropagation();
    setClickedRow(row);
    setOpenDeleteRowDialogConfirm(true);
  };

  const handleClickConfirmButton = () => {
    let ids;
    if (clicked_row) {
      ids = JSON.stringify([clicked_row.id]);
    } else {
      ids = JSON.stringify(selectedCheckbox);
    }
    handlerDeteleInvoice(ids);
    setSelectedCheckbox([]);
    setOpenDeleteRowDialogConfirm(false);
  };

  const handlerDeteleInvoice = ids => {
    dispatch(deleteInvoice({ ids: ids })).then(() => {
      handleGetAllHirizeFinList();
      setClickedRow(null);
    });
  };

  const handlerClickGoInvoiceDetail = (event, id) => {
    event.stopPropagation();
    navigate(ROUTE_PATHS.PRODUCTS_HIRIZE_FIN_DETAIL + id);
  };

  useEffect(() => {
    handleGetAllHirizeFinList();
  }, []);

  return (
    <ProtectedLayout loading={loading}>
      <DialogConfirm
        open={openDeleteRowDialogConfirm}
        onClose={() => {
          setClickedRow(null);
          setOpenDeleteRowDialogConfirm(false);
        }}
        title="Are you sure?"
        desc={`You are about to delete "${
          clicked_row ? clicked_row?.file_name : `${selectedCheckbox.length} files`
        }". </br> </br> This action cannot be undone. `}
        TextConfirmButton="Delete"
        ClickConfirmButton={handleClickConfirmButton}
        type="warning"
      />
      <div className="Page Page-HirizeFinList">
        <div className="HirizeFinList">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="title">Hirize Fin</div>
              <div className="desc">Parses invoices, receipts and financial documents efficiently</div>
            </div>
          </div>
          <div className="HirizeFinList-List">
            <div className="HirizeFinList-Table-Header">
              <div className="search-invoice">
                <div className="search-input">
                  <TextField
                    placeholder={'Search'}
                    variant="outlined"
                    value={valueTableSearch}
                    onChange={onChangeValueTableSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconSearch />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {valueTableSearch ? (
                    <IconButton
                      className="delete-search"
                      color="transparent"
                      size="small"
                      onClick={handleDeleteSearchValue}
                    >
                      <IconClose />
                    </IconButton>
                  ) : (
                    ''
                  )}
                </div>
                <IconButton color="white" onClick={handleGetAllHirizeFinListSearch}>
                  <IconSearch />
                </IconButton>
              </div>
              <div className="upload-files">
                <Button startIcon={<IconUpload />} variant="contained" onClick={openDialog}>
                  Upload
                </Button>
              </div>
            </div>
            <div className="HirizeFinList-Table">
              <TableContainer {...getRootProps()}>
                <input {...getInputProps()} />
                <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-head-cell-checkbox" padding="none">
                        <Checkbox
                          color="primary"
                          indeterminate={selectedCheckbox?.length > 0 && selectedCheckbox?.length < list_invoice.length}
                          checked={list_invoice?.length > 0 && selectedCheckbox?.length === list_invoice?.length}
                          onChange={handleSelectAllClick}
                          inputProps={{
                            'aria-label': 'select all',
                          }}
                        />
                      </TableCell>

                      {selectedCheckbox?.length > 0 ? (
                        <>
                          <TableCell>
                            <div className="table-head-selected">
                              <div className="selected-info">
                                {selectedCheckbox?.length} document{selectedCheckbox?.length > 1 ? 's' : ''} selected
                              </div>
                              <div className="selected-actions">
                                <IconButton size="small" color="red-outlined" onClick={handlerClickDeleteInvoice}>
                                  <IconTrash />
                                </IconButton>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </>
                      ) : (
                        DATA_HIRIZE_FIN_LIST_HEAD.map(headCell => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.align || 'left'}
                            padding={'none'}
                            sx={{ width: headCell.width }}
                          >
                            {headCell.label}
                          </TableCell>
                        ))
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...invoice_upload_progress, ...(list_invoice || [])]?.map((row, index) => {
                      const isItemSelected = isSelectedCheckbox(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={event => handleClickTableCheckBox(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          <TableCell className="table-row-cell-checkbox" padding="none">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell className="bold" component="th" scope="row" padding="none">
                            <Link to={ROUTE_PATHS.PRODUCTS_HIRIZE_FIN_DETAIL + row.id}>
                              {row.file_name ? row.file_name : row.name}
                            </Link>
                          </TableCell>

                          <TableCell padding="none">
                            {/*   <Date date={row.created_at} /> */}
                            <TableStatus row={row} />
                          </TableCell>

                          <TableCell align="right" padding="none" className="actions">
                            <IconButton
                              size="small"
                              color="red-outlined"
                              onClick={event => handlerClickDeleteInvoice(event, row)}
                            >
                              <IconTrash />
                            </IconButton>
                            <IconButton
                              size="small"
                              color="white"
                              onClick={event => handlerClickGoInvoiceDetail(event, row.id)}
                            >
                              <IconRight />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {(list_invoice?.list?.length === 0 || !list_invoice) && (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={12}>
                          <TableEmpty
                            text={'You have no files uploaded yet'}
                            desc={'PDF, DOCX, PNG, JPEG, JPG, WEBP (max 2 MB)'}
                          ></TableEmpty>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                {list_invoice?.total_count > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={list_invoice?.total_count}
                    rowsPerPage={list_invoice?.per_page}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    SelectProps={{ className: 'table-select-rows-per-page' }}
                  />
                )}
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};
