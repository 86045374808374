import { format as _format, parseISO } from 'date-fns';
import { memo } from 'react';

const Date = ({ date, format = 'PP' }) => {
  if (!date) return null;
  if (date === 'today') {
    return `${_format(new window.Date(), format)}`;
  } else {
    return `${_format(parseISO(date), format)}`;
  }
};

export default memo(Date);
