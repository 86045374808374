import { ROUTE_PATHS } from 'data/paths';

export const DATA_INVOICES_LIST_HEAD = [
  {
    id: 'paid_at',
    label: 'DATE',
  },
  {
    id: 'total',
    label: 'AMOUNT',
  },

  {
    id: 'status',
    label: 'PAYMENT STATUS',
  },
  {
    id: 'action',
    label: 'ACTION',
  },
];

export const NAV_SETTIGNS_TAB = [
  {
    id: 'company-account',
    path: ROUTE_PATHS.SETTINGS_COMPANY_ACCOUNT,
    label: 'Company Account',
  },
  {
    id: 'access-management',
    path: ROUTE_PATHS.SETTINGS_ACCESS_MANAGEMENT,
    label: 'Access Management',
  },
  {
    id: 'billing-invoices',
    path: ROUTE_PATHS.SETTINGS_BILLING_INVOICES,
    label: 'Billing & Invoices',
  },
];
