import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as IconUpload2 } from 'assets/icons/upload-2.svg';
import { ReactComponent as IconFileUpload } from 'assets/icons/file-upload.svg';
import { ReactComponent as IconClose } from 'assets/icons/close.svg';

import { ACCEPT_FILE_FORMATS, MAX_FILE_SIZE } from 'data';
import { IconButton } from '@mui/material';
import { fileSizeConverter } from 'utils';
export const DropzoneStandart = ({
  onDropChange,
  accept = ACCEPT_FILE_FORMATS,
  maxSize = MAX_FILE_SIZE,
  showIcon = true,
  text,
  file,
  multiple = false,
  deleteFile,
}) => {
  const onDrop = useCallback(acceptedFiles => {
    onDropChange(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    accept: accept,
    maxSize: maxSize,
  });
  return (
    <div {...getRootProps({ className: `DropzoneStandart ${file ? 'has-file' : ''}` })}>
      <input {...getInputProps()} />
      {file ? (
        <div className="dropdoze-uploaded-file">
          <div className="icon">
            <IconFileUpload />
          </div>
          <div className="file-detail">
            <div className="file-name">{file.file_name || file.name}</div>
            <div className="file-size">{fileSizeConverter(file.size)}</div>
          </div>
          <div className="file-delete">
            <IconButton color="white" onClick={deleteFile} size="small">
              <IconClose />
            </IconButton>
          </div>
        </div>
      ) : (
        <div className="dropzone-front">
          {showIcon ? (
            <div className="icon">
              <IconUpload2 />
            </div>
          ) : null}
          <div className="text-1">
            <strong>Click to upload</strong> or drag and drop
          </div>
          <div className="text-2">{text}</div>
        </div>
      )}
    </div>
  );
};
