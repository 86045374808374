import { ProtectedLayout } from 'components/layouts/ProtectedLayout';
import { JsonViewer } from '@textea/json-viewer';
import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router-dom';
import Buttons from 'components/elements/buttons';
import TabPanel from 'components/elements/TabPanel';
import { ReactComponent as IconDownload } from 'assets/icons/download.svg';

import { useDispatch, useSelector } from 'react-redux';

import { GeneratePdf } from 'utils/pdfExport';
import { ROUTE_PATHS } from 'data/paths';
import { InvoiceParserDetail } from 'components/InvoiceParserDetail';
import { getInvoiceDetail } from 'features/hirizeFin/hirizeFinAction';

export const HirizeFinDetail = () => {
  const { invoice_data } = useSelector(state => state.hirizeFin);
  const dispatch = useDispatch();
  let { id } = useParams();
  const [tabValue, setTabValue] = useState('overview');

  const handleChangeTab = (event, value) => {
    setTabValue(value);
  };

  useEffect(() => {
    dispatch(getInvoiceDetail({ id: id }));
  }, [id]);

  const refContent = useRef();

  /*  const DownloadResumeDoc = async () => {
    let doc;
    if (account_data?.company?.id === 241) {
      doc = await DOC_CA_MINING(parser_detail_data);
    } else {
      doc = await DOC_HIRIZE(parser_detail_data);
    }
    Packer.toBlob(await doc).then(blob => {
      saveAs(blob, `${id}.docx`);
    });
  }; */
  return (
    <ProtectedLayout>
      <div className="Page Page-ParserDetail">
        <div className="ParserDetail">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="top-action">
                <Buttons.HistoryBack text={'Back to Invoice List'} to={ROUTE_PATHS.PRODUCTS_HIRIZE_FIN_LIST} />
              </div>
            </div>
          </div>
          <div className="ParserDetail-Content">
            <div className="ParserDetail-Tabs">
              <Tabs className={'Tabs-Standart Tabs-ParserDetail'} value={tabValue} onChange={handleChangeTab}>
                <Tab label="Overview" value={'overview'} />
                <Tab label="JSON" value={'json'} />
              </Tabs>
              <div className="action">
                {tabValue === 'overview' ? (
                  <>
                    <IconButton color="white" onClick={() => GeneratePdf(refContent, id)}>
                      <IconDownload />
                    </IconButton>
                    {/*   <IconButton color="white" onClick={DownloadResumeDoc}>
                      <IconDoc />
                    </IconButton> */}
                  </>
                ) : null}
              </div>
            </div>
            <div className="ParserDetail-Page">
              <TabPanel value={tabValue} index={'overview'}>
                {invoice_data && <InvoiceParserDetail myRef={refContent} data={invoice_data} />}
              </TabPanel>
              <TabPanel value={tabValue} index={'json'}>
                <div className="json-viewer">{invoice_data && <JsonViewer value={invoice_data} />}</div>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};
