import { ProtectedLayout } from 'components/layouts/ProtectedLayout';

import React, { useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as IconPlus2 } from 'assets/icons/plus-2.svg';
import { ReactComponent as IconRight } from 'assets/icons/arrow-right-2.svg';

import { DATA_MATCHER_LIST_HEAD } from 'data/matcher';
import { ReactComponent as IconSearch } from 'assets/icons/search.svg';

import { TableEmpty } from 'components/elements/TableEmpty';
import { CreateMatcher as ModalCreateMatcher } from 'components/modals/CreateMatcher';
import { ACCEPT_FILE_FORMATS_JOB, MAX_FILE_SIZE_JOB } from 'data';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMatcher, getAllMatcherList, getAllSeniorityList } from 'features/matcher/matcherAction';
import Date from 'components/elements/Date';
import { TableHeadSortLabel } from 'components/elements/TableSortLabel';
import useOrderTable from 'hooks/useOrderTable';
import { ROUTE_PATHS } from 'data/paths';
import { Chip } from 'components/elements/Chip';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';
import { DialogConfirm } from 'components/modals/DialogConfirm';

export const MatcherList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const IS_MEDIMIND = location.pathname.includes('medimind-list');

  const [page, setPage] = useState(1);

  const { list_matcher, success_matcher_delete } = useSelector(state => state.matcher);

  const [order, orderBy, createSortHandler] = useOrderTable('desc', 'date');

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const [openModalCreateMatcher, setModalCreateMatcher] = useState(false);
  const handleClickCreateMatcher = () => {};

  const handleGetAllSeniorityList = () => {
    dispatch(getAllSeniorityList());
  };

  const handleGetAllMatcherList = (_page, _order, _orderBy) => {
    dispatch(
      getAllMatcherList({
        page: _page || page,
        order: _order || order,
        orderBy: _orderBy || orderBy,
        matcher_type: IS_MEDIMIND ? 'MEDI_MIND' : null,
      }),
    );
  };

  useEffect(() => {
    handleGetAllMatcherList(page, order, orderBy);
  }, [page, order, orderBy]);

  useEffect(() => {
    handleGetAllSeniorityList();
  }, []);

  const [valueTableSearch, setValueTableSearch] = useState('');

  const onChangeValueTableSearch = event => {
    const { value } = event.target;
    setValueTableSearch(value);
  };

  const filtered_list_matcher = list_matcher?.list?.filter(row => {
    return row.name.toLowerCase().includes(valueTableSearch.toLowerCase());
  });

  const handlerClickGoMatcherDetail = (event, id) => {
    event.stopPropagation();
    navigate(IS_MEDIMIND ? ROUTE_PATHS.PRODUCTS_MEDIMIND_DETAIL + id : ROUTE_PATHS.PRODUCTS_MATCHER_DETAIL + id);
  };

  const handlerClickDeleteMatcher = (event, row) => {
    event.stopPropagation();
    setOpenDeleteRowDialogConfirm(true);
    setSelectedMatcherForDelete(row);
  };

  const handlerDeleteMatcher = () => {
    dispatch(deleteMatcher({ id: selectedMatcherForDelete.id }));
    setOpenDeleteRowDialogConfirm(false);
  };

  useEffect(() => {
    if (success_matcher_delete !== null) {
      handleGetAllMatcherList();
    }
  }, [success_matcher_delete]);

  const [openDeleteRowDialogConfirm, setOpenDeleteRowDialogConfirm] = useState(false);
  const [selectedMatcherForDelete, setSelectedMatcherForDelete] = useState(null);

  return (
    <ProtectedLayout>
      <ModalCreateMatcher
        onClose={() => setModalCreateMatcher(false)}
        open={openModalCreateMatcher}
        clickConfirm={handleClickCreateMatcher}
        dropzoneText={'SVG, PNG, JPG or GIF (max. 800x400px)'}
        accept={ACCEPT_FILE_FORMATS_JOB}
        maxSize={MAX_FILE_SIZE_JOB}
        updateMatcherList={handleGetAllMatcherList}
      />

      <DialogConfirm
        open={openDeleteRowDialogConfirm}
        onClose={() => {
          setOpenDeleteRowDialogConfirm(false);
          setSelectedMatcherForDelete(null);
        }}
        title="Are you sure?"
        desc={`You are about to delete "<b>${
          selectedMatcherForDelete?.name || ''
        }</b>". </br> </br> This action cannot be undone. `}
        TextConfirmButton="Delete"
        ClickConfirmButton={handlerDeleteMatcher}
        type="warning"
      />

      <div className="Page Page-MatcherList">
        <div className="MatcherList">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="title">{IS_MEDIMIND ? 'MediMind' : 'AI Matcher'}</div>
              <div className="desc">
                {IS_MEDIMIND
                  ? 'Utilize advanced algorithms to pre-screen candidates within the healthcare sector.'
                  : 'Match with the power of AI and customize your search'}
              </div>
            </div>
          </div>
          <div className="MatcherList-List">
            <div className="MatcherList-Table-Header">
              <div className="search-matcher">
                <TextField
                  placeholder={'Search'}
                  variant="outlined"
                  value={valueTableSearch}
                  onChange={onChangeValueTableSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconSearch />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="action-add-matcher">
                <Button
                  startIcon={<IconPlus2 />}
                  variant="contained"
                  color="primary"
                  onClick={() => setModalCreateMatcher(true)}
                >
                  Start Matching
                </Button>
              </div>
            </div>
            <div className="MatcherList-Table">
              <TableContainer>
                <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow>
                      {DATA_MATCHER_LIST_HEAD.map(headCell => (
                        <TableCell
                          key={headCell.id}
                          align={'left'}
                          padding={'none'}
                          sx={{ width: headCell.width }}
                          sortDirection={orderBy === headCell.id ? order : false}
                        >
                          {headCell.id === 'action' || headCell.id === 'name' ? (
                            IS_MEDIMIND ? (
                              headCell.label_medimind
                            ) : (
                              headCell.label
                            )
                          ) : (
                            <TableHeadSortLabel
                              orderBy={orderBy}
                              headCell={headCell}
                              order={order}
                              onClick={createSortHandler(headCell.id)}
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filtered_list_matcher &&
                      filtered_list_matcher.map((row, index) => {
                        return (
                          <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell className="bold" component="th" scope="row" padding="none">
                              <Link
                                to={
                                  IS_MEDIMIND
                                    ? ROUTE_PATHS.PRODUCTS_MEDIMIND_DETAIL + row.id
                                    : ROUTE_PATHS.PRODUCTS_MATCHER_DETAIL + row.id
                                }
                              >
                                {row.name}
                              </Link>
                            </TableCell>
                            <TableCell className="bold" component="th" scope="row" padding="none">
                              <Chip color="primary" type="stroke" label={row.log_count} dot />
                            </TableCell>

                            <TableCell padding="none">
                              <Date date={row.created_at} />
                            </TableCell>

                            <TableCell padding="none" className="actions">
                              <IconButton
                                size="small"
                                color="red-outlined"
                                onClick={event => handlerClickDeleteMatcher(event, row)}
                              >
                                <IconTrash />
                              </IconButton>
                              <IconButton
                                size="small"
                                color="white"
                                onClick={event => handlerClickGoMatcherDetail(event, row.id)}
                              >
                                <IconRight />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  {(list_matcher?.list?.length === 0 || !list_matcher) && (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={12}>
                          <TableEmpty
                            text={'Let’s get you started add matchers to this list '}
                            desc={'Hirize will help you create match criteria,  and save them  lets get you started   '}
                          >
                            <Button
                              startIcon={<IconPlus2 />}
                              onClick={() => setModalCreateMatcher(true)}
                              variant="contained"
                              color="primary"
                            >
                              Start Matching
                            </Button>
                          </TableEmpty>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                {list_matcher?.total_count > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={list_matcher?.total_count}
                    rowsPerPage={list_matcher?.per_page}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    SelectProps={{ className: 'table-select-rows-per-page' }}
                  />
                )}
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};
