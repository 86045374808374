import { createSlice } from '@reduxjs/toolkit';
import { getDashboard } from './dashboardAction';

const initialState = {
  success: null,
  message: null,
  dashboard: null,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // get all product
    builder.addCase(getDashboard.pending, state => {
      state.success = null;
    });
    builder.addCase(getDashboard.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.dashboard = payload.data;
    });
    builder.addCase(getDashboard.rejected, (state, { payload }) => {
      state.success = payload.success;
    });
  },
});

export default dashboardSlice.reducer;
