import { Button, Dialog } from '@mui/material';
import React, { useEffect } from 'react';
import { ReactComponent as IconTag } from 'assets/icons/tag-2.svg';
import { AutoCompletedTagList } from 'components/elements/AutoCompletedTagList';

export const AddTagsFiles = props => {
  const {
    open,
    onClose,
    selectedTagList = [],
    selectedTagsNewParser,
    updateselectedTagsNewParser,
    tagList,
    onClickConfirm,
  } = props;
  useEffect(() => {
    updateselectedTagsNewParser([]);
  }, [open]);
  return (
    <Dialog className="Modal-Container Modal-AddTagsFiles" maxWidth={'sm'} onClose={onClose} open={open}>
      <div className="Modal-Box">
        <div className="Modal-Header">
          <div className="icon">
            <IconTag />
          </div>
          <div className="title">Add Tags to Files</div>
          <div className="desc">Tags help you organize files and create bulk actions</div>
        </div>
        <div className="Modal-Content">
          <div className="Autocomplete-OpenedStandart-Container">
            <AutoCompletedTagList
              tagList={tagList}
              selectedTagList={selectedTagList}
              onChange={updateselectedTagsNewParser}
              value={selectedTagsNewParser}
              type={'new'}
            />
          </div>
        </div>
        <div className="Modal-Footer">
          <Button
            onClick={() => {
              updateselectedTagsNewParser([]);
              onClickConfirm('skip');
              onClose();
            }}
            variant="outlined"
            color="white"
            fullWidth
            size="large"
          >
            Skip
          </Button>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={() => {
              onClickConfirm('normal');
              onClose();
            }}
          >
            Add Tags
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
