import { Chip as MuiChip } from '@mui/material';
import React from 'react';

import { ReactComponent as IconBadgeRemove } from 'assets/icons-v2/badge-remove.svg';
import { DATA_PRODUCTS } from 'data/products';

export const Chip = ({
  type = 'solid',
  color = 'primary',
  size = 'small',
  dot = false,
  iconType = 'default',
  ...props
}) => {
  const classList = `Color-${color} Type-${type} ${dot ? 'has-dot' : ''} icon-type-${iconType}`;

  return <MuiChip deleteIcon={<IconBadgeRemove />} className={classList} {...props} size={size} />;
};

export const ChipProduct = ({ product_id, product_name }) => {
  return (
    <Chip
      iconType="product"
      icon={DATA_PRODUCTS[product_id]['icon_rounded']}
      size="small"
      color="primary"
      type="stroke"
      label={product_name}
    />
  );
};

export const ChipAutoComplete = {
  className: 'Color-primary Type-solid',
  size: 'xsmall',
  deleteIcon: <IconBadgeRemove />,
};
