import { Box, Button, Divider, FormControl, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { PublicLayout } from 'components/layouts/PublicLayout';
import { Controller, useForm } from 'react-hook-form';
import { FORM_TEXT } from 'data';

import { ReactComponent as IconGoogle } from 'assets/icons/socialmedia/google.svg';
import { ReactComponent as IconOffice } from 'assets/icons/socialmedia/office.svg';
import { ReactComponent as IconLinkedin } from 'assets/icons/socialmedia/linkedin.svg';
import { ReactComponent as IconMail } from 'assets/icons/socialmedia/mail.svg';

import { Link } from 'react-router-dom';
import { PaternEmail, validateBusinessEmail } from 'utils/formPatterns';
import Buttons from 'components/elements/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from 'features/user/userLoginAction';
import { InputFilledPassword } from 'components/elements/InputFilledPassword';
import { ROUTE_PATHS } from 'data/paths';

import { LoadingButton } from '@mui/lab';

export const Login = () => {
  const [loginType, changeLoginType] = useState('email');
  const handleOnClickBack = () => {
    if (loginType === 'social') {
      window.open('https://hirize.hr/', '_blank');
    } else {
      /* changeLoginType('social'); */
      window.open('https://hirize.hr/', '_blank');
    }
  };
  return (
    <PublicLayout>
      <div className="Page Page-Login">
        <div className="Login">
          <div className="Login-Header">
            <Buttons.AuthBack
              text={loginType === 'email' ? 'Back to Hirize' : 'Back to Login'}
              onClick={handleOnClickBack}
            />
          </div>
          {loginType === 'social' && <LoginWithSocial changeLoginType={changeLoginType} />}

          {loginType === 'email' && <LoginForm />}
          <div className="Login-Footer">
            <div className="redirect-login">
              Need a Hirize account? <Link to={ROUTE_PATHS.REGISTER}>Sign Up</Link>
            </div>
          </div>
        </div>
      </div>
    </PublicLayout>
  );
};

const LoginForm = () => {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const SendLoginForm = data => {
    dispatch(userLogin(data));
  };

  const { loading } = useSelector(state => state.userLogin);

  return (
    <div className="Login-Form">
      <div className="Heading">
        <div className="title">Log In With E-Mail</div>
        <div className="desc">Start hiring with Hirize</div>
      </div>
      <Box component="form" onSubmit={handleSubmit(data => SendLoginForm(data))} autoComplete="off">
        <Grid container rowSpacing={{ xs: '20px', sm: '24px' }} columnSpacing={{ xs: '0', sm: '16px' }}>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth size={'small'}>
              <Controller
                render={({ field }) => (
                  <TextField
                    error={!!errors?.['email']?.['message']}
                    helperText={errors?.['email']?.['message']}
                    label="Work e-mail*"
                    variant="filled"
                    {...field}
                  />
                )}
                name="email"
                control={control}
                rules={{ required: FORM_TEXT.required, pattern: PaternEmail, validate: validateBusinessEmail }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputFilledPassword control={control} errors={errors} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid className="form-action-forgotPassword" item xs={12}>
            <Button component={Link} to={ROUTE_PATHS.FORGOT_PASSWORD_EMAIL} variant="outlined" color="white">
              Forgot Password?
            </Button>
          </Grid>

          <Grid className="form-action-send" item xs={12}>
            <LoadingButton
              onClick={handleSubmit(SendLoginForm)}
              fullWidth
              variant="contained"
              size="large"
              loading={loading}
            >
              Login to Hirize
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

const LoginWithSocial = ({ changeLoginType }) => {
  return (
    <div className="Login-Form-Social">
      <div className="Heading">
        <div className="title">Welcome Back!</div>
        <div className="desc">Start hiring with Hirize</div>
      </div>
      <div className="Social-Login">
        <Button fullWidth className="button-social-login" startIcon={<IconGoogle />}>
          Continue with Google
        </Button>
        <Button fullWidth className="button-social-login" startIcon={<IconOffice />}>
          Continue with Office
        </Button>
        <Button fullWidth className="button-social-login" startIcon={<IconLinkedin />}>
          Continue with Linkedin
        </Button>
      </div>
      <Divider className="divider">OR</Divider>
      <Button
        onClick={() => changeLoginType('email')}
        fullWidth
        className="button-type-auth"
        variant="contained"
        size="large"
        startIcon={<IconMail />}
      >
        Continue with E-mail
      </Button>
    </div>
  );
};
