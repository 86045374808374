import { Box, Button, Grid } from '@mui/material';
import { createdInvitedMemberPassword, invitedMember } from 'features/userMember/userMemberAction';
import { updateInvitedMemberDetailPassword } from 'features/userMember/userMemberSlice';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { InputFilledPassword } from 'components/elements/InputFilledPassword';
import { logout } from 'features/user/userLoginSlice';
import { resetProfileState } from 'features/profile/profileSlice';
import { resetAccountState } from 'features/user/accountSlice';

export const CreatePasswordNewPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [params] = useSearchParams();
  const invite_code = params.get('invite_code');

  const { invited_member_detail } = useSelector(state => state.userMember);
  const { isLoading } = useSelector(state => state.ui);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      password: '',
    },
  });
  const SendCreateProfile = data => {
    dispatch(createdInvitedMemberPassword({ password: data.password, code: invite_code })).then(result => {
      if (result.payload.success) {
        dispatch(updateInvitedMemberDetailPassword({ password: data.password }));
        navigate('/create-password/result');
      }
    });
  };

  useEffect(() => {
    dispatch(logout());
    dispatch(resetProfileState());
    dispatch(resetAccountState());
    dispatch(invitedMember({ invite_code }));
  }, []);

  return (
    <div className="CreatePassword-NewPassword">
      <div className="heading">
        <div className="icon">
          <img src="/images/logo-tiny-2.svg" alt="" />
        </div>
        <div className="title">
          Hi {invited_member_detail?.name} {invited_member_detail?.surname}, <br /> You’ve been invited to Hirize
        </div>
      </div>
      <Box component="form" onSubmit={handleSubmit(data => SendCreateProfile(data))} autoComplete="off">
        <Grid container rowSpacing={{ xs: '0', sm: '44px' }}>
          <Grid item xs={12} sm={12}>
            <div className="input-label">Create a password to get started.</div>
            <InputFilledPassword control={control} errors={errors} name={'password'} />
          </Grid>
          <Grid className="form-action-send" item xs={12}>
            <Button
              onClick={handleSubmit(SendCreateProfile)}
              fullWidth
              className="button-type-auth"
              variant="contained"
              size="large"
              disabled={isLoading}
            >
              Create profile
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
