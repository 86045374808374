import { Avatar, Button, IconButton, MenuItem, Select } from '@mui/material';
import { ReactComponent as IconArrowDown } from 'assets/icons/arrow-down.svg';
import React, { useState } from 'react';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';
import { ReactComponent as IconPencil } from 'assets/icons/pencil.svg';
import { InviteTeamMember as ModalInviteTeamMember } from 'components/modals/InviteTeamMember';
import { stringAvatar } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  getPendingInviteList,
  getAllCompanyMember,
  getAuthorityList,
  getPermissionTemplate,
  deleteCompanyMember,
  updateCompanyMember,
} from 'features/companyMember/companyMemberAction';
import { Chip } from 'components/elements/Chip';

export const AccessManagement = () => {
  const dispatch = useDispatch();
  const [openModalInviteTeamMember, setOpenModalInviteTeamMember] = useState(false);
  const { allCompanyMembers, pendingInviteList, authorityList } = useSelector(state => state.companyMember);

  const [selectedUser, updateSelectedUser] = useState(null);

  const handleDeleteUser = (listType, id) => {
    dispatch(deleteCompanyMember(id));
  };

  const handleOnClickEditProfile = user => {
    updateSelectedUser(user);
    setOpenModalInviteTeamMember(true);
  };

  useEffect(() => {
    dispatch(getAuthorityList());
    dispatch(getPermissionTemplate());
    dispatch(getAllCompanyMember());
    dispatch(getPendingInviteList());
  }, []);

  return (
    <>
      <ModalInviteTeamMember
        data={selectedUser}
        open={openModalInviteTeamMember}
        onClose={() => setOpenModalInviteTeamMember(false)}
      />
      <div className="Page-AccessManagement">
        <div className="AccessManagement">
          <div className="White-Box AccessManagement-Form">
            <div className="active-user-list">
              <div className="box-heading">
                <div className="heading-start">
                  <div className="title">Users</div>
                </div>
                <div className="heading-end">
                  <Button
                    variant="contained"
                    onClick={() => {
                      updateSelectedUser(null);

                      setOpenModalInviteTeamMember(true);
                    }}
                  >
                    Invite Team Member
                  </Button>
                </div>
              </div>
              {allCompanyMembers?.length === 0 ? (
                ''
              ) : (
                <div className="user-list">
                  {allCompanyMembers?.map((item, index) => {
                    return (
                      <div key={index} className="user-item">
                        <CardUser
                          authorityList={authorityList}
                          deleteUser={handleDeleteUser}
                          onClickEditProfile={handleOnClickEditProfile}
                          listType={'active'}
                          data={item}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="pending-user-list">
              {pendingInviteList?.length === 0 ? null : (
                <>
                  <div className="box-heading">
                    <div className="heading-start">
                      <div className="title">Pending Invites</div>
                    </div>
                  </div>
                  <div className="user-list">
                    {pendingInviteList?.map((item, index) => {
                      return (
                        <div key={index} className="user-item">
                          <CardUser
                            authorityList={authorityList}
                            deleteUser={handleDeleteUser}
                            listType={'pending'}
                            data={item}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CardUser = ({ data, authorityList, listType, deleteUser, onClickEditProfile }) => {
  const dispatch = useDispatch();

  const { name, surname, email, profile_picture, id, authority } = data;
  const changeUserRoleType = event => {
    const { value } = event.target;
    dispatch(
      updateCompanyMember({
        postData: {
          name,
          surname,
          email,
          authority,
          id,
        },
        value,
      }),
    );
  };
  return (
    <div className="Card-User">
      <div className="card-left">
        <div className="card-profile">
          <div className="profile-avatar">
            <Avatar
              sx={{ width: 52, height: 52 }}
              alt={name}
              src={listType === 'active' ? profile_picture : ''}
              children={(listType === 'pending' || !!profile_picture) && stringAvatar(`${name} ${surname}`)}
            />
          </div>
          <div className="profile-info">
            <div className="profile-name">
              {name} {surname}
              {listType === 'pending' ? <Chip size="small" color="yellow" label="Pending" /> : ''}
            </div>
            <div className="profile-email">{email}</div>
          </div>
        </div>
      </div>
      <div className="card-right">
        <div className="actions">
          <Select
            className="Select-Standart"
            IconComponent={IconArrowDown}
            value={String(authority.id) || '1'}
            onChange={event => changeUserRoleType(event)}
            MenuProps={{
              disableScrollLock: true,
              className: 'Select-Standart-Menu',
            }}
          >
            {authorityList?.map(item => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>

          {listType === 'active' ? (
            <IconButton color="white" size="small" onClick={() => onClickEditProfile(data)}>
              <IconPencil />
            </IconButton>
          ) : null}
          <IconButton color="red-outlined" size="small" onClick={() => deleteUser(listType, id)}>
            <IconTrash />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
