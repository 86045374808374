import { createSlice } from '@reduxjs/toolkit';
import { snackActions } from 'libs/snackbar';
import {
  addTagResume,
  changeFileName,
  createCompanyParserTags,
  getAllCompanyParserTags,
  getAllParserList,
  getCompanyParserConfig,
  getResumeDetail,
  makeParserRequest,
  removeResume,
  removeTagResume,
  updateCompanyParserConfig,
} from './parserAction';

const initialState = {
  success: null,
  message: null,
  parser_detail_data: null,
  resume_list: null,
  company_tags: [],
  page_number: 0,
  success_resume_list: null,
  parser_upload_progress: [],
  is_done_parser_upload_progess: false,
  parser_config: null,
  clicked_resume: null,
  loading: false,
};

export const parserSlice = createSlice({
  name: 'parser',
  initialState,
  reducers: {
    resetState: () => initialState,
    updatePageNumber: (state, { payload }) => {
      const { page_number } = payload;
      state.page_number = page_number;
    },
    updateUploadFileProgress: (state, { payload }) => {
      const { files } = payload;

      state.parser_upload_progress = files.map(item => {
        return {
          ...item,
          status: 'loading',
          file_name: item.name,
        };
      });
    },
    changeUploadFileProgressStatus: (state, { payload }) => {
      const { index, status } = payload;
      state.parser_upload_progress[index]['status'] = status;
    },
    resetUploadFileProgress: (state, { payload }) => {
      state.parser_upload_progress = [];
      state.success_resume_list = null;
    },
    updateClickedResume: (state, { payload }) => {
      state.clicked_resume = payload;
    },
  },
  extraReducers: builder => {
    // getAllParserList
    builder.addCase(getAllParserList.pending, state => {
      state.success_resume_list = null;
    });
    builder.addCase(getAllParserList.fulfilled, (state, { payload }) => {
      state.success_resume_list = payload.success;
      state.resume_list = payload.data;
    });
    builder.addCase(getAllParserList.rejected, (state, { payload }) => {
      state.success_resume_list = false;
      state.message = payload;
    });

    // makeParserRequest
    builder.addCase(makeParserRequest.pending, (state, { payload }) => {
      state.success = null;
      state.loading = true;
    });
    builder.addCase(makeParserRequest.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.loading = false;
    });
    builder.addCase(makeParserRequest.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
      state.loading = false;
    });

    // removeResume
    builder.addCase(removeResume.pending, state => {
      state.success = null;
    });
    builder.addCase(removeResume.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      snackActions.success('File deleted successfully!');
    });
    builder.addCase(removeResume.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // getResumeDetail
    builder.addCase(getResumeDetail.pending, state => {
      state.success = null;
    });
    builder.addCase(getResumeDetail.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.parser_detail_data = payload.data;
    });
    builder.addCase(getResumeDetail.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // createCompanyParserTags
    builder.addCase(createCompanyParserTags.pending, state => {
      state.success = null;
    });
    builder.addCase(createCompanyParserTags.fulfilled, (state, { payload }) => {
      state.success = payload.success;
    });
    builder.addCase(createCompanyParserTags.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // getAllCompanyParserTags
    builder.addCase(getAllCompanyParserTags.pending, state => {
      state.success = null;
    });
    builder.addCase(getAllCompanyParserTags.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.company_tags = payload.data.reverse(); //JSON.parse(payload.data);
    });
    builder.addCase(getAllCompanyParserTags.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // removeTagResume
    builder.addCase(removeTagResume.pending, state => {
      state.success = null;
    });
    builder.addCase(removeTagResume.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      snackActions.success('Tag deleted successfully!');
    });
    builder.addCase(removeTagResume.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // addTagResume
    builder.addCase(addTagResume.pending, state => {
      state.success = null;
    });
    builder.addCase(addTagResume.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      snackActions.success('Tag add successfully!');
    });
    builder.addCase(addTagResume.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // getCompanyParserConfig
    builder.addCase(getCompanyParserConfig.pending, state => {
      state.success = null;
    });
    builder.addCase(getCompanyParserConfig.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.parser_config = payload.data;
    });
    builder.addCase(getCompanyParserConfig.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // updateCompanyParserConfig
    builder.addCase(updateCompanyParserConfig.pending, state => {
      state.success = null;
    });
    builder.addCase(updateCompanyParserConfig.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      snackActions.success('Parser settings updated successfully!');
    });
    builder.addCase(updateCompanyParserConfig.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });

    // changeFileName
    builder.addCase(changeFileName.pending, state => {
      state.success = null;
    });
    builder.addCase(changeFileName.fulfilled, (state, { payload }) => {
      state.success = payload.success;
    });
    builder.addCase(changeFileName.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
    });
  },
});

export const {
  resetState,
  updatePageNumber,
  updateUploadFileProgress,
  changeUploadFileProgressStatus,
  resetUploadFileProgress,
  updateClickedResume,
} = parserSlice.actions;

export default parserSlice.reducer;
