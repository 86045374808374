import { addMinutes, differenceInDays, endOfYear, startOfYear, subMinutes, subYears } from 'date-fns';
import { createStaticRanges, defaultStaticRanges } from 'react-date-range';
import { crateNewDate } from 'utils';

const newDate = crateNewDate();

export const allStaticRanges = [
  ...defaultStaticRanges,
  ...createStaticRanges([
    {
      label: 'This Year',
      range: () => ({
        startDate: startOfYear(newDate),
        endDate: newDate,
      }),
    },
    {
      label: 'Last Year',
      range: () => ({
        startDate: startOfYear(subYears(newDate, 1)),
        endDate: endOfYear(subYears(newDate, 1)),
      }),
    },
  ]),
];

export const DISPLAY_TYPE_LIST = {
  DAILY: 'DAILY',
  HOURLY: 'HOURLY',
  MONTHY: 'MONTHY',
  WEEKLY: 'WEEKLY',
};

export const DATE_RANGE_LABEL = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  THIS_WEEK: 'This Week',
  LAST_WEEK: 'Last Week',
  THIS_MONTH: 'This Month',
  LAST_MONTH: 'Last Month',
  THIS_YEAR: 'This Year',
  LAST_YEAR: 'Last Year',
};

export const getRangeType = (label, value) => {
  const diffDays = differenceInDays(value.endDate, value.startDate);
  if (label === DATE_RANGE_LABEL.TODAY || label === DATE_RANGE_LABEL.YESTERDAY) {
    return DISPLAY_TYPE_LIST.HOURLY;
  } else if (
    label === DATE_RANGE_LABEL.THIS_WEEK ||
    label === DATE_RANGE_LABEL.LAST_WEEK ||
    label === DATE_RANGE_LABEL.THIS_MONTH ||
    label === DATE_RANGE_LABEL.LAST_MONTH
  ) {
    return DISPLAY_TYPE_LIST.DAILY;
  } else if (label === DATE_RANGE_LABEL.THIS_YEAR || label === DATE_RANGE_LABEL.LAST_YEAR) {
    return DISPLAY_TYPE_LIST.MONTHY;
  } else {
    if (diffDays < 30 && diffDays > 0) {
      return DISPLAY_TYPE_LIST.DAILY;
    }
    if (diffDays === 0) {
      return DISPLAY_TYPE_LIST.HOURLY;
    }
    if (diffDays > 30 && diffDays < 180) {
      return DISPLAY_TYPE_LIST.WEEKLY;
    }
    if (diffDays > 180) {
      return DISPLAY_TYPE_LIST.MONTHY;
    }
  }
};

/* export const getTimeZoneValue = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}; */

export const getUTCDate = date => {
  const utc_offset = date.getTimezoneOffset();
  const newDate = Math.sign(utc_offset) !== -1 ? addMinutes(date, utc_offset) : subMinutes(date, Math.abs(utc_offset));
  return newDate;
};
