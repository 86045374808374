import { Button, Dialog, Grid, MenuItem, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as IconMember } from 'assets/icons/member.svg';
import TabPanel from 'components/elements/TabPanel';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/system';
import { PaternEmail, validateBusinessEmail } from 'utils/formPatterns';
import { FormInput } from 'components/elements/FormInput';

import CheckboxTree from 'react-checkbox-tree';
import { FormSelect } from 'components/elements/FormSelect';
import { TabLabel } from 'components/elements/TabLabel';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPendingInviteList,
  inviteCompanyMember,
  updateCompanyMember,
} from 'features/companyMember/companyMemberAction';
import { useSnackbar } from 'notistack';
import { getNodeIds, getSelectedNodeIds } from 'utils';
import { CHECKBOXTREE_ICONS } from 'constant';

export const InviteTeamMember = props => {
  const { open, onClose, data = null } = props;
  const IS_NEW_MODE = !data;
  const IS_EDIT_MODE = !!data;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { authorityList, permissionTemplate } = useSelector(state => state.companyMember);

  const [expandedPermissions, setExpandedPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [permissionData, setPermissionData] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setExpandedPermissions(getNodeIds(permissionTemplate));
  }, [permissionTemplate]);

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    defaultValues: {
      authority: 1,
    },
  });

  const resetForm = () => {
    reset({
      first_name: null,
      last_name: null,
      email: null,
      authority: 1,
    });
  };

  const SendInviteTeamForm = formData => {
    const { first_name, last_name, email, authority } = formData;

    if (IS_NEW_MODE && selectedPermissions && selectedPermissions.length === 0) {
      setTabValue(1);
      enqueueSnackbar('Please select role!', {
        variant: 'warning',
      });
      return;
    }

    if (IS_NEW_MODE) {
      const memberData = {
        name: first_name,
        surname: last_name,
        email: email,
        authority: authority,
        roles: permissionData,
      };
      dispatch(inviteCompanyMember(memberData)).then(result => {
        if (result.payload.success) {
          onClose();
          resetForm();
          dispatch(getPendingInviteList());
        }
      });
    }

    if (IS_EDIT_MODE) {
      dispatch(
        updateCompanyMember({
          postData: {
            name: first_name,
            surname: last_name,
            email: email,
            authority: authority,
            ...(data.roles && { roles: JSON.stringify(permissionData) }),
            id: data.id,
          },
        }),
      ).then(result => {
        if (result.payload.success) {
          onClose();
          resetForm();
          dispatch(getPendingInviteList());
        }
      });
    }
  };

  const handleOnCheck = (checked, node) => {
    setSelectedPermissions(checked);

    var _permissionData = permissionTemplate.map(item => {
      return {
        label: item.label,
        value: item.value,
        selected: checked.includes(item.value),

        ...(item.children && {
          children: item.children.map(item2 => {
            return {
              label: item2.label,
              value: item2.value,
              selected: checked.includes(item2.value),
              ...(item2.children && {
                children: item2.children.map(item3 => {
                  return {
                    label: item3.label,
                    value: item3.value,
                    selected: checked.includes(item3.value),
                  };
                }),
              }),
            };
          }),
        }),
      };
    });

    setPermissionData(_permissionData[0]);
  };

  useEffect(() => {
    setTabValue(0);
    setPermissionData([]);
    if (IS_EDIT_MODE) {
      reset({
        first_name: data?.name,
        last_name: data?.surname,
        email: data?.email,
        authority: data?.authority?.id,
      });
      if (data.roles) {
        setSelectedPermissions(getSelectedNodeIds([data?.roles]));
        setPermissionData(data?.roles);
      }
    }
    if (IS_NEW_MODE) {
      resetForm();
      setSelectedPermissions([]);
    }
  }, [open]);

  return (
    <Dialog className="Modal-Container Modal-InviteTeamMember" maxWidth={'sm'} open={open}>
      <div className="Modal-Box">
        <div className="Modal-Header">
          <div className="icon">
            <IconMember />
          </div>
          <div className="title">{IS_NEW_MODE ? 'Invite Team Member ' : 'Update Team Member '}</div>
          <div className="desc">Give your team member access.</div>
        </div>
        <div className="Modal-Content">
          <Tabs className={'Tabs-Standart Tabs-InviteTeamMember'} value={tabValue} onChange={handleChangeTab}>
            <Tab label={<TabLabel label="Contact" />} value={0} />
            {IS_NEW_MODE || data?.roles ? <Tab label={<TabLabel label="Permissions" />} value={1} /> : null}
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <Box component="form" onSubmit={handleSubmit(data => SendInviteTeamForm(data))} autoComplete="off">
              <Grid container rowSpacing={{ xs: '16px' }} columnSpacing={{ xs: '16px' }}>
                <Grid item sm={6}>
                  <FormInput
                    label={'First Name'}
                    errors={errors}
                    name={'first_name'}
                    control={control}
                    placeholder={'First Name*'}
                    isRequired={true}
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormInput
                    label={'Last Name'}
                    errors={errors}
                    name={'last_name'}
                    control={control}
                    placeholder={'Last Name'}
                    isRequired={false}
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormInput
                    label={'Email'}
                    errors={errors}
                    name={'email'}
                    rules={{ pattern: PaternEmail, validate: validateBusinessEmail }}
                    control={control}
                    placeholder={'Email*'}
                    isRequired={true}
                    inputType="email"
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormSelect name="authority" control={control} errors={errors} label={'Authority*'} defaultValue={''}>
                    {authorityList?.map(item => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </FormSelect>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <CheckboxTree
              nodes={permissionTemplate}
              checked={selectedPermissions}
              expanded={expandedPermissions}
              onCheck={(checked, node) => {
                handleOnCheck(checked, node);
              }}
              checkModel={'all'}
              onExpand={expanded => setExpandedPermissions(expanded)}
              icons={CHECKBOXTREE_ICONS}
            />
          </TabPanel>
        </div>
        <div className="Modal-Footer">
          <Button onClick={onClose} variant="outlined" color="white" fullWidth size="large">
            Cancel
          </Button>
          <Button variant="contained" size="large" fullWidth onClick={handleSubmit(SendInviteTeamForm)}>
            {IS_NEW_MODE ? 'Send Invite' : 'Update Member'}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
