import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const createOrReplaceUserPreference = createAsyncThunk(
  'UserPreferenceParser/createOrReplaceUserPreference',
  async ({ preference_value, setting_key }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('/api/user-preference-parser-ui/', { preference_value, setting_key });

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const getUserPreference = createAsyncThunk(
  'UserPreferenceParser/getUserPreference',
  async ({ setting_key }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/user-preference-parser-ui/parser-preference/${setting_key}`);
      const newData = {
        data: data,
        setting_key: setting_key,
      };
      return newData;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
