import { Avatar, Drawer, Menu, MenuItem, Tooltip } from '@mui/material';
import React, { useState } from 'react';

import { NAVIGATION, NAVIGATION_BOTTOM } from 'data';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'features/user/userLoginSlice';
import { stringAvatar } from 'utils';
import { useNavigate } from 'react-router-dom';
import { resetProfileState } from 'features/profile/profileSlice';
import { resetAccountState } from 'features/user/accountSlice';
import { resetPurchaseState } from 'features/purchase/purchaseSlice';
import { ROUTE_PATHS } from 'data/paths';

export const ProtectedSidebar = () => {
  const { isOpenSidebar } = useSelector(state => state.ui);
  const { company_plan_data } = useSelector(state => state.company);

  return (
    <Drawer
      className={`Sidebar ${isOpenSidebar ? 'opened' : ''}`}
      PaperProps={{
        className: 'Sidebar-Content',
      }}
      variant="permanent"
    >
      <div className="Sidebar-Header">
        <Link to={ROUTE_PATHS.HOME}>
          <div className="logo-box">
            {isOpenSidebar ? (
              <div className="logo-full">
                <img src="/images/logo-white.svg" alt="" />
              </div>
            ) : (
              <div className="logo-small">
                <img src="/images/logo-tiny.svg" alt="" />
              </div>
            )}
          </div>
        </Link>
      </div>
      <div className="Sidebar-Navigation">
        <div className="Navigation">
          {NAVIGATION.map((item, index) => (
            <NavItem key={index} data={item} />
          ))}
        </div>
      </div>
      <div className="Sidebar-Footer">
        <div className="Navigatation-Bottom">
          {NAVIGATION_BOTTOM.map((item, index) => {
            if (item.id === 'checkout' && !company_plan_data?.is_free) {
              return null;
            } else {
              return <NavItem key={index} data={item} />;
            }
          })}
        </div>
        <div className="Profile-Item">
          <ProfileCard isOpenSidebar={isOpenSidebar} />
        </div>
      </div>
    </Drawer>
  );
};

const ProfileCard = ({ isOpenSidebar }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handlerLogout = () => {
    dispatch(logout());
    dispatch(resetProfileState());
    dispatch(resetAccountState());
    dispatch(resetPurchaseState());
    navigate(ROUTE_PATHS.LOGIN);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  return (
    <>
      <div onClick={event => setAnchorEl(event.currentTarget)} className="Profile-Card">
        <div className="profile-avatar">
          <ProfileAvatar sx={{ width: isOpenSidebar ? 32 : 56, height: isOpenSidebar ? 32 : 56 }} />
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        className="Menu-SidebarStandart"
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          className: 'Menu-SidebarStandart-List',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => navigate(ROUTE_PATHS.PROFILE)}>Edit Profile</MenuItem>
        <MenuItem onClick={() => handlerLogout()}>Log Out</MenuItem>
      </Menu>
    </>
  );
};

export const ProfileAvatar = ({ ...props }) => {
  const { profile } = useSelector(state => state.profile);
  return (
    <>
      <Avatar
        alt={`${profile?.name} ${profile?.surname}`}
        src={profile?.photo_path && profile?.profile_photo}
        children={!profile?.photo_path && stringAvatar(`${profile?.name || '.'} ${profile?.surname || '.'}`)}
        {...props}
      />
    </>
  );
};

const NavItem = ({ data, onClick, className = '' }) => {
  const { pathname } = useLocation();
  const rootPathname = pathname.split('/')[1];
  const rootDataPathname = data.href?.split('/')[1];
  const isActive = rootPathname === rootDataPathname;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Tooltip
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        animationduration={500}
        title={<NavItemTooltipContent data={data} />}
        placement="right"
        PopperProps={{ className: 'Tooltip-SidebarNav' }}
      >
        <div className={`${className} nav-item ${isActive ? 'active' : ''}`}>
          <Link to={data?.href} className="nav-item-inner" title={data.label}>
            {data.icon ? <span className="nav-icon">{data.icon} </span> : null}
            {/*  {data.label ? <span className="nav-label">{data.label} </span> : null} */}
          </Link>
        </div>
      </Tooltip>
    </div>
  );
};

const NavItemTooltipContent = ({ data }) => {
  return (
    <div className="nav-item-tooltip-content">
      {data.children ? (
        data.children.map(item => {
          return (
            <Link key={item.id} to={item?.href} className="nav-item-tooltip-content-link">
              {<div className="nav-label">{item.label} </div>}
            </Link>
          );
        })
      ) : (
        <Link to={data?.href} className="nav-item-tooltip-content-link">
          {<div className="nav-label">{data.label} </div>}
        </Link>
      )}
    </div>
  );
};
