import { createSlice } from '@reduxjs/toolkit';
import { getAllHirizeFinList, getInvoiceDetail, parseInvoce } from './hirizeFinAction';

const initialState = {
  loading: false,
  success: null,

  message: null,
  invoice_data: null,
  list_invoice: null,

  invoice_upload_progress: [],
};

export const hirizeFinSlice = createSlice({
  name: 'hirizeFin',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetMatcherDetail: (state, { payload }) => {
      state.detail_matcher = null;
      state.detail_matcher_search = null;
    },
    updateUploadFileProgress: (state, { payload }) => {
      const { files } = payload;
      state.invoice_upload_progress = files.map(item => {
        return {
          ...item,
          status: 'loading',
          name: item.name,
        };
      });
    },
    changeUploadFileProgressStatus: (state, { payload }) => {
      const { index, status } = payload;
      state.invoice_upload_progress[index]['status'] = status;
    },
    resetUploadFileProgress: (state, { payload }) => {
      state.invoice_upload_progress = [];
      state.success_resume_list = null;
    },
  },
  extraReducers: builder => {
    // parseInvoce
    builder.addCase(parseInvoce.pending, state => {
      state.success = null;
      state.loading = true;
    });
    builder.addCase(parseInvoce.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.loading = false;
    });
    builder.addCase(parseInvoce.rejected, (state, { payload }) => {
      state.success = false;
      state.message = payload;
      state.loading = false;
    });

    // getAllHirizeFinList
    builder.addCase(getAllHirizeFinList.pending, state => {
      state.success = null;
    });
    builder.addCase(getAllHirizeFinList.fulfilled, (state, { payload }) => {
      state.success = true;
      state.list_invoice = payload.data;
    });
    builder.addCase(getAllHirizeFinList.rejected, (state, { payload }) => {
      state.success = false;
    });

    // getInvoiceDetail
    builder.addCase(getInvoiceDetail.pending, state => {
      state.success = null;
    });
    builder.addCase(getInvoiceDetail.fulfilled, (state, { payload }) => {
      /* state.success = payload.success; */
      state.invoice_data = payload.data[0].response.data.invoice;
    });
    builder.addCase(getInvoiceDetail.rejected, (state, { payload }) => {
      state.success = false;
    });
  },
});

export const {
  resetState,
  resetMatcherDetail,
  updateUploadFileProgress,
  changeUploadFileProgressStatus,
  resetUploadFileProgress,
} = hirizeFinSlice.actions;

export default hirizeFinSlice.reducer;
