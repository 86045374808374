import UnAuthorized from 'pages/unauthorized';
import React from 'react';
import { useSelector } from 'react-redux';

export const RolesRoute = ({ allowedRoles, children }) => {
  const { account_data } = useSelector(state => state.account);
  const isAllowed = account_data?.roles?.find(role => allowedRoles?.includes(role));

  if (isAllowed) {
    return children;
  } else {
    return <UnAuthorized />;
  }
};
