import { createSlice } from '@reduxjs/toolkit';
import { snackActions } from 'libs/snackbar';
import { addCreditCard, deletePaymentMethod, getAllCreditCards, updateDefaultPaymentMethod } from './companyCardAction';

const initialState = {
  success: null,
  message: null,
  success_card_added: null,
  list_all_credit_cards: null,
};

export const companyCardSlice = createSlice({
  name: 'companyCard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // addCreditCard
    builder.addCase(addCreditCard.pending, state => {
      state.success_card_added = null;
    });
    builder.addCase(addCreditCard.fulfilled, (state, { payload }) => {
      state.success_card_added = payload.success;
    });
    builder.addCase(addCreditCard.rejected, (state, { payload }) => {
      state.success_card_added = false;
    });

    // getAllCreditCards
    builder.addCase(getAllCreditCards.pending, state => {
      state.success = null;
    });
    builder.addCase(getAllCreditCards.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.list_all_credit_cards = payload.data;
    });
    builder.addCase(getAllCreditCards.rejected, (state, { payload }) => {
      state.success = payload.success;
    });

    // updateDefaultPaymentMethod
    builder.addCase(updateDefaultPaymentMethod.pending, state => {
      state.success = null;
    });
    builder.addCase(updateDefaultPaymentMethod.fulfilled, (state, { payload }) => {
      state.success = payload.success;
    });
    builder.addCase(updateDefaultPaymentMethod.rejected, (state, { payload }) => {
      state.success = payload.success;
    });

    // deletePaymentMethod
    builder.addCase(deletePaymentMethod.pending, state => {
      state.success = null;
    });
    builder.addCase(deletePaymentMethod.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      snackActions.success('Credit card deleted successfully!');
    });
    builder.addCase(deletePaymentMethod.rejected, (state, { payload }) => {
      state.success = payload.success;
    });
  },
});

export default companyCardSlice.reducer;
