import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { updateDataForgotPassword } from 'features/userMember/userMemberSlice';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmailHideFormatter } from 'utils';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from 'data/paths';
import { LoadingButton } from '@mui/lab';
let currentOTPIndex = 0;

export const ForgotPasswordCodeVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dataForgotPassword } = useSelector(state => state.userMember);

  const [OTPCode, setOTPCode] = useState(new Array(4).fill(''));
  const OTPCodeValue = OTPCode.join('');

  const SendCodeVerificationForm = () => {
    dispatch(updateDataForgotPassword({ field: 'code', value: OTPCodeValue }));
    navigate(ROUTE_PATHS.FORGOT_PASSWORD_RESET_PASSWORD);
  };
  return (
    <div className="ForgotPassword-CodeVerification">
      <div className="heading">
        <div className="title">Code Verification</div>
        <div className="desc">
          {`We have sent code to your Email `}{' '}
          <span className="email">{EmailHideFormatter(dataForgotPassword.email)}</span>
        </div>
      </div>
      <InputCodeArea
        email={dataForgotPassword.email}
        setCode={setOTPCode}
        code={OTPCode}
        submit={SendCodeVerificationForm}
      />
    </div>
  );
};

export const InputCodeArea = ({ submit, code, setCode, email }) => {
  const [activeIndexOTP, setActiveIndexOTP] = useState(0);
  const { loading } = useSelector(state => state.userMember);

  const inputRef = useRef(null);
  const OTPCodeValue = code.join('');

  const handlerChangeOTPInput = (event, index) => {
    const value = event.target.value;
    if (value.length > 1) return;
    const newValue = value.substring(value.length - 1);

    const newOTP = [...code];
    newOTP[currentOTPIndex] = newValue;

    if (!value) {
    } else {
      setActiveIndexOTP(currentOTPIndex + 1);
    }

    setCode(newOTP);
  };

  const handlerKeyDownOTPInput = (event, index) => {
    const { key } = event;
    currentOTPIndex = index;
    if (key === 'Backspace') {
      setActiveIndexOTP(currentOTPIndex - 1);
    }
  };

  useEffect(() => {
    inputRef?.current?.focus();
  }, [activeIndexOTP]);

  /*  if (!email) {
    return <Navigate to={ROUTE_PATHS.FORGOT_PASSWORD_EMAIL} replace />;
  } */

  const handlePaste = event => {
    const paste_text = event.clipboardData.getData('text/plain');
    const new_array = [
      paste_text.charAt(0) || '',
      paste_text.charAt(1) || '',
      paste_text.charAt(2) || '',
      paste_text.charAt(3) || '',
    ];
    setCode(new_array);
  };

  return (
    <Box className="Input-CodeArea" component="form" autoComplete="off">
      <div className="form-inputs">
        {[0, 1, 2, 3].map(item => {
          return (
            <TextField
              inputProps={{
                ref: item === activeIndexOTP ? inputRef : null,
              }}
              key={item}
              variant="outlined"
              value={code[item]}
              onChange={event => handlerChangeOTPInput(event, item)}
              onKeyDown={event => handlerKeyDownOTPInput(event, item)}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  submit();
                }
              }}
              onPaste={handlePaste}
            />
          );
        })}
      </div>

      <div className="form-action">
        <LoadingButton
          loading={loading}
          onClick={submit}
          fullWidth
          variant="contained"
          size="large"
          disabled={OTPCodeValue.length < 4}
        >
          Verify Account
        </LoadingButton>
      </div>
    </Box>
  );
};
