import React from 'react';

import { ChipDemoPro } from 'components/elements/ChipDemoPro';

export const TabLabel = ({ label, chipType = 'demo' }) => {
  return (
    <div className={`tab-label label-${chipType}`}>
      <span className="text">{label}</span>

      <ChipDemoPro type={chipType} />
    </div>
  );
};
