import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const addCreditCard = createAsyncThunk('company/addCreditCard', async (postData, { rejectWithValue }) => {
  /* const postData = {
      "type": null,
      "number": null,
      "month": null,
      "year": null,
      "cvc": null
    } */
  try {
    const { data } = await axios.post('api/company/card', postData);

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getAllCreditCards = createAsyncThunk(
  'company/getAllCreditCards',
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('api/company/cards');

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const updateDefaultPaymentMethod = createAsyncThunk(
  'company/updateDefaultPaymentMethod',
  async (postData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.put('api/company/cards/set-default-method', postData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    } finally {
      dispatch(getAllCreditCards());
    }
  },
);

export const deletePaymentMethod = createAsyncThunk(
  'company/deletePaymentMethod',
  async (postData, { rejectWithValue, dispatch }) => {
    const { id } = postData;
    try {
      const { data } = await axios.delete(`api/company/cards/${id}`, postData);

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    } finally {
      dispatch(getAllCreditCards());
    }
  },
);
