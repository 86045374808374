import { Box, Button, Grid } from '@mui/material';
import { passwordUpdate } from 'features/userMember/userMemberAction';
import { resetStatus } from 'features/userMember/userMemberSlice';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import { InputFilledPassword } from 'components/elements/InputFilledPassword';
import { ROUTE_PATHS } from 'data/paths';

export const ForgotPasswordResetPassword = () => {
  const { isLoading } = useSelector(state => state.ui);

  const dispatch = useDispatch();
  const { successPasswordUpdate, message, dataForgotPassword } = useSelector(state => state.userMember);
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },

    control,
  } = useForm({
    defaultValues: {
      password: '',
    },
  });
  const SendResetPasswordForm = data => {
    dispatch(
      passwordUpdate({
        email: dataForgotPassword.email,
        reset_token: dataForgotPassword.code,
        password: data.password,
      }),
    );
  };
  useEffect(() => {
    if (message === 'Reset token wrong') {
      dispatch(resetStatus());
      navigate('/forgot-password/code-verification');
    }
    if (successPasswordUpdate) {
      navigate(ROUTE_PATHS.LOGIN);
    }
  }, [isLoading, successPasswordUpdate]);

  if (!dataForgotPassword || !dataForgotPassword.email || !dataForgotPassword.code) {
    return <Navigate to={ROUTE_PATHS.FORGOT_PASSWORD_EMAIL} replace />;
  }

  return (
    <div className="ForgotPassword-ResetPassword">
      <div className="heading">
        <div className="title">Reset Your Password</div>
        <div className="desc">Insert your new password here and get started!</div>
      </div>
      <Box component="form" onSubmit={handleSubmit(data => SendResetPasswordForm(data))} autoComplete="off">
        <Grid container rowSpacing={{ xs: '0', sm: '44px' }}>
          <Grid item xs={12} sm={12}>
            <InputFilledPassword control={control} errors={errors} name={'password'} />
          </Grid>
          <Grid className="form-action-send" item xs={12}>
            <Button
              onClick={handleSubmit(SendResetPasswordForm)}
              fullWidth
              className="button-type-auth"
              variant="contained"
              size="large"
              disabled={isLoading}
            >
              Reset Password
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
