import { Button, Dialog } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { FormInput } from 'components/elements/FormInput';
import { ReactComponent as IconRight } from 'assets/icons/arrow-right.svg';
import IconEvaluator from 'assets/icons/products/evaluator.png';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createEvaluator, getEvaluatorList } from 'features/evaluator/evaluatorAction';

export const CreateEvaluator = ({ onClose, open }) => {
  const dispatch = useDispatch();
  const { success_create_evaluator } = useSelector(state => state.evaluator);
  const refInput = useRef(null);
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({});

  const clickCreateEvaluator = data => {
    dispatch(createEvaluator({ name: data.evaluator_name }));
  };

  useEffect(() => {
    if (success_create_evaluator) {
      dispatch(getEvaluatorList());
      onClose();
      reset({
        evaluator_name: null,
      });
    }
  }, [success_create_evaluator]);

  return (
    <Dialog className="Modal-Container Modal-CreateEvaluator" onClose={onClose} maxWidth={'sm'} open={open}>
      <form className="Modal-Box" onSubmit={handleSubmit(data => clickCreateEvaluator(data))}>
        <div className="Modal-Header">
          <div className="icon icon-img">
            <img src={IconEvaluator} alt="" />
          </div>
          <div className="title"> Create Evaluator</div>
          <div className="desc">You're about to create an evaluator. Give it a name that makes sense to you. </div>
        </div>
        <div className="Modal-Content">
          <div className="Evaluator-Name">
            <FormInput
              label={'Evaluator Name'}
              errors={errors}
              name={'evaluator_name'}
              control={control}
              placeholder={'Evaluator Name'}
              isRequired={true}
              ref={refInput}
              autoFocus
            />
          </div>
        </div>
        <div className="Modal-Footer">
          <Button onClick={onClose} variant="outlined" color="white" fullWidth className="button-cancel">
            Cancel
          </Button>
          <Button
            variant="contained"
            fullWidth
            className="icon-right "
            endIcon={<IconRight />}
            onClick={handleSubmit(data => clickCreateEvaluator(data))}
          >
            Create Evaluator
          </Button>
        </div>
      </form>
    </Dialog>
  );
};
