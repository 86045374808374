import { createSlice } from '@reduxjs/toolkit';
import { snackActions } from 'libs/snackbar';
import { createSubscription, paymentCheckStatus } from './subscriptionAction';

const initialState = {
  success_subscription: null,
  message_success_subscription: null,
  data_usage: null,
  payment_status: null,
  payment_type: null,
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    changePaymentType: (state, { payload }) => {
      state.payment_type = payload;
    },
  },
  extraReducers: builder => {
    // createSubscription
    builder.addCase(createSubscription.pending, state => {
      state.success_subscription = null;
    });
    builder.addCase(createSubscription.fulfilled, (state, { payload }) => {
      state.success_subscription = payload[0].success;
      state.message_success_subscription = payload[0].data;
    });
    builder.addCase(createSubscription.rejected, (state, { payload }) => {
      state.success_subscription = payload[0].success;
      snackActions.error(payload[0]);
    });

    // paymentCheckStatus
    builder.addCase(paymentCheckStatus.pending, state => {
      state.success_subscription = null;
    });
    builder.addCase(paymentCheckStatus.fulfilled, (state, { payload }) => {
      state.success_subscription = payload.success;
      state.payment_status = payload.data;
    });
    builder.addCase(paymentCheckStatus.rejected, (state, { payload }) => {
      state.success_subscription = false;
      state.payment_status = payload;
    });
  },
});

export const { changePaymentType } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
