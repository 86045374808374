import { configureStore } from '@reduxjs/toolkit';
import analyticsSlice from 'features/analytics/analyticsSlice';
import chatCVSlice from 'features/chat-cv/chatCVSlice';
import companySlice from 'features/company/companySlice';
import companyCardSlice from 'features/companyCard/companyCardSlice';
import companyMemberSlice from 'features/companyMember/companyMemberSlice';
import connectedAppsSlice from 'features/connected-apps/connectedAppsSlice';
import dashboardSlice from 'features/dashboard/dashboardSlice';
import endpointSlice from 'features/endpoint/endpointSlice';
import evaluatorSlice from 'features/evaluator/evaluatorSlice';
import hirizeFinSlice from 'features/hirizeFin/hirizeFinSlice';
import hirizeiqSlice from 'features/hirizeiq/hirizeiqSlice';
import matcherSlice from 'features/matcher/matcherSlice';
import jobParserSlice from 'features/parser/jobParserSlice';
import parserSlice from 'features/parser/parserSlice';
import productSlice from 'features/product/productSlice';
import profileSlice from 'features/profile/profileSlice';
import purchaseSlice from 'features/purchase/purchaseSlice';
import subscriptionSlice from 'features/subscription/subscriptionSlice';
import uiSlice from 'features/ui/uiSlice';
import accountSlice from 'features/user/accountSlice';
import userLoginSlice from 'features/user/userLoginSlice';
import userMemberSlice from 'features/userMember/userMemberSlice';
import userPreferenceParserSlice from 'features/UserPreferenceParser/UserPreferenceParserSlice';

const store = configureStore({
  reducer: {
    ui: uiSlice,
    userLogin: userLoginSlice,
    userMember: userMemberSlice,
    account: accountSlice,
    product: productSlice,
    dashboard: dashboardSlice,
    profile: profileSlice,
    company: companySlice,
    companyMember: companyMemberSlice,
    parser: parserSlice,
    jobParser: jobParserSlice,
    endpoint: endpointSlice,
    hirizeiq: hirizeiqSlice,
    matcher: matcherSlice,
    analytics: analyticsSlice,
    purchase: purchaseSlice,
    subscription: subscriptionSlice,
    companyCard: companyCardSlice,
    evaluator: evaluatorSlice,
    chatCV: chatCVSlice,
    connected_apps: connectedAppsSlice,
    hirizeFin: hirizeFinSlice,
    userPreferenceParser: userPreferenceParserSlice,
  },
});

export default store;
