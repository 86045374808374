import { ReactComponent as IconUser } from 'assets/icons/user.svg';
import { ReactComponent as IconTeam } from 'assets/icons/team.svg';
import { ReactComponent as IconSwitch } from 'assets/icons/switch.svg';

import { ReactComponent as IconQuestion } from 'assets/icons/question.svg';
import { ReactComponent as IconDocumentation } from 'assets/icons/documentation.svg';
import { ReactComponent as IconPhone } from 'assets/icons/phone.svg';
import { ReactComponent as IconCode } from 'assets/icons/code.svg';
import { URL_API_DOCUMENTATION } from 'data';
import { ROUTE_PATHS } from 'data/paths';
import IconGreenHouse from 'assets/icons/apps/green-house.png';

export const GET_STARTED = [
  {
    id: 1,
    title: 'Complete Details',
    icon: <IconUser />,
    desc: 'Complete details for a customized experience',
    buttonText: 'Complete details',
    buttonHref: ROUTE_PATHS.PROFILE,
  },
  {
    id: 2,
    title: 'Build Team',
    icon: <IconTeam />,
    desc: 'Add your teammates to use our products more effectively.',
    buttonText: 'Add teammates',
    buttonHref: ROUTE_PATHS.SETTINGS_ACCESS_MANAGEMENT,
  },
  {
    id: 3,
    title: `Activate API's`,
    icon: <IconSwitch />,
    desc: 'Select and purchase the right product for your needs',
    buttonText: 'Activate API',
    buttonHref: ROUTE_PATHS.ENDPOINTS_LIST,
  },
];

export const CONTACT_US = [
  {
    id: 1,
    text: 'Book a call',
    desc: `Need help with our products?`,
    href: 'https://hirize.hr/contact',
    icon: <IconPhone />,
  },
  {
    id: 2,
    text: 'FAQ',
    desc: `We've created an extensive FAQ site to help answer your questions.`,
    href: 'https://hirize.hr#faq-section',
    icon: <IconQuestion />,
  },
  {
    id: 3,
    text: 'API documentation',
    desc: `Find detailed information in our API documentation.`,
    href: URL_API_DOCUMENTATION,
    icon: <IconDocumentation />,
  },
];

export const CONTACT_LIST = [
  {
    id: 1,
    text: 'Greenhouse',
    desc: `Seamlessly Integrate With Greenhouse: Supercharge your recruitment process`,
    href: ROUTE_PATHS.CONNECTED_APPS,
    icon_image: IconGreenHouse,
  },
  {
    id: 2,
    text: 'Connect API',
    desc: `Plug into our APIs and kickstart your recruitment revolution today!`,
    href: ROUTE_PATHS.ENDPOINTS_LIST,
    icon: <IconCode />,
  },
];
