import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const GeneratePdf = (ref, filename) => {
  html2canvas(ref?.current, {
    logging: false,
    letterRendering: 1,
    useCORS: true,
    dpi: 72,
    scale: 2,
    onclone: (event, element) => {
      element.classList.add('pdf-state');
    },
  }).then(canvas => {
    var imgWidth = 210;
    var pageHeight = 297;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    var heightLeft = imgHeight;

    const pdf = new jsPDF('portrait', 'mm', 'a4', false);

    var position = 0;

    pdf.addImage(canvas, 'JPG', 0, position, imgWidth, imgHeight, 'NONE');
    heightLeft -= pageHeight;

    while (heightLeft >= 1) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(canvas, 'JPG', 0, 10 + position, imgWidth, imgHeight, 'NONE');
      heightLeft -= pageHeight;
    }
    pdf.save(filename);
  });
};
