import { Button, IconButton, TextField, Tooltip } from '@mui/material';

import { ReactComponent as IconArrowDown2 } from 'assets/icons/arrow-down-2.svg';
import { ReactComponent as IconXLS } from 'assets/icons/xls.svg';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';
import { ReactComponent as IconWarning } from 'assets/icons/info2.svg';

import React, { useEffect, useState } from 'react';
import Buttons from 'components/elements/buttons';
import { APPS_ICONS } from 'data/connect';
import { createConnectedApp, deleteConnectedApp, testZapierExport } from 'features/connected-apps/connectedAppsAction';
import { useDispatch, useSelector } from 'react-redux';
import { Chip } from 'components/elements/Chip';
import FileSampleXKSXParsedResume from 'documents/zapier-integrations/sample-file-parsed-resumes-zapier.xlsx';
import { URL_ZAPIER_EXPORT_ZAP_TEMPLATE } from 'data';
import { ReactComponent as IconCopy } from 'assets/icons/copy-2.svg';
import { copyResult } from 'utils';

export const ConnectApp = ({ data }) => {
  const { id, company, description, integration_keys, url, is_active, has_custom_webhook } = data;
  const [isOpened, changeIsOpened] = useState(false);
  const [value_newkey, set_value_newkey] = useState('');
  const dispatch = useDispatch();
  const { success_connect_apps } = useSelector(state => state.connected_apps);
  const IS_ZAPIER_WEBHOOK = company === 'Zapier Export' && has_custom_webhook;
  const has_generate_key = integration_keys?.length > 0;
  const IS_ZAPIER = id === 3;

  const clickAddConnectAppWebook = webhook_url => {
    dispatch(
      createConnectedApp({
        app_id: id,
        key: 'Zapier Export Integration',
        webhook_url: webhook_url,
      }),
    );
  };

  const addConnectApp = key => {
    dispatch(
      createConnectedApp({
        app_id: id,
        key: value_newkey,
      }),
    );
  };

  const handlerDeleteConnect = key_id => {
    dispatch(
      deleteConnectedApp({
        appId: id,
        keyId: key_id,
      }),
    );
  };

  const handlerClickAddNewKey = () => {
    addConnectApp();
  };

  const testDummyData = () => {
    dispatch(testZapierExport({}));
  };

  useEffect(() => {
    if (success_connect_apps !== null) {
      set_value_newkey('');
    }
  }, [success_connect_apps]);

  return (
    <div className="Card-ConnectApp">
      <div className="Card-Start">
        <div className="card-heading">
          <div className="card-heading-left">
            <div className="card-title">{company}</div>
            {!!url ? (
              <div className="card-link">
                <Buttons.ExternalLink text={url} url={url} />
              </div>
            ) : null}
          </div>
          <div className="card-heading-right">
            <div className="card-icon">{APPS_ICONS?.[id]?.['icon']}</div>
          </div>
        </div>
        <div className="card-desc">{description}</div>
      </div>
      <div className={`Card-Middle ${isOpened ? 'opened' : ''}`}>
        <AppCardContentApiKey
          data={data}
          deleteConnect={handlerDeleteConnect}
          clickAddNewKey={handlerClickAddNewKey}
          value_newkey={value_newkey}
          set_value_newkey={set_value_newkey}
          has_generate_key={has_generate_key}
        />
      </div>
      <div className="Card-End">
        <AppCardFooterApiKey
          has_generate_key={has_generate_key}
          changeIsOpened={changeIsOpened}
          isOpened={isOpened}
          data={data}
          IS_ZAPIER={IS_ZAPIER}
        />
      </div>
    </div>
  );
};

const AppCardContentApiKey = ({
  data,
  clickAddNewKey,
  deleteConnect,
  value_newkey,
  set_value_newkey,
  has_generate_key,
}) => {
  const { integration_keys, company } = data;

  const IS_GREENHOUSE = company === 'Greenhouse';

  const onClickCopyKey = key => {
    copyResult(key, 'Webhook URL copied!');
  };

  const GREENHOUSE_URL = token => {
    return `https://connect.hirize.hr/api/greenhouse/webhook-greenhouse/${token}`;
  };
  return (
    <div className="Card-ConnectApp-ApiKey">
      <div className="card-endpoint-list">
        {has_generate_key ? (
          <div className="list-header">
            <div className="title-detail">KEY/TOKEN</div>
            <div className="title-action">ACTIONS</div>
          </div>
        ) : null}
        <div className="list-content">
          {integration_keys?.map(item => {
            return (
              <div key={item.id} className="list-item">
                <div className="item-detail">
                  <div className="item-key">
                    <small>{IS_GREENHOUSE ? 'Harvest API KEY' : 'Key'} </small>
                    <span className="value">{item.key}</span>
                  </div>
                  <div className="item-token">
                    <small>{IS_GREENHOUSE ? 'Webhook URL' : 'Token'} </small>
                    <span className="value">{IS_GREENHOUSE ? GREENHOUSE_URL(item.token) : item.token}</span>
                  </div>
                  {IS_GREENHOUSE ? (
                    <div className="item-token">
                      <small>Secret Key</small>
                      <span className="value">{item.token}</span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="item-action">
                  {IS_GREENHOUSE ? (
                    <IconButton color="white" size="small" onClick={() => onClickCopyKey(GREENHOUSE_URL(item.token))}>
                      <IconCopy />
                    </IconButton>
                  ) : null}
                  <IconButton color="white" size="small" onClick={() => deleteConnect(item.id)}>
                    <IconTrash />
                  </IconButton>
                </div>
              </div>
            );
          })}

          <div className="list-item list-item-newkey">
            <div className="item-input">
              <TextField
                autoFocus
                fullWidth
                size="small"
                placeholder={'New API Key'}
                variant="outlined"
                value={value_newkey}
                onChange={event => set_value_newkey(event.target.value)}
              />
            </div>
            <div className="item-new-action">
              <Button variant="contained" size="small" color="primary" onClick={clickAddNewKey}>
                Add API Key
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AppCardContentWebhook = ({ data, clickAddConnectAppWebook, testDummyData, deleteConnect }) => {
  const { integration_keys } = data;
  const [value_webhook, set_value_webook] = useState({
    url: '',
    is_valid: false,
  });
  const active_webhook = integration_keys?.[integration_keys?.length - 1];

  const isUrlValid = userInput => {
    var regexQuery =
      '^(https?:\\/\\/)?((([-a-z0-9]{1,63}\\.)*?[a-z0-9]([-a-z0-9]{0,253}[a-z0-9])?\\.[a-z]{2,63})|((\\d{1,3}\\.){3}\\d{1,3}))(:\\d{1,5})?((\\/|\\?)((%[0-9a-f]{2})|[-\\w\\+\\.\\?\\/@~#&=])*)?$';
    var url = new RegExp(regexQuery, 'i');
    return url.test(userInput);
  };

  const is_value_empty = value_webhook.url === '';

  useEffect(() => {
    if (active_webhook) {
      set_value_webook({ url: active_webhook?.webhook_url, is_valid: true });
    } else {
      set_value_webook({ url: '', is_valid: false });
    }
  }, [data]);

  const onClickAddWebhook = () => {
    if (value_webhook.is_valid) {
      clickAddConnectAppWebook(value_webhook.url);
    }
  };

  return (
    <div className="Card-ConnectApp-Webhook">
      <div className="form-webhook-connect">
        <div className="input">
          <TextField
            disabled={active_webhook ? true : false}
            autoFocus
            fullWidth
            size="large"
            placeholder={'Webhook URL'}
            variant="outlined"
            value={value_webhook.url}
            onChange={event =>
              set_value_webook({
                url: event.target.value,
                is_valid: isUrlValid(event.target.value),
              })
            }
            error={!value_webhook.is_valid && !is_value_empty}
            helperText={value_webhook.is_valid || is_value_empty ? '' : 'URL is not correct'}
          />
        </div>
        <div className="action">
          {active_webhook ? (
            <Button variant="contained" size="large" color="red" onClick={() => deleteConnect(active_webhook?.id)}>
              Delete
            </Button>
          ) : (
            <Button
              disabled={is_value_empty}
              variant="contained"
              size="large"
              color="primary"
              onClick={onClickAddWebhook}
            >
              Add Webhook
            </Button>
          )}
        </div>
      </div>
      {active_webhook ? (
        <div className="test-connect">
          <Button target="_blank" onClick={testDummyData} variant="contained" color="secondary">
            Test Dummy Data
          </Button>
        </div>
      ) : null}
    </div>
  );
};

const AppCardFooterApiKey = ({ data, has_generate_key, IS_ZAPIER, isOpened, changeIsOpened }) => {
  const { id, integration_keys, is_active } = data;

  return (
    <div className="card-actions">
      <div className="actions-left">
        {!is_active ? (
          <div className="not-avaible">
            <Chip icon={<IconWarning />} color="warning" size="small" type="tint" label="Soon Available" />
          </div>
        ) : null}
        <Button disabled={!is_active} variant="contained" color="primary" onClick={() => changeIsOpened(true)}>
          Connect
        </Button>
        <Button
          target="_blank"
          href={APPS_ICONS?.[id]?.['learn_link']}
          variant="outlined"
          color="white"
          onClick={() => null}
        >
          Learn How
        </Button>
        {IS_ZAPIER ? (
          <Tooltip title="Download sample xls file and upload google drive as Google Sheets.">
            <Button
              variant="outlined"
              color="white"
              download="Sample XLS file for parsed resumes with Zapier"
              target="_blank"
              rel="noreferrer"
              href={FileSampleXKSXParsedResume}
              endIcon={<IconXLS />}
            >
              XLS Template
            </Button>
          </Tooltip>
        ) : null}
      </div>
      <div className="actions-right">
        {has_generate_key ? (
          <Button
            className={`${isOpened ? 'opened' : 'asdasdsa'}`}
            endIcon={<IconArrowDown2 />}
            variant="outlined"
            color="white"
            onClick={() => changeIsOpened(prev => !prev)}
            disabled={!is_active}
          >
            {integration_keys?.length} added
          </Button>
        ) : null}
      </div>
    </div>
  );
};

const AppCardFooterWebhook = ({ data }) => {
  const { is_active } = data;

  return (
    <div className="card-actions-webhook">
      {!is_active ? (
        <div className="not-avaible">
          <Chip icon={<IconWarning />} color="warning" size="small" type="tint" label="Soon Available" />
        </div>
      ) : null}

      <Button variant="outlined" color="white" target="_blank" rel="noreferrer" href={URL_ZAPIER_EXPORT_ZAP_TEMPLATE}>
        Zap Template
      </Button>
      <Tooltip title="Download sample xls file and upload google drive as Google Sheets.">
        <Button
          variant="outlined"
          color="white"
          download="Sample XLS file for parsed resumes with Zapier"
          target="_blank"
          rel="noreferrer"
          href={FileSampleXKSXParsedResume}
          endIcon={<IconXLS />}
        >
          XLS Template
        </Button>
      </Tooltip>
    </div>
  );
};
