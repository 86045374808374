import React, { useState } from 'react';
import { NumberFormatter } from 'utils';
import { TooltipStandartBox } from './elements/TooltipStandartBox';

import { ReactComponent as IconInfo } from 'assets/icons/info.svg';
import { ReactComponent as IconExclamation } from 'assets/icons/exclamation.svg';

import { Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
export const CreditStatusBox = () => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const { account_data } = useSelector(state => state.account);
  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const credit = NumberFormatter(parseInt(account_data?.['remaining-credit']) || 0);

  const creditStatus = credit === 0 ? 'no-credit' : 'has-credit';

  const textHasCreditInfo = <>You have {NumberFormatter(credit)} credits.</>;
  /* const textHasCreditInfo =  <>You have {NumberFormatter(credit)} of 20.000 credits remaining. Your credits wil expire November 25th, 2022</>; */

  return (
    <Tooltip
      onClose={handleTooltipClose}
      open={openTooltip}
      title={
        <TooltipStandartBox
          title={'Credits'}
          content={creditStatus === 'has-credit' ? textHasCreditInfo : textHasCreditInfo}
        />
      }
      PopperProps={{ className: 'Tooltip-Standart' }}
    >
      <div className={`CreditStatusBox status-${creditStatus}`} onClick={() => setOpenTooltip(prev => !prev)}>
        <span className="label">{credit} Credits</span>
        <span className="icon">{creditStatus === 'has-credit' ? <IconInfo /> : <IconExclamation />}</span>
        {/*  <span className="action">
          <Link className="link" to={ROUTE_PATHS.CHECKOUT}>
            <IconPlus />
          </Link>
        </span> */}
      </div>
    </Tooltip>
  );
};
