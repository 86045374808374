import { FormControl, IconButton, InputLabel, TextField } from '@mui/material';
import { FORM_TEXT } from 'data';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { ReactComponent as IconEye } from 'assets/icons/eye.svg';
import { ReactComponent as IconEyeSlash } from 'assets/icons/eye-slash.svg';

export const InputFilledPassword = ({ errors, control, label = 'Password', labelTop = null, name }) => {
  const [passwordType, changePasswordType] = useState('password');

  const handleClickShowPassword = () => {
    changePasswordType(prev => {
      if (prev === 'password') {
        return 'text';
      } else if (prev === 'text') {
        return 'password';
      }
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  return (
    <>
      {labelTop ? <InputLabel className="Label-TextField">{labelTop}</InputLabel> : null}
      <FormControl className="InputFilledPassword" fullWidth size={'small'}>
        <Controller
          render={({ field }) => (
            <TextField
              error={!!errors?.[name]?.['message']}
              helperText={errors?.[name]?.['message']}
              label={label}
              variant="filled"
              type={passwordType}
              {...field}
            />
          )}
          name="password"
          control={control}
          rules={{ required: FORM_TEXT.required }}
        />
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          size="medium"
          className="button-passwordType"
        >
          {passwordType === 'password' ? <IconEye /> : <IconEyeSlash />}
        </IconButton>
      </FormControl>
    </>
  );
};
