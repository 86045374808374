export const DATA_MATCHER_HEAD = [
  {
    id: 'rank',
    label: 'AVERAGE',
  },
  {
    id: 'name',
    label: 'NAME',
  },
  {
    id: 'date',
    label: 'DATE UPLOADED',
  },
  {
    id: 'match',
    label: 'MATCH',
  },
  {
    id: 'retain',
    label: 'RETAIN',
  },
  {
    id: 'action',
    label: 'ACTION',
  },
];

export const DATA_MEDIMIND_HEAD = [
  {
    id: 'rank',
    label: 'AVERAGE',
  },
  {
    id: 'name',
    label: 'NAME',
  },
  {
    id: 'date',
    label: 'DATE UPLOADED',
  },
  {
    id: 'scope_of_practice',
    label: 'SCOPE OF PRACTICE',
  },
  {
    id: 'education',
    label: 'EDUCATION',
  },
  {
    id: 'experience',
    label: 'EXPERIENCE',
  },
  {
    id: 'qualifying',
    label: 'EXAM',
  },
  {
    id: 'match',
    label: 'MATCH',
  },
  {
    id: 'retain',
    label: 'RETAIN',
  },
  {
    id: 'action',
    label: 'ACTION',
  },
];

export const DATA_MATCHER_LIST_HEAD = [
  {
    id: 'name',
    label: 'MATCHER NAME',
    label_medimind: 'NAME',
    width: '70%',
  },
  {
    id: 'log_count',
    label: 'RESUME',
    label_medimind: 'RESUME',
    width: '10%',
  },
  {
    id: 'date',
    label: 'DATE',
    label_medimind: 'DATE',
    width: '10%',
  },
  {
    id: 'action',
    label: 'ACTION',
    label_medimind: 'ACTION',
    width: '10%',
  },
];
