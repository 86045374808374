import { ReactComponent as IconCheck } from 'assets/icons/check.svg';
import { userLogin } from 'features/user/userLoginAction';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const CreatePasswordResult = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { invited_member_detail } = useSelector(state => state.userMember);
  const refTimerRedirect = useRef(null);
  useEffect(() => {
    dispatch(
      userLogin({
        email: invited_member_detail.email,
        password: invited_member_detail.new_password,
      }),
    ).then(result => {
      if (!!result.payload.access_token) {
        refTimerRedirect.current = setTimeout(() => {
          navigate('/dashboard');
        }, 2500);
      }
    });

    return () => clearTimeout(refTimerRedirect.current);
  }, []);
  return (
    <div className="CreatePassword-Result">
      <div className="icon">
        <img src="/images/logo-tiny-2.svg" alt="" />
      </div>
      <div className="status-title">
        <span className="text">Verified</span>{' '}
        <span className="status-icon">
          <IconCheck />
        </span>
      </div>
      <div className="status-desc">Your email was successfully verified.</div>
      <div className="info-action">
        <span className="loading-icon">
          <img src="/images/loading-animation.svg" alt="" />
        </span>
        <span className="text">You are being redirected to the dashboard</span>
      </div>
    </div>
  );
};
