import { Autocomplete, Checkbox, createFilterOptions, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as IconPlus } from 'assets/icons/plus.svg';
import { useDispatch } from 'react-redux';
import { createCompanyParserTags } from 'features/parser/parserAction';
import { ChipAutoComplete } from './Chip';
export const AutoCompletedTagList = ({
  type,
  tagList,
  onChange,
  clicked_row,
  removeTagParser,
  addTagParser,
  addCompanyTagParser,
  value,
}) => {
  const filter = createFilterOptions();
  const dispatch = useDispatch();
  const [selected, setselected] = useState(null);

  const isSameTag = (a, b) => a.id === b.id;
  const onlyInLeft = (left, right, compareFunction) =>
    left?.filter(leftValue => right?.some(rightValue => compareFunction(leftValue, rightValue)));

  useEffect(() => {
    setselected(onlyInLeft(tagList, clicked_row?.tags, isSameTag));
  }, [clicked_row?.tags, tagList]);

  useEffect(() => {}, [tagList]);

  return (
    <>
      <Autocomplete
        className={`Autocomplete-OpenedStandart type-${type}`}
        renderInput={params => <TextField {...params} variant="outlined" placeholder="Add filter/tags" />}
        open={true}
        multiple={true}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        popupIcon={null}
        clearIcon={null}
        disablePortal={true}
        options={tagList && tagList}
        value={type === 'new' ? value : selected || []}
        onChange={(event, value, reason, details) => {
          if (type === 'new' && !details.option.isNewTagCompany) {
            onChange?.(value);
          }

          if (type === 'update' && !details.option.isNewTagCompany) {
            if (reason === 'selectOption') {
              addTagParser(details.option, clicked_row.id);
            }
            if (reason === 'removeOption') {
              removeTagParser(details.option, clicked_row.id);
            }
          }

          if (details.option.isNewTagCompany) {
            dispatch(createCompanyParserTags({ tag: details.option.inputValue }));
          }
        }}
        autoSelect={true}
        componentsProps={{
          popper: {
            className: `Autocomplete-OpenedStandart-Popper type-${type}`,
          },
        }}
        getOptionLabel={option => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.tag;
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} className={`${props.className} ${!!option.inputValue ? 'option-add-tag' : ''}`}>
              {!option.inputValue ? <Checkbox checked={selected} /> : <IconPlus />}
              {option.tag}
            </li>
          );
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params?.inputValue !== '') {
            filtered.push({
              isNewTagCompany: true,
              inputValue: params?.inputValue,
              tag: `Create new tag "${params?.inputValue}"`,
            });
          }

          return filtered;
        }}
        ChipProps={ChipAutoComplete}
      />
    </>
  );
};
