import React from 'react';
import { ReactComponent as IconBgFixedBanner } from 'assets/icons/bg-fixed-banner.svg';

export const FixedPlanPayment_Action = ({ children }) => <>{children}</>;

const FixedPlanPayment = ({ children, type, icon, text }) => {
  return (
    <div className={`Banner-FixedPayment type-${type}`}>
      <div className="background">
        <IconBgFixedBanner />
      </div>
      <div className="icon">{icon}</div>
      <div className="text" dangerouslySetInnerHTML={{ __html: text }}></div>
      <div className="action">{children}</div>
      {/* <div className="action">
        <Button variant="contained" color="primary" onClick={() => window.open('https://hirize.hr/contact', '_blank')}>
          Get in Touch
        </Button>
      </div> */}
    </div>
  );
};

FixedPlanPayment.Action = FixedPlanPayment_Action;

export default FixedPlanPayment;
