import { createSlice } from '@reduxjs/toolkit';
import { getAllProduct, getAllProductFunctions, getProductCalculateRequestCountOptions } from './productAction';

const initialState = {
  success: null,
  message: null,
  allProduct: null,
  allProductFunctions: null,
  product_count_options: null,
  profit_product_count_options: null,
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // get all product
    builder.addCase(getAllProduct.pending, state => {
      state.success = null;
    });
    builder.addCase(getAllProduct.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.allProduct = payload.data.filter(item => item.id !== 23);
    });
    builder.addCase(getAllProduct.rejected, (state, { payload }) => {
      state.success = payload.success;
    });

    // get all product functions
    builder.addCase(getAllProductFunctions.pending, state => {
      state.success = null;
    });
    builder.addCase(getAllProductFunctions.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      state.allProductFunctions = payload.data;
    });
    builder.addCase(getAllProductFunctions.rejected, (state, { payload }) => {
      state.success = payload.success;
    });

    // getProductCalculateRequestCountOptions
    builder.addCase(getProductCalculateRequestCountOptions.pending, state => {
      state.success = null;
    });
    builder.addCase(getProductCalculateRequestCountOptions.fulfilled, (state, { payload }) => {
      state.success = payload.success;
      const newArray = Object.entries(payload.data.options).map(item => {
        const [, value] = item;
        return {
          id: value,
          label: value > 1000 ? `${value / 1000}K` : value,
        };
      });

      state.product_count_options = newArray;
      state.profit_product_count_options = payload.data.profit;
    });
    builder.addCase(getProductCalculateRequestCountOptions.rejected, (state, { payload }) => {
      state.success = payload.success;
    });
  },
});

export default productSlice.reducer;
