import axios from 'axios';
import { toBase64 } from 'utils';
import { updateFileProgressStatus } from './hirizeiqSlice';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const makeHirizeIQRequest = createAsyncThunk(
  'hirizeiq/makeHirizeIQRequest',
  async ({ file, job_description }, { dispatch, rejectWithValue }) => {
    const base64file = await toBase64(file);

    const postData = {
      payload: base64file,
      file_name: file.name,
      file: null,
      job_description: job_description,
    };

    try {
      const { data } = await axios.post('api/hirizeiq-ui/make-request', postData, {
        onUploadProgress: progressEvent => {
          let progress = (progressEvent.loaded / progressEvent.total) * 100;
          dispatch(updateFileProgressStatus({ progress }));
        },
      });

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
