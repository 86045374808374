import { ProtectedLayout } from 'components/layouts/ProtectedLayout';
import { ReactComponent as IconCopy } from 'assets/icons/copy-2.svg';

import React, { useState } from 'react';
import {
  Button,
  Drawer,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { ReactComponent as IconPlus2 } from 'assets/icons/plus-2.svg';
import { ReactComponent as IconArrowDown } from 'assets/icons/arrow-down.svg';

import { ReactComponent as IconSearch } from 'assets/icons/search.svg';
import { ReactComponent as IconLight } from 'assets/icons/light.svg';
import { ReactComponent as IconLink } from 'assets/icons/link.svg';

/* import { TableEmpty } from 'components/elements/TableEmpty'; */
import useOrderTable from 'hooks/useOrderTable';
import { TableHeadSortLabel } from 'components/elements/TableSortLabel';
import { DATA_EVALUATOR_HEAD } from 'data/evaluator';
import { default as HirizeDate } from 'components/elements/Date';
import { CreateEvaluator as ModalCreateEvaluator } from 'components/modals/CreateEvaluator';
import { ReactComponent as IconClose } from 'assets/icons/close.svg';
import { copyResult } from 'utils';
import { Chip } from 'components/elements/Chip';
import { useEffect } from 'react';
import { getEvaluatorList, getEvaluatorResult } from 'features/evaluator/evaluatorAction';
import { useDispatch, useSelector } from 'react-redux';
import { TrimAll } from 'utils/index';
import { formatDistance, parseISO } from 'date-fns';
import { TableEmpty } from 'components/elements/TableEmpty';

export const EvaluatorList = () => {
  const dispatch = useDispatch();

  const [valueTableSearch, setValueTableSearch] = useState('');
  const [valueEvaluatorSelect, setValueEvaluatorSelect] = useState(null);
  const [openModalAddNewEvaluator, setModalAddNewEvaluator] = useState(false);
  const [order, orderBy, createSortHandler] = useOrderTable('desc', 'date');
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [valueSelectedResult, setValueSelectedResult] = useState(false);

  const { evaluator_list, success_evaluator_list, evaluator_detail } = useSelector(state => state.evaluator);

  const onChangeValueTableSearch = event => {
    const { value } = event.target;
    setValueTableSearch(value);
  };

  const changeEvaluator = event => {
    const { value } = event.target;
    const evaluator_item = evaluator_list.find(item => item.id === value);
    setValueEvaluatorSelect(evaluator_item);
    dispatch(getEvaluatorResult({ code: evaluator_item?.code }));
  };

  const clickCopyEvaluatorLink = key => {
    copyResult(key, 'Evaluator copied!');
  };

  useEffect(() => {
    dispatch(getEvaluatorList());
  }, []);

  useEffect(() => {
    if (success_evaluator_list) {
      setValueEvaluatorSelect(evaluator_list[0]);
      dispatch(getEvaluatorResult({ code: evaluator_list[0]?.code }));
    }
  }, [success_evaluator_list]);

  const hasEvaluator = evaluator_detail?.evaluator?.[0];

  const EVALUATOR_URL = window.location.origin + '/evaluator-form/' + evaluator_detail?.evaluator?.[0]?.['code'];

  const filtered_evaluator_responses = evaluator_detail?.responses.filter(item => {
    return (
      item.name.toLowerCase().indexOf(TrimAll(valueTableSearch).toLocaleLowerCase()) !== -1 ||
      item.email.toLowerCase().indexOf(TrimAll(valueTableSearch).toLocaleLowerCase()) !== -1
    );
  });
  return (
    <ProtectedLayout>
      <ModalCreateEvaluator onClose={() => setModalAddNewEvaluator(false)} open={openModalAddNewEvaluator} />
      <DrawerEvaluatorCandidateDetail
        onClose={() => setOpenDrawer(false)}
        open={isOpenDrawer}
        evaluator_id={valueSelectedResult}
      />

      <div className="Page Page-EvaluatorList">
        <div className="EvaluatorList">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="title">
                Evaluator{' '}
                <span className="evaluator-name">
                  {' '}
                  {hasEvaluator ? `(${evaluator_detail?.evaluator?.[0]?.['name']})` : ''}
                </span>
              </div>
              <div className="detail">
                {hasEvaluator ? (
                  <>
                    <Button
                      variant="outlined"
                      color="white"
                      endIcon={<IconCopy />}
                      onClick={() => clickCopyEvaluatorLink(EVALUATOR_URL)}
                      title="Copy evaluator link!"
                    >
                      {EVALUATOR_URL}
                    </Button>
                    <IconButton
                      className="button-link"
                      size="small"
                      variant="contained"
                      color="primary"
                      target="_blank"
                      href={EVALUATOR_URL}
                      title="Open evaluator link!"
                    >
                      <IconLink />
                    </IconButton>
                    <div className="evaluator-time">
                      Created{' '}
                      {formatDistance(parseISO(evaluator_detail?.evaluator?.[0]?.['created_at']), new Date(), {
                        addSuffix: true,
                      })}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="Heading-End">
              <Button
                startIcon={<IconPlus2 />}
                variant="contained"
                color="primary"
                onClick={() => setModalAddNewEvaluator(true)}
              >
                Add New Evaluator
              </Button>

              {/*  {hasEvaluator ? (
                <Button
                  startIcon={evaluator_detail?.evaluator[0]?.['is_active'] ? <IconPause /> : <IconPlay />}
                  variant="outlined"
                  color="white"
                >
                  {evaluator_detail?.evaluator[0]?.['is_active'] ? ' Stop Evaluator' : 'Active Evaluator'}
                </Button>
              ) : (
                ''
              )} */}
            </div>
          </div>
          <div className="EvaluatorList-List">
            <div className="EvaluatorList-Table-Header">
              <div className="EvaluatorList-Table-Header-Left">
                <div className="search-candidate">
                  <TextField
                    placeholder={'Search'}
                    variant="outlined"
                    value={valueTableSearch}
                    onChange={onChangeValueTableSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconSearch />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="EvaluatorList-Table-Header-Right">
                {hasEvaluator ? (
                  <div className="select-evaluator">
                    Select Evaluator:
                    {evaluator_list?.length > 0 ? (
                      <Select
                        className="Select-Standart"
                        IconComponent={IconArrowDown}
                        value={valueEvaluatorSelect?.id || ''}
                        onChange={event => changeEvaluator(event)}
                        MenuProps={{
                          disableScrollLock: true,
                          className: 'Select-Standart-Menu',
                        }}
                      >
                        {evaluator_list?.map(item => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    ) : null}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="EvaluatorList-Table">
              <TableContainer>
                <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow>
                      {DATA_EVALUATOR_HEAD.map(headCell => (
                        <TableCell
                          key={headCell.id}
                          align={'left'}
                          padding={'none'}
                          sx={{ width: headCell.width }}
                          sortDirection={orderBy === headCell.id ? order : false}
                        >
                          {headCell.id === 'action' || headCell.id === 'name' ? (
                            headCell.label
                          ) : (
                            <TableHeadSortLabel
                              orderBy={orderBy}
                              headCell={headCell}
                              order={order}
                              onClick={createSortHandler(headCell.id)}
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filtered_evaluator_responses?.map((row, index) => {
                      return (
                        <TableRow hover key={row.created_at}>
                          <TableCell className="bold" component="th" scope="row" padding="none">
                            {row.name}
                          </TableCell>
                          <TableCell className="bold" component="th" scope="row" padding="none">
                            {row.email}
                          </TableCell>

                          <TableCell padding="none">
                            <HirizeDate format="PPpp" date={row.created_at} />
                          </TableCell>
                          {/* <TableCell className="bold" component="th" scope="row" padding="none">
                            {row.score}
                          </TableCell> */}
                          <TableCell padding="none">
                            <div className="table-cell-action">
                              <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  setOpenDrawer(true);
                                  setValueSelectedResult(row.id);
                                }}
                              >
                                Open Result
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {filtered_evaluator_responses?.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={12}>
                          <TableEmpty
                            text={
                              !hasEvaluator
                                ? 'There are no answers yet!'
                                : !!valueTableSearch
                                ? 'There are no results!'
                                : 'There are no answers yet!'
                            }
                            desc={
                              !hasEvaluator
                                ? `Create your Evaluator link, and let's start reeling in those exceptional ability bullet points.`
                                : !!valueTableSearch
                                ? ''
                                : `Calm before the storm... No responses yet, but they're on their way!`
                            }
                          >
                            {hasEvaluator ? (
                              ''
                            ) : (
                              <Button
                                startIcon={<IconPlus2 />}
                                variant="contained"
                                color="primary"
                                onClick={() => setModalAddNewEvaluator(true)}
                              >
                                Add New Evaluator
                              </Button>
                            )}
                          </TableEmpty>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                {/* { evaluator_detail.responses?.total_count > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={ evaluator_detail.responses?.total_count}
                    rowsPerPage={ evaluator_detail.responses?.per_page}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    SelectProps={{ className: 'table-select-rows-per-page' }}
                  />
                )} */}
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};

export const DrawerEvaluatorCandidateDetail = ({ open, onClose, evaluator_id }) => {
  const { evaluator_detail } = useSelector(state => state.evaluator);

  const data_answer = evaluator_detail?.responses.find(item => item.id === evaluator_id);
  return (
    <Drawer elevation={0} anchor={'right'} open={open} onClose={onClose}>
      <div className="Drawer-EvaluatorCandidateDetail">
        <div className="Drawer-EvaluatorCandidateDetail-Header">
          <div className="title">CANDIDATE'S RESULTS</div>

          <div className="action">
            <IconButton onClick={onClose} variant="outlined" color="white" size="small">
              <IconClose />
            </IconButton>
          </div>
        </div>
        <div className="Drawer-EvaluatorCandidateDetail-Content">
          <div className="candidate-info">
            <div className="info-left">
              <div className="name">{data_answer?.name}</div>
              <div className="email">{data_answer?.email}</div>
              <div className="date">
                <HirizeDate format="PPpp" date={data_answer?.created_at} />
              </div>
            </div>
            {/* <div className="info-right">
              <div className="score">70</div>
            </div> */}
          </div>
          <div className="candidate-results">
            {data_answer?.responses.map((item, index) => {
              const isPositive = item.signal === 'Positive';
              return (
                <div key={index} className="result-item">
                  <div className="result-heading">
                    <span className="number">{index + 1}. Answer</span>
                    {
                      <Chip
                        size="small"
                        color={isPositive ? 'success' : 'red'}
                        label={isPositive ? 'POSITIVE' : 'BAD'}
                      />
                    }
                  </div>
                  <div className="result-text">{item.answer}</div>

                  <ol className="result-detailed-reasoning">
                    <div className="heading">
                      <span className="icon">
                        <IconLight />
                      </span>
                      <span className="title">Detailed Reasoning</span>
                    </div>
                    {item.detailed_reasoning.map((detail_item, detail_index) => {
                      return <li key={detail_index}>{detail_item}</li>;
                    })}
                  </ol>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Drawer>
  );
};
