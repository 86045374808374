import { ProtectedLayout } from 'components/layouts/ProtectedLayout';

import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DATA_END_POINTS_HEAD } from 'data/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyEndpoints } from 'features/endpoint/endpointAction';
import { TableEmpty } from 'components/elements/TableEmpty';
import Date from 'components/elements/Date';
import { ReactComponent as IconPlus2 } from 'assets/icons/plus-2.svg';
import { Chip, ChipProduct } from 'components/elements/Chip';
import { ROUTE_PATHS } from 'data/paths';

export const EndPoints = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { company_endpoints } = useSelector(state => state.endpoint);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    dispatch(getCompanyEndpoints());
  }, []);

  return (
    <ProtectedLayout>
      <div className="Page Page-EndPoints">
        <div className="EndPoints">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="title">End-Points</div>
              <div className="desc">Find and manage all of your connected integrations here</div>
            </div>
            <div className="Heading-End">
              <Button
                variant="contained"
                color="primary"
                startIcon={<IconPlus2 />}
                onClick={() => navigate(ROUTE_PATHS.ENDPOINTS_NEW)}
              >
                Create key
              </Button>
            </div>
          </div>
          <div className="EndPoints-List">
            <div className="EndPoints-Table">
              <TableContainer>
                <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow>
                      {DATA_END_POINTS_HEAD.map(headCell => (
                        <TableCell key={headCell.id} align={'left'} padding={'none'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {company_endpoints &&
                      company_endpoints?.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow hover tabIndex={-1} key={row.id}>
                            <TableCell className="bold" component="th" id={labelId} scope="row" padding="none">
                              {row.name}
                            </TableCell>
                            <TableCell padding="none">{row.keys[0].key}</TableCell>
                            <TableCell padding="none">
                              <ChipProduct product_name={row.product.name} product_id={row.product.id} />
                            </TableCell>
                            <TableCell padding="none">
                              <Date date={row?.created_at} />
                            </TableCell>
                            <TableCell padding="none">
                              <Chip
                                dot
                                type="stroke"
                                color={row?.is_active ? 'success' : 'warning'}
                                label={row?.is_active ? 'Active' : 'Passive'}
                                size="small"
                              />
                            </TableCell>
                            <TableCell padding="none">
                              <div className="table-cell-action">
                                <Button
                                  variant="outlined"
                                  color="white"
                                  size="small"
                                  onClick={event => {
                                    event.stopPropagation();
                                    window.open(row?.api_doc_url ?? '#', '_blank');
                                  }}
                                >
                                  Generate Code
                                </Button>

                                <Button
                                  variant="outlined"
                                  color="white"
                                  size="small"
                                  onClick={() => navigate(`/endpoints/edit/${row?.id}`)}
                                >
                                  Edit
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  {company_endpoints?.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={12}>
                          <TableEmpty
                            icon={'table-empty-endpoints'}
                            text={'You have no keys yet '}
                            desc={'Getting started is easy, just hit “Create New Key” and connect your apps '}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={company_endpoints?.length || 0}
                  rowsPerPage={company_endpoints?.length || 0}
                  page={page}
                  onPageChange={handleChangePage}
                  SelectProps={{ className: 'table-select-rows-per-page' }}
                />
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};
