import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const getDataUsage = createAsyncThunk('analytics/getDataUsage', async (postData, { rejectWithValue }) => {
  try {
    const { data } = await axios.post('api/analytics/data-usage', postData);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getDataUsageDetail = createAsyncThunk(
  'analytics/getDataUsageDetail',
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('api/analytics/data-usage-detail', postData);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
