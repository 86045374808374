import { ProtectedLayout } from 'components/layouts/ProtectedLayout';

import React, { useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { DATA_ANALYTICS_HEAD } from 'data/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { TableEmpty } from 'components/elements/TableEmpty';
import { DATA_ANALYTICS_PRODUCTS } from 'data';
import { Bar, BarChart, CartesianGrid, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { endOfDay, endOfWeek, format, parseISO, startOfDay, startOfWeek, startOfYear } from 'date-fns';
import Date from 'components/elements/Date';
import { crateNewDate } from 'utils';
import { DISPLAY_TYPE_LIST, getRangeType } from 'utils/dates';
import { getDataUsage, getDataUsageDetail } from 'features/analytics/analyticsAction';
import { PopoverRangeDatepicker } from 'components/elements/RangeDatepicker';
import { TableHeadSortLabel } from 'components/elements/TableSortLabel';
import useOrderTable from 'hooks/useOrderTable';
import { Chip } from 'components/elements/Chip';
const newDate = crateNewDate();

export const Analytics = () => {
  return (
    <ProtectedLayout>
      <div className="Page Page-Analytics">
        <div className="Analytics">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="title">Analytics</div>
              <div className="desc">Below you’ll find a summary of API usage for your organization.</div>
            </div>
          </div>
          <div className="Analytics-DataUsage">
            <DataUsageChart />
          </div>
          <div className="Analytics-DataUsageDetail">
            <DataUsageDetail />
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};

const DataUsageChart = () => {
  const dispatch = useDispatch();
  const { data_usage } = useSelector(state => state.analytics);
  const [labelDatePickerButton, setLabelDatePickerButton] = useState('This Week');
  const [valueDatePicker, setValueDatePicker] = useState([
    {
      startDate: startOfWeek(newDate),
      endDate: endOfWeek(newDate),
      key: 'selection',
    },
  ]);

  const rangeType = useMemo(
    () => getRangeType(labelDatePickerButton, valueDatePicker[0]),
    [labelDatePickerButton, valueDatePicker],
  );

  const handleGetDataUsage = () => {
    const _startDate = startOfDay(parseISO(format(valueDatePicker[0].startDate, "yyyy-MM-dd'T'HH:mm")));
    const _endDate = endOfDay(parseISO(format(valueDatePicker[0].endDate, "yyyy-MM-dd'T'HH:mm")));

    dispatch(
      getDataUsage({
        display_type: rangeType,
        start_date: format(_startDate, "yyyy-MM-dd'T'HH:mm"),
        end_date: format(_endDate, "yyyy-MM-dd'T'HH:mm"),
      }),
    );
  };

  useEffect(() => {
    handleGetDataUsage();
  }, [valueDatePicker, labelDatePickerButton]);

  return (
    <div className="DataUsageChart">
      <div className="DataUsageChart-Heading">
        <div className="title">Data Usage</div>
        <div className="product-bar-list">
          {DATA_ANALYTICS_PRODUCTS.map(item => {
            return (
              <div key={item.id} className="bar-item">
                <span className={`bar-color color-${item.color}`} style={{ backgroundColor: item.color }}></span>
                <span className="bar-text">{item.label}</span>
              </div>
            );
          })}
        </div>
        <div className="action">
          <PopoverRangeDatepicker
            value={valueDatePicker}
            onChange={setValueDatePicker}
            selectedLabel={labelDatePickerButton}
            updateSelectedLabel={setLabelDatePickerButton}
          />
        </div>
      </div>
      <div className="DataUsageChart-Chart">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            className="Chart-DataUsageChart"
            width={100}
            height={300}
            data={data_usage}
            margin={{
              top: 20,
              right: 0,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis tickSize={10} tickLine={false} axisLine={true} />
            <XAxis
              dataKey={payload => dateDataKeyXAxix(payload, rangeType)}
              tickSize={10}
              tickLine={false}
              axisLine={true}
            />
            <Tooltip
              cursor={<CustomCursor />}
              animationDuration={500}
              content={<TooltipDateUsage rangeType={rangeType} labelDatePicker={labelDatePickerButton} />}
            />
            {DATA_ANALYTICS_PRODUCTS.map((item, index) => {
              const radius =
                index === 0 ? [0, 0, 8, 8] : index === DATA_ANALYTICS_PRODUCTS.length - 1 ? [8, 8, 0, 0] : 0;
              const isLarge = data_usage?.length > 15;
              return (
                <Bar
                  barCategoryGap={'20%'}
                  key={item.id}
                  radius={radius}
                  stackId="a"
                  barSize={isLarge ? 32 : 48}
                  brushBottom={10}
                  dataKey={item.id}
                  fill={item.color}
                  className={isLarge ? 'size-medium' : 'size-large'}
                />
              );
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const TooltipDateUsage = ({ active, payload, label, rangeType, labelDatePicker }) => {
  const data = payload?.[0]?.payload;
  if (active && payload && payload.length && data) {
    return (
      <div className="RechartTooltip-DataUsage">
        <div className="date-info">
          {rangeType === DISPLAY_TYPE_LIST.WEEKLY ? (
            <div className="date-weekly">{labelDatePicker}</div>
          ) : (
            <div className="date-1">
              <Date date={data.date} format={rangeType === DISPLAY_TYPE_LIST.MONTHY ? 'MMMM' : 'PP'} />
            </div>
          )}

          <div className="date-2">{dateChartTooltip(data, rangeType)}</div>
        </div>
        <ul className="data-list">
          {DATA_ANALYTICS_PRODUCTS.map((item, index) => {
            return (
              <li key={index}>
                <span className="label">{item.label}:</span>
                <span className="value">{data?.[item.id]}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  } else {
    return <div className="RechartTooltip-DataUsage">No data</div>;
  }
};

const CustomCursor = props => {
  const { x, y, width, height } = props;
  return <Rectangle fill="#eef0f3" stroke="transparent" x={x} y={y} width={width} height={height} />;
};

const dateDataKeyXAxix = (payload, rangeType) => {
  if (rangeType === DISPLAY_TYPE_LIST.HOURLY) {
    return `${payload?.hour || '00'} : 00`;
  } else if (rangeType === DISPLAY_TYPE_LIST.MONTHY) {
    return format(parseISO(payload?.date), 'LLL');
  } else if (rangeType === DISPLAY_TYPE_LIST.WEEKLY) {
    return `Week ${payload?.date}`;
  } else {
    return format(parseISO(payload?.date), 'eee');
  }
};

const dateChartTooltip = (payload, rangeType) => {
  if (rangeType === DISPLAY_TYPE_LIST.HOURLY) {
    return `${payload?.hour || '00'} : 00`;
  } else if (rangeType === DISPLAY_TYPE_LIST.MONTHY) {
    return format(parseISO(payload?.date), 'u');
  } else if (rangeType === DISPLAY_TYPE_LIST.WEEKLY) {
    return `Week ${payload?.date}`;
  } else if (rangeType === DISPLAY_TYPE_LIST.DAILY) {
    return format(parseISO(payload?.date), 'EEEE');
  } else {
    return format(parseISO(payload?.date), 'eee');
  }
};

const DataUsageDetail = () => {
  const dispatch = useDispatch();
  const { data_usage_detail } = useSelector(state => state.analytics);

  const [page, setPage] = useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const [labelDatePickerButton, setLabelDatePickerButton] = useState('This Year');
  const [valueDatePicker, setValueDatePicker] = useState([
    {
      startDate: startOfYear(newDate),
      endDate: newDate,
      key: 'selection',
    },
  ]);

  const handleGetDataUsage = () => {
    const _startDate = startOfDay(parseISO(format(valueDatePicker[0].startDate, "yyyy-MM-dd'T'HH:mm")));
    const _endDate = endOfDay(parseISO(format(valueDatePicker[0].endDate, "yyyy-MM-dd'T'HH:mm")));

    dispatch(
      getDataUsageDetail({
        current_page: page,
        start_date: format(_startDate, "yyyy-MM-dd'T'HH:mm"),
        end_date: format(_endDate, "yyyy-MM-dd'T'HH:mm"),
        order: {
          [orderBy.toUpperCase()]: order.toUpperCase(),
        },
      }),
    );
  };
  const [order, orderBy, createSortHandler] = useOrderTable('asc', 'TIME');

  useEffect(() => {
    handleGetDataUsage();
  }, [page, order, valueDatePicker, labelDatePickerButton]);

  return (
    <div className="DataUsageDetail">
      <div className="UsageDetail-Table-Header">
        <div className="table-title">Usage Detail</div>
        <div className="table-action">
          <PopoverRangeDatepicker
            value={valueDatePicker}
            onChange={setValueDatePicker}
            selectedLabel={labelDatePickerButton}
            updateSelectedLabel={setLabelDatePickerButton}
          />
        </div>
      </div>
      <div className="UsageDetail-Table">
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                {DATA_ANALYTICS_HEAD.map(headCell => {
                  return (
                    <TableCell
                      key={headCell.id}
                      align={'left'}
                      padding={'none'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      {headCell.id === 'ip_address' || headCell.id === 'name' ? (
                        headCell.label
                      ) : (
                        <TableHeadSortLabel
                          orderBy={orderBy}
                          headCell={headCell}
                          order={order}
                          onClick={createSortHandler(headCell.id)}
                        />
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data_usage_detail?.list?.map((row, index) => {
                const { is_error } = row;
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell padding="none">{row.ip_address}</TableCell>
                    <TableCell padding="none">
                      <Chip type="stroke" label={row.name} />
                    </TableCell>
                    <TableCell padding="none">
                      <Date date={row.created_at} format="Pp" />
                    </TableCell>
                    <TableCell padding="none">
                      <Chip
                        type="stroke"
                        dot
                        color={is_error ? 'red' : 'success'}
                        label={is_error ? 'Error' : 'Success'}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            {data_usage_detail?.list?.length === 0 && (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12}>
                    <TableEmpty icon={'table-empty-parser'} text={'No result found! '} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={data_usage_detail?.total_count || 0}
            rowsPerPage={data_usage_detail?.per_page || 0}
            page={page - 1}
            onPageChange={handleChangePage}
            SelectProps={{ className: 'table-select-rows-per-page' }}
          />
        </TableContainer>
      </div>
    </div>
  );
};
