import React from 'react';
import { NumberFormatter, NumberFormatterDollar } from 'utils';

export const PricingSummaryProduct = ({ data, type }) => {
  const { name, document_count, price, total_credit } = data;
  return (
    <div className={`Card-PricingSummaryProduct type-${type}`}>
      <div className="product-info">
        <div className="product-name">{name}</div>
        <div className="product-desc">{NumberFormatter(document_count) || 0} Documents</div>
      </div>
      <div className="pricing-info">
        <div className="product-price">{NumberFormatterDollar(price || 0)} </div>
        <div className="product-credit">{NumberFormatter(total_credit) || 0} Credits</div>
      </div>
    </div>
  );
};
