import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const getAllProduct = createAsyncThunk('product/getAllProduct', async (data, { rejectWithValue }) => {
  try {
    const { data } = await axios.get('api/product/all');

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getAllProductFunctions = createAsyncThunk(
  'product/getAllProductFunctions',
  async (data, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('api/product/all/functions');

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const getProductCalculateRequestCountOptions = createAsyncThunk(
  'product/getProductCalculateRequestCountOptions',
  async (data, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('api/product/calculation/request-options');

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);
