import { ProtectedLayout } from 'components/layouts/ProtectedLayout';

import React, { useCallback, useState } from 'react';
import {
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { ReactComponent as IconPlus2 } from 'assets/icons/plus-2.svg';
import { ReactComponent as IconUp } from 'assets/icons/arrow-up.svg';
import { DATA_MATCHER_HEAD, DATA_MEDIMIND_HEAD } from 'data/matcher';
import { ReactComponent as IconArrowDown } from 'assets/icons/arrow-down.svg';
import { TableEmpty } from 'components/elements/TableEmpty';

import { ACCEPT_FILE_FORMATS, MAX_FILE_SIZE } from 'data';
import Buttons from 'components/elements/buttons';
import { useDispatch, useSelector } from 'react-redux';
import {
  addResumeMatcher,
  deleteMatcherResume,
  getMatcherDetail,
  getMatcherMedicalScope,
  getResumeDetail,
  searchMatcherRequestList,
} from 'features/matcher/matcherAction';
import { useEffect } from 'react';
import { InputTag } from 'components/elements/InputTag';
import { resetMatcherDetail, resetUploadFileProgress } from 'features/matcher/matcherSlice';
import { useDropzone } from 'react-dropzone';
import { ParserDetailList } from 'components/ParserDetailList';
import Date from 'components/elements/Date';
import { TableHeadSortLabel } from 'components/elements/TableSortLabel';
import useOrderTable from 'hooks/useOrderTable';
import { Chip } from 'components/elements/Chip';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';

import { ROUTE_PATHS } from 'data/paths';

export const MatcherDetail = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  const IS_MEDIMIND = location.pathname.includes('medimind-detail');
  const DATA_TABLE_HEAD = IS_MEDIMIND ? DATA_MEDIMIND_HEAD : DATA_MATCHER_HEAD;
  let { matcherId } = useParams();
  const {
    detail_matcher,
    detail_matcher_search,
    matcher_resume_upload_progress,
    list_matcher_medical_scope,
    loading,
    success_matcher_delete,
  } = useSelector(state => state.matcher);

  const [page, setPage] = useState(1);
  const [tagsSkills, updateTagsSkills] = useState([]);
  const [valueScope, setValueScope] = useState(list_matcher_medical_scope?.[0]?.['id'] || 'GN');
  const [valuePriority, updateValuePriority] = useState(20);

  // SKILLS FILTER
  const handleDeleteTagsSkills = i => {
    updateTagsSkills(prev => prev.filter((tag, index) => index !== i));
  };

  const handleChangeValueScope = event => {
    setValueScope(event.target.value);
  };
  const [order, orderBy, createSortHandler] = useOrderTable('desc', 'rank');

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleSearchMatcherRequestList = (_priority, _skills, _order, _orderBy, _current_page, _valueScope) => {
    const postData = {
      priority: _priority || valuePriority,
      skills: _skills || tagsSkills,
      order: _order || order,
      orderBy: _orderBy || orderBy,
      current_page: _current_page || page,
      id: matcherId,
      scope: IS_MEDIMIND ? _valueScope || valueScope : null,
    };

    dispatch(searchMatcherRequestList(postData));
  };

  const handleGetMatcherDetail = () => {
    dispatch(getMatcherDetail({ id: matcherId }));
  };

  useEffect(() => {
    handleGetMatcherDetail();
    dispatch(getMatcherMedicalScope());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetMatcherDetail());
    };
  }, []);

  useEffect(() => {
    if (tagsSkills) {
      handleSearchMatcherRequestList();
    }
  }, [tagsSkills, order, orderBy, page, valuePriority, valueScope]);

  /*   useEffect(() => {
    if (success_matcher_detail) {
      updateTagsSkills(detail_matcher?.skills);
    }
  }, [success_matcher_detail]); */

  const handleUploadParserResume = files => {
    dispatch(
      addResumeMatcher({
        matcher_id: detail_matcher?.id,
        files: files,
      }),
    ).then(() => {
      handleSearchMatcherRequestList();
      dispatch(resetUploadFileProgress());
    });
  };

  const onDrop = useCallback(
    acceptedFiles => {
      handleUploadParserResume(acceptedFiles);
    },
    [detail_matcher],
  );
  const {
    getRootProps,
    getInputProps,
    open: openDialog,
  } = useDropzone({
    onDrop,
    noClick: true,
    accept: ACCEPT_FILE_FORMATS,
    maxSize: MAX_FILE_SIZE,
  });

  useEffect(() => {
    if (success_matcher_delete !== null) {
      handleSearchMatcherRequestList();
    }
  }, [success_matcher_delete]);

  return (
    <ProtectedLayout loading={loading}>
      <div className="Page Page-MatcherDetail">
        <div className="MatcherDetail">
          <div className="Page-Heading">
            <div className="Heading-Start">
              <div className="top-action">
                <Buttons.HistoryBack
                  text={detail_matcher?.name}
                  to={IS_MEDIMIND ? ROUTE_PATHS.PRODUCTS_MEDIMIND_LIST : ROUTE_PATHS.PRODUCTS_MATCHER_LIST}
                />
              </div>
            </div>
            <div className="Heading-End"></div>
          </div>
          <div className="MatcherDetail-List">
            <div className="MatcherDetail-Table-Header">
              <div className="filter-list">
                <div className="filter-item">
                  <FilterBox title="Skills">
                    <FilterBoxTags
                      placeholder={'Add skills'}
                      type="skills"
                      tags={tagsSkills || []}
                      update_tags={updateTagsSkills}
                    />
                  </FilterBox>
                </div>
                <div className="filter-item">
                  <FilterBox title="Priority">
                    <div className="FilterBox-Priority">
                      <div className="text">Choose which score is more important for you.</div>
                      <div className="slider">
                        <Slider
                          aria-label="Priority"
                          defaultValue={20}
                          step={5}
                          valueLabelDisplay="on"
                          value={valuePriority}
                          onChange={(event, value) => updateValuePriority(value)}
                        />
                        <div className="slider-labels">
                          <div className="label">Match</div>
                          <div className="label">Retain</div>
                        </div>
                      </div>
                    </div>
                  </FilterBox>
                </div>

                {IS_MEDIMIND ? (
                  <div className="filter-item">
                    <FilterBox title="Scope">
                      <FormControl>
                        <RadioGroup value={valueScope} onChange={handleChangeValueScope}>
                          {list_matcher_medical_scope?.map(item => {
                            return (
                              <div key={item.id}>
                                <FormControlLabel value={item.id} control={<Radio />} label={item.label} />
                              </div>
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </FilterBox>
                  </div>
                ) : null}
                <div className="filter-item">
                  <Button
                    variant="outlined"
                    color="white"
                    onClick={event => {
                      updateTagsSkills([]);
                      updateValuePriority(20);
                    }}
                  >
                    Clear all
                  </Button>
                </div>
              </div>
              <div className="action-add-resumes">
                <Button startIcon={<IconPlus2 />} variant="contained" color="primary" onClick={openDialog}>
                  Add Resume
                </Button>
              </div>
              <div className="filter-result">
                {tagsSkills?.length > 0 &&
                  tagsSkills.map((item, index) => {
                    return (
                      <div className="filter-item" key={index}>
                        <Chip
                          color="ocean-gradient"
                          type="solid"
                          size="small"
                          label={item}
                          onDelete={() => handleDeleteTagsSkills(index)}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="MatcherDetail-Table">
              <TableContainer {...getRootProps()}>
                <input {...getInputProps()} />
                <Table stickyHeader sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow>
                      {DATA_TABLE_HEAD.map(headCell => (
                        <TableCell
                          key={headCell.id}
                          align={'left'}
                          padding={'none'}
                          sortDirection={orderBy === headCell.id ? order : false}
                        >
                          {headCell.id === 'action' || headCell.id === 'name' ? (
                            headCell.label
                          ) : (
                            <TableHeadSortLabel
                              orderBy={orderBy}
                              headCell={headCell}
                              order={order}
                              onClick={createSortHandler(headCell.id)}
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(detail_matcher_search?.list.length > 0 || matcher_resume_upload_progress.length > 0) &&
                      [...matcher_resume_upload_progress, ...detail_matcher_search?.list].map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <Row
                            getList={handleSearchMatcherRequestList}
                            key={index}
                            labelId={labelId}
                            scope={valueScope}
                            row={row}
                            is_medimind={IS_MEDIMIND}
                          />
                        );
                      })}
                  </TableBody>
                  {detail_matcher_search?.list?.length === 0 && (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={12}>
                          <TableEmpty
                            icon={'table-empty-matcher'}
                            text={'To begin matching, add pre-created tags, drag and drop resumes, or add resumes.'}
                            desc={'Upload from Hirize Parser or your computer'}
                          />
                        </TableCell>
                      </TableRow>{' '}
                    </TableBody>
                  )}
                </Table>
                {detail_matcher_search?.total_count > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={detail_matcher_search?.total_count}
                    rowsPerPage={detail_matcher_search?.per_page}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    SelectProps={{ className: 'table-select-rows-per-page' }}
                  />
                )}
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </ProtectedLayout>
  );
};

const Row = ({ row, labelId, is_medimind, scope }) => {
  const dispatch = useDispatch();
  const { resume_detail } = useSelector(state => state.matcher);
  const [open, setOpen] = useState(false);

  const handlerClickDeleteMatcheResume = (event, row) => {
    event.stopPropagation();
    dispatch(deleteMatcherResume({ id: row.id }));
  };

  return (
    <>
      <TableRow hover tabIndex={-1} key={row.id} className={`row-status-${open ? 'opened' : 'closed'}`}>
        <TableCell className="bold" component="th" id={labelId} scope="row" padding="none">
          {row.rank}
        </TableCell>
        <TableCell className="bold" padding="none">
          {row.name}
        </TableCell>

        <TableCell padding="none">
          <Date date={row.created_at} />
        </TableCell>

        {row.scope_of_practice ? <TableCell padding="none">{row.scope_of_practice}</TableCell> : null}

        {row.education ? (
          <TableCell padding="none">
            <TableCellStatusIcon value={row.education} />
          </TableCell>
        ) : null}

        {row.experience ? (
          <TableCell padding="none">
            <TableCellStatusIcon value={row.experience} />
          </TableCell>
        ) : null}

        {row.qualifying ? (
          <TableCell padding="none">
            <TableCellStatusIcon value={row.qualifying} />
          </TableCell>
        ) : null}

        <TableCell padding="none">
          <TableScore score={row.match} color="#3A5AFF" />
        </TableCell>
        <TableCell padding="none">
          <TableScore score={row.retain} color="#82ff57" />
        </TableCell>

        <TableCell className="actions">
          <IconButton size="small" color="red-outlined" onClick={event => handlerClickDeleteMatcheResume(event, row)}>
            <IconTrash />
          </IconButton>
          <IconButton
            size="small"
            color="white"
            onClick={event => {
              event.stopPropagation();
              setOpen(!open);
              if (!open) {
                dispatch(
                  getResumeDetail({ id: row.resume_id, is_medimind: is_medimind, resume_id: row.id, scope: scope }),
                );
              }
            }}
            className={`button-toggle-detail ${open ? 'opened' : ''}`}
          >
            <IconUp />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className="Row-Opened">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {resume_detail && resume_detail?.[row.resume_id] && (
              <div className="Row-Opened-Detail">
                {<ParserDetailList showHeading={false} data={resume_detail?.[row.resume_id]} />}
                <div className="opened-detail-action">
                  <Button
                    endIcon={<IconUp />}
                    onClick={event => {
                      setOpen(false);
                    }}
                  >
                    View Less
                  </Button>
                </div>
              </div>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const TableCellStatusIcon = ({ value }) => {
  if (value === 'positive') {
    return (
      <div title={'Positive'} className="table-cell-status-icon ">
        <i className="status status-positive"></i>
      </div>
    );
  } else if (value === 'negative') {
    return (
      <div title={'Negative'} className="table-cell-status-icon">
        <i className="status status-negative"></i>
      </div>
    );
  } else if (value === 'none') {
    return (
      <div title={'Not Required'} className="table-cell-status-icon">
        <i className="status not-required"></i>
      </div>
    );
  } else {
    return null;
  }
};

const TableScore = ({ score, color = '#444' }) => {
  return (
    <div className="TableScore">
      <div className="icon">
        <svg viewBox="0 0 36 36">
          <path
            d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="none"
            stroke={color}
            strokeWidth="3"
            strokeDasharray={`${score}, 100`}
          />
        </svg>
      </div>
      <div className="score">{score} %</div>
    </div>
  );
};

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};
const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const FilterBox = ({ children, title, filter_priority, updateFilterPriority }) => {
  const [anchorElFilter, setAnchorElFilter] = useState(null);

  return (
    <>
      <Button
        variant="outlined"
        color="white"
        endIcon={<IconArrowDown />}
        onClick={event => setAnchorElFilter(event.currentTarget)}
      >
        {title}
      </Button>
      <Popover
        open={Boolean(anchorElFilter)}
        anchorEl={anchorElFilter}
        onClose={() => setAnchorElFilter(null)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        className="Popover-FilterBox"
      >
        <div className="FilterBox">
          <div className="filter-heading">
            <div className="title">{title}</div>
            {/* <div className="filter-priority">
              <Rating
                name="customized-color"
                defaultValue={2}
                precision={1}
                icon={<IconPriorityFilled fontSize="inherit" />}
                emptyIcon={<IconPriorityEmpty fontSize="inherit" />}
              />
            </div> */}
          </div>
          <div className="filter-content">{children}</div>
        </div>
      </Popover>
    </>
  );
};

const FilterBoxTags = ({ type, tags, update_tags, placeholder }) => {
  return (
    <div className="FilterBox-Tags FilterBox-JobTitle">
      <div className="tags">
        <InputTag viewType={'standart'} value={tags} setValue={update_tags} placeholder={placeholder} />
      </div>
    </div>
  );
};
