import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const getCompany = createAsyncThunk('company/getCompany', async (data, { rejectWithValue }) => {
  try {
    const { data } = await axios.get('api/company');

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getCompanyPlan = createAsyncThunk('company/getCompanyPlan', async (postData, { rejectWithValue }) => {
  try {
    const { data } = await axios.get('api/company/plan');

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const updateCompany = createAsyncThunk('company/updateCompany', async (postData, { rejectWithValue }) => {
  try {
    const { data } = await axios.put('api/company/', postData);

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getAllInvoices = createAsyncThunk('company/getAllInvoices', async (postData, { rejectWithValue }) => {
  try {
    const { data } = await axios.get('api/company/invoices');

    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
