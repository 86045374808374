export const DATA_ANALYTICS_HEAD = [
  {
    id: 'ip_address',
    label: 'SOURCE IP',
  },
  {
    id: 'name',
    label: 'SOURCE',
  },
  {
    id: 'TIME',
    label: 'TIME',
  },
  {
    id: 'STATUS',
    label: 'STATUS',
  },
];
