import axios from 'axios';

const { createAsyncThunk } = require('@reduxjs/toolkit');

export const userLogin = createAsyncThunk('user/login', async ({ email, password }, { rejectWithValue }) => {
  try {
    const { data } = await axios.post('oauth/v2/token', {
      username: email,
      password,
      grant_type: 'password',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    });
    const { access_token, expires_in, refresh_token } = data;
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('expires_in', expires_in);
    localStorage.setItem('refresh_token', refresh_token);

    return data;
  } catch (error) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error);
    }
  }
});
