import React from 'react';

export const BoxInfo = ({ title, text, theme = 'gray', size = 'medium' }) => {
  return (
    <div className={`Box-InfoBox theme-${theme} size-${size}`}>
      <div className="box-title">{title}</div>
      <div className="box-text">{text} </div>
    </div>
  );
};
