import { Button, FormControl, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { FORM_TEXT } from 'data';
import { forgotPassword } from 'features/userMember/userMemberAction';
import { updateDataForgotPassword } from 'features/userMember/userMemberSlice';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { PaternEmail, validateBusinessEmail } from 'utils/formPatterns';
import { useNavigate } from 'react-router-dom';

export const ForgotPasswordEmailForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { success, message } = useSelector(state => state.userMember);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });
  const SendForgotPasswordForm = data => {
    dispatch(forgotPassword(data));
    dispatch(updateDataForgotPassword({ field: 'email', value: data.email }));
  };
  useEffect(() => {
    if (success || message === 'Already has a generated code') {
      navigate('/forgot-password/code-verification');
    }
  }, [success]);
  return (
    <div className="ForgotPassword-EmailForm">
      <div className="heading">
        <div className="title">Forgot Password</div>
        <div className="desc">No problem! It happens to the best of us! 😎</div>
      </div>{' '}
      <Box component="form" onSubmit={handleSubmit(data => SendForgotPasswordForm(data))} autoComplete="off">
        <Grid container rowSpacing={{ xs: '0', sm: '44px' }}>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth size={'small'}>
              <Controller
                render={({ field }) => (
                  <TextField
                    error={!!errors?.['email']?.['message']}
                    helperText={errors?.['email']?.['message']}
                    label="Work e-mail*"
                    variant="filled"
                    {...field}
                  />
                )}
                name="email"
                control={control}
                rules={{ required: FORM_TEXT.required, pattern: PaternEmail, validate: validateBusinessEmail }}
              />
            </FormControl>
          </Grid>
          <Grid className="form-action-send" item xs={12}>
            <Button
              onClick={handleSubmit(SendForgotPasswordForm)}
              fullWidth
              className="button-type-auth"
              variant="contained"
              size="large"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
